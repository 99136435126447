import moment from 'moment';

export const CalculateAge = birthDate => {
  let age = birthDate;
  if (birthDate) {
    const today = moment();
    const dob = moment(birthDate);
    const years = today.diff(dob, 'years');
    dob.add(years, 'years');
    const months = today.diff(dob, 'months');
    dob.add(months, 'months');
    const days = today.diff(dob, 'days');
    if (years > 0) {
      age = `${years} y`;
    } else if (months > 0) {
      age = `${months} m`;
    } else if (days > 0) {
      age = `${days} d`;
    }
    return age;
  }
};
