import React, { useState } from 'react';
import WardCard from './components/WardCard';

const InPatientPage = () => {
  const [activeTab, setActiveTab] = useState('My Patients');

  const wards = [
    { name: 'Ward A', patients: 10, bedsOccupied: 8, bedsVacant: 2 },
    { name: 'Ward B', patients: 12, bedsOccupied: 9, bedsVacant: 3 }
    // Add more wards as needed
  ];

  return (
    <div className='p-2'>
      <h1 className='text-2xl font-bold mb-4'>In Patient Management</h1>

      {/* Ward Cards Section */}
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-6'>
        {/* {wards.map((ward, index) => (
          <WardCard key={index} ward={ward} />
        ))} */}
      </div>
    </div>
  );
};

export default InPatientPage;
