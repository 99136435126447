import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import { FaBars } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { useContext, useEffect, useRef, useState } from 'react';
import { getNavigation } from '../../../../router/routes';
import CreateButton from '../../../CreateButton';
import { AddCircleOutline, ListAlt, LogoutRounded, Person2Rounded } from '@mui/icons-material';
import DriveEtaOutlinedIcon from '@mui/icons-material/DriveEtaOutlined';
import MedicationIcon from '@mui/icons-material/Medication';
import './style.css';
import { appContext } from '../../../../context/ContextProvider';
import { Avatar, FormControl, IconButton, InputLabel, Menu, MenuItem, Select } from '@mui/material';
import NotificationDropdown from '../../../NotifDropdwon/Dropdown';
import { GiPayMoney } from 'react-icons/gi';
import { MdOutlinePolicy } from 'react-icons/md';

const Navbar = ({ noButton }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchInputRef = useRef(null);
  const location = useLocation();
  const { sidebar: sidebarLinks } = getNavigation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, token, setUser, setToken } = useContext(appContext);
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [fontSize, setFontSize] = useState('16px');
  const navigate = useNavigate();
  // Function to find the active link and its submenu
  const getActiveLink = () => {
    if (!sidebarLinks || !Array.isArray(sidebarLinks)) return null; // Safeguard for undefined/null or invalid sidebarLinks

    return sidebarLinks.find(item => {
      if (!item || !item.path) return false; // Ensure item and item.path are valid
      // Check if current pathname matches either the main path or a submenu path
      return (
        location.pathname === `/${item.path}` ||
        (item.menu &&
          Array.isArray(item.menu) &&
          item.menu.some(sub => sub && sub.path && location.pathname === `/${sub.path}`)) // Ensure submenu paths are valid
      );
    });
  };

  const activeLink = getActiveLink(); // Get the currently active link

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  const toggleMenu = () => {
    setIsNavOpen(!isNavOpen);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (!isSearchOpen) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 100); // Small delay to ensure the input is visible before focusing
    }
  };
  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);

    // Adjust font size dynamically when there are many links
    const adjustFontSize = () => {
      const container = document.querySelector('.nav-links');
      if (container && container.scrollWidth > container.clientWidth) {
        setFontSize('12px');
      } else {
        setFontSize('14px');
      }
    };

    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);

    return () => {
      window.removeEventListener('resize', updateScreenWidth);
      window.removeEventListener('resize', adjustFontSize);
    };
  }, []);
  const handleLogout = () => {
    // Clear user and token context and local storage
    setUser(null); // Clear user state
    setToken(null); // Clear token state
    localStorage.clear(); // Remove token from local storage
    navigate('/'); // Redirect to landing page after logout
  };

  const handleDropdownClick = event => {
    setAnchorEl(event.currentTarget); // Set the anchor element for the dropdown
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the dropdown
  };
  const handleLanguageChange = event => {
    setSelectedLanguage(event.target.value);
    // Implement any necessary actions when the language changes
    console.log(`Language selected: ${event.target.value}`);
  };
  // or get user info from localStorage
  const [isProvider, setIsProvider] = useState(false);

  // Fetch user role from localStorage if needed
  useEffect(() => {
    if (user?.RoleName === 'provider') {
      setIsProvider(true);
    }
  }, []);
  const isOnDashboard = location.pathname === '/dashboard'; // Check if on dashboard

  return (
    <div
      className='flex items-center justify-between text-white  py-3 lg:px-4 xl:px-10 navigationbar'
      style={{
        backgroundColor: '#373d45'
      }}
    >
      <div className='flex items-center pr-2'>
        {screenWidth > 768 ? (
          <div>
            {activeLink && ( // Render only the active link and its submenu
              <div className='flex items-center space-x-3 text-lg lg:space-x-6 font-semibold text-nowrap overflow-x-auto'>
                <Link
                  to={`/${activeLink.path}`}
                  className={`hover:text-gray-300 ${
                    location.pathname === `/${activeLink.path}`
                      ? 'text-white border-b-2 lg:border-b-4 border-transparent rounded-b-md '
                      : 'text-white'
                  }`}
                  style={
                    location.pathname === `/${activeLink.path}`
                      ? {
                          borderImage: 'linear-gradient(90deg, #f97316, #dc2626) 1',
                          borderBottom: '2px solid transparent'
                        }
                      : {}
                  }
                >
                  {activeLink.name}
                </Link>
                {activeLink.menu &&
                  activeLink.menu.map(sub => (
                    <Link
                      to={`/${sub.path}`}
                      key={sub.path}
                      className={`hover:text-gray-300 ${
                        location.pathname === `/${sub.path}` ? 'text-white border-b-2 border-transparent' : 'text-white'
                      } ${sidebarLinks.length > 4 ? 'text-sm' : 'text-lg'}`}
                      style={
                        location.pathname === `/${sub.path}`
                          ? {
                              borderBottomWidth: '4px',
                              borderImage: 'linear-gradient(to right, #f97316, #dc2626) 1'
                            }
                          : {}
                      }
                    >
                      {sub.name}
                    </Link>
                  ))}
              </div>
            )}
          </div>
        ) : (
          <div>
            <button onClick={toggleMenu} className='mt-2'>
              <FaBars size={18} />
            </button>
            {isNavOpen && activeLink && (
              <div>
                <div className='absolute top-0 left-0 text-white flex flex-col items-center justify-center space-x-3 lg:space-x-6 text-sm lg:text-base xl:text-lg font-semibold text-nowrap bg-white py-8 outline-none border-none z-40 navigationbar-hamburger shadow-slate-200 h-screen'>
                  <Link
                    onClick={toggleMenu}
                    to={`/${activeLink.path}`}
                    className={`hover:text-gray-300 ${
                      location.pathname === `/${activeLink.path}`
                        ? 'md:text-white border-b-0 lg:border-b-4 border-white'
                        : 'md:text-white'
                    } outline-none py-3`}
                  >
                    {activeLink.name}
                  </Link>
                  {activeLink.menu &&
                    activeLink.menu.map(sub => (
                      <Link
                        onClick={toggleMenu}
                        to={`/${sub.path}`}
                        key={sub.path}
                        className={`hover:text-gray-300 ${
                          location.pathname === `/${sub.path}`
                            ? 'md:text-white border-b-0 lg:border-b-4 border-white'
                            : 'md:text-white'
                        } outline-none py-3`}
                      >
                        {sub.name}
                      </Link>
                    ))}
                  <div onClick={toggleMenu} className='text-white absolute top-5 right-5'>
                    <ImCross size={14} />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className='flex items-center space-x-3 lg:space-x-6'>
        <div className='relative flex items-center'></div>

        <div className='relative'>
          <IconButton onClick={handleDropdownClick}>
            <Avatar src='/imageHis/person.svg' className='object-cover w-16 h-16' />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            PaperProps={{
              sx: {
                width: '25ch',
                mt: 2,
                bgcolor: 'rgb(55, 61, 69)',
                marginTop: '10px'
              }
            }}
          >
            {/* User Info */}
            <MenuItem onClick={handleClose}>
              <div className='flex flex-col space-y-1 '>
                <div className='flex flex-col'>
                  <p>
                    <span style={{ fontStyle: 'italic', color: 'white' }}>{user?.RoleName}</span>
                  </p>
                </div>
                <div className='flex flex-col'>
                  <p>
                    <span style={{ fontStyle: 'italic', color: 'white' }}>{user?.FullName}</span>
                  </p>
                </div>
              </div>
            </MenuItem>

            {/* Conditionally Render Menu Items Based on Role */}
            {isProvider && (
              <div>
                <MenuItem
                  onClick={() => {
                    navigate('/physicians');
                    handleClose();
                  }}
                  sx={{ fontStyle: 'italic', color: 'white' }}
                >
                  <Person2Rounded /> Physicians
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/receptionist/insurance_policy');
                    handleClose();
                  }}
                  sx={{ fontStyle: 'italic', color: 'white' }}
                >
                  <MdOutlinePolicy className='mr-3' />
                  Insurance Policy
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/his/payers');
                    handleClose();
                  }}
                  sx={{ fontStyle: 'italic', color: 'white' }}
                >
                  <GiPayMoney className='mr-3' />
                  Payers
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/price-list');
                    handleClose();
                  }}
                  sx={{ fontStyle: 'italic', color: 'white' }}
                >
                  <ListAlt /> Price List
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/nurses');
                    handleClose();
                  }}
                  sx={{ fontStyle: 'italic', color: 'white' }}
                >
                  <MedicationIcon /> Nurses
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/drivers');
                    handleClose();
                  }}
                  sx={{ fontStyle: 'italic', color: 'white' }}
                >
                  <DriveEtaOutlinedIcon /> Drivers
                </MenuItem>
              </div>
            )}

            {/* Logout */}
            <MenuItem sx={{ borderTop: '1px solid gray', color: 'red' }} onClick={handleLogout}>
              <LogoutRounded /> Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
