import { useMutation, useQuery } from '@apollo/client';
import React, { useState, useContext, useEffect } from 'react';
import Filter from './components/Filter';
import beneficiary from '../../gql/beneficiary';
import EditBeneficiaryModal from './components/EditBeneficiaryModal';

import AddBeneficiaryModal from './components/AddBeneficiaryModal';
import ConformationModal from '../../components/ConformationModal';
// import { ToastContainer, toast } from "react-toastify";
import toast from 'react-hot-toast';
import { appContext } from '../../context/ContextProvider';
import { useNavigate } from 'react-router-dom';
import FilterModal from './components/modals/FilterModal';

import './styles/BeneficiaryList.css';
import BeneficiaryDetailModal from './components/BeneficiaryDetailModal';
import { BsGenderMale, BsGenderFemale } from 'react-icons/bs';
import { FaGenderless } from 'react-icons/fa';
import { Chip, Menu, MenuItem, Tooltip } from '@mui/material';
import { TbEditCircle } from 'react-icons/tb';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CustomPagination from '../HIS/components/CustomPagination';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getPatientListDropdown } from '../HIS/components/ApiMethods';
import AppLoader from '../../components/AppLoader';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { CalculateAge } from '../../util/CalculateAge';

function BeneficiaryList(props) {
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [patientList, setPatientList] = useState(false);
  const [selectedDetailID, setSelectedDetailID] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedPatientID, setSelectedPatientID] = useState(null);
  const [exportMenuEl, setExportMenuEl] = useState(null);
  const openExportMenu = Boolean(exportMenuEl);
  const prinfRef = useRef(null);
  const iframeRef = useRef(null);

  const handleClickOpen = patientID => {
    setSelectedPatientID(patientID); // Set the selected patient ID
    setOpen(true); // Open the modal
  };

  const handleClose = () => {
    setOpen(false); // Close the modal
    setSelectedPatientID(null); // Reset the selected ID
  };

  const handleViewClick = el => {
    handleClickOpen(el.patient_id); // Open the modal with the patient ID
  };

  const [excelData, setExcelData] = useState([
    { id: 1, name: 'John Doe', email: 'john.doe@example.com' },
    { id: 2, name: 'Jane Doe', email: 'jane.doe@example.com' },
    { id: 3, name: 'Bob Smith', email: 'bob.smith@example.com' }
  ]);

  const [showFilter, setShowFilter] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    _id: 0,
    Address: 'a',
    IqamaExpiry: 'arbi@gmail.com',
    StartDate: 'name',
    Department: 'salman45',
    Gender: '2345',
    StatusId: 1
  });

  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(
    +JSON?.parse(localStorage?.getItem('beneficiariesPageNo'))
      ? +JSON?.parse(localStorage?.getItem('beneficiariesPageNo'))
      : 1
  );

  const {
    loading: excelDataLoading,
    error: excelDataError,
    data: excelDataResponse,
    refetch: refetchExcelDataResponse
  } = useQuery(beneficiary.GET_PATIENTS_IN_EXCEL, {
    variables: { filters: { limit: limit, page: pageNo } },
    skip: true
  });

  useEffect(() => {
    getPatientListDropdown(setLoading, setPatientList, {
      page: 1,
      perPage: 10
    });
  }, []);

  useEffect(() => {
    if (excelDataResponse) {
      setExcelData(JSON.parse(excelDataResponse?.listPatientsExcel?.data));
    }
  }, [excelDataResponse]);

  const [delEntry, {}] = useMutation(beneficiary.DEL_BENEFICIARY, {
    update(proxy, result) {
      onSuccessfulRequestResponse(result.data);
    },
    onError(error) {
      onResponseError(error.graphQLErrors[0]);
    }
  });

  const onSuccessfulRequestResponse = data => {
    if (data.deletePatient) {
      toast.success('Successfully Deleted!');
    }
  };

  const onResponseError = error => {
    toast.error(`${error.message}`);
  };

  const onPageChange = (newPage, newRowsPerPage) => {
    getPatientListDropdown(setLoading, setPatientList, {
      page: newPage,
      perPage: newRowsPerPage
    });
  };

  const onClickDelete = item => {
    setCurrentItem(item);
    setShowDeleteAlert(true);
  };

  const deleteEntry = () => {
    delEntry({ variables: { input: { ID: currentItem._id } } });
    setShowDeleteAlert(false);
  };

  const onClickEdit = el => {
    navigate('/beneficiary/edit-form', {
      state: { patientData: el }
    });
  };

  const tableHeaders = [
    { name: 'SQ #' },
    { name: 'Full Name' },
    { name: 'File No.' },
    { name: 'Document Type' },
    { name: 'Beneficiary Type' },
    { name: 'Document #' },
    { name: 'Contact No.' },
    { name: 'Nationality' },
    { name: 'Martial Status' },
    { name: 'Age' },
    { name: 'Actions' }
  ];

  const documentTypes = [
    { id: 'VP', name: 'VISA' },
    { id: 'PPN', name: 'Passport' },
    { id: 'PRC', name: 'Permanent Resident Card Number' },
    { id: 'MR', name: 'Medical record number' },
    { id: 'NI', name: 'National Card' }
  ];

  // const handleViewClick = (el) => {
  //   handleClickOpen(el.patient_id); // Open the modal with the patient ID
  // };

  function calculateAge(dateOfBirth) {
    // Parse the date of birth string into a Date object
    const dob = new Date(dateOfBirth);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in years
    const age = currentDate.getFullYear() - dob.getFullYear();

    // Check if the birthday has occurred this year
    if (
      currentDate.getMonth() < dob.getMonth() ||
      (currentDate.getMonth() === dob.getMonth() && currentDate.getDate() < dob.getDate())
    ) {
      // If not, subtract 1 from the age
      return age - 1;
    }

    // Otherwise, return the calculated age
    return age;
  }

  const downloadExcelFile = fileName => {
    refetchExcelDataResponse({ filters: { limit: limit, page: pageNo } }).then(res => {
      const data = JSON.parse(res?.data?.listPatientsExcel?.data);
      const workbook = XLSX.utils.book_new();
      const sheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      const fileData = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      saveAs(fileData, `${fileName}.xlsx`);
    });
    setExportMenuEl(null);
  };

  const handlePrint = () => {
    let printContents = prinfRef.current.innerHTML;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = printContents;
    const rows = tempDiv.querySelectorAll('tr');
    rows.forEach(row => {
      const cells = row.children;
      if (cells.length > 0) {
        cells[cells.length - 1].remove();
      }
    });

    printContents = tempDiv.innerHTML;

    // Get the iframe reference
    const iframe = iframeRef.current;
    const doc = iframe.contentWindow.document;

    doc.open();
    doc.write('<html><head><title>Balance Sheet</title>');
    doc.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">');
    doc.write('<style>');
    doc.write(`
      @media print {
        @page {
          margin: 20px 0 20px 0;
        }
        body {
          margin: 1cm;
        }
        th {
          padding: 10px !important; 
          text-align: center !important; 
          font-size: 12px !important; 
          border: 1px solid #ccc; 
        }
        td {
          padding: 8px !important; 
          border: 1px solid #ccc; 
        }
      }
    `);
    doc.write('</style>');
    // Write the content to the iframe
    doc.write('</head><body>');
    doc.write(printContents);
    doc.write('</body></html>');
    doc.close();

    // Trigger print on iframe load
    iframe.onload = () => {
      iframe.contentWindow.print();
    };
  };

  return (
    <div className='superGit_container !p-0'>
      <div className='flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3'>
        <div className=''>
          <Tooltip placement='right' title='Keep track of your beneficiaries' className='my-auto !mt-2'>
            <span className='font-semibold text-black/70 text-lg '>
              BENEFICIARIES
              <span className='italic text-lg ml-1'>
                ({patientList?.totalCount || 0}
                <span className='text-xs font-bold italic'>Entries</span>)
              </span>
            </span>
          </Tooltip>
        </div>
        <div className='flex gap-3 flex-col md:flex-row'>
          <div>
            <Button
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              className='!text-white bg-gradient-to-r from-[#f14f3e] to-[#fab768] hover:opacity-80'
              startIcon={<FileDownloadOutlinedIcon />}
              onClick={event => setExportMenuEl(event.currentTarget)}
              size='small'
            >
              Export
            </Button>
            <Menu
              id='basic-menu'
              anchorEl={exportMenuEl}
              open={openExportMenu}
              onClose={() => setExportMenuEl(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              style={{ width: '100px' }}
            >
              <MenuItem onClick={() => downloadExcelFile('Beneficiaries')}> Excel</MenuItem>
              <MenuItem onClick={() => handlePrint()}> PDF</MenuItem>
            </Menu>
          </div>
          <Button
            variant='contained'
            onClick={() => navigate('/beneficiary/form', { state: { name: 'b-form' } })}
            style={{
              backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
            }}
            startIcon={<AddIcon />}
            size='small'
          >
            Create Beneficiary
          </Button>
          <Button
            variant='outlined'
            onClick={() => setShowFilter(() => !showFilter)}
            className='!text-white bg-gradient-to-r from-[#f14f3e] to-[#fab768] hover:opacity-80'
            startIcon={<FilterAltOutlinedIcon />}
            size='small'
          >
            Apply Filter
          </Button>
        </div>
      </div>

      {/* <div className="search-filter"> */}
      {/* <div className="search">
          <input type="search" name="search" id="search" placeholder="Search" />
        </div> */}

      <FilterModal
        setLoading={setLoading}
        setPatientList={setPatientList}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        setPageNo={setPageNo}
        setLimit={setLimit}
        pageNo={pageNo}
        // refetchExcelDataResponse={refetchExcelDataResponse}
      />
      {/* </div> */}
      <div className='super-git-table !p-0 !m-0' ref={prinfRef}>
        <table className='table-super-git !p-0'>
          <thead className='!p-0'>
            <tr className='!p-0'>
              {tableHeaders.map((el, index) => {
                return (
                  <th className='!p-2 !text-sm !bg-[#373d45]' key={'tableHeaders_' + index}>
                    {el.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {patientList?.data?.map((el, index) => {
              return (
                <tr className='!p-0' key={'beneficiariesData_' + index}>
                  <td className='!p-1 !text-sm'>{pageNo * limit + index + 1 - limit}</td>
                  <td className='!p-1 !text-sm capitalize text-start'>
                    {el?.full_name?.length > 100 ? (
                      <Tooltip title={el?.full_name}>
                        <span>{el?.full_name.slice(0, 100)}...</span>
                      </Tooltip>
                    ) : (
                      el?.full_name
                    )}
                  </td>
                  <td className='!p-1 !text-sm'>{el.file_no}</td>
                  <td className='!p-1 !text-sm'>
                    {el?.document_type ? documentTypes.find(doc => doc.id === el.document_type)?.name : ''}
                  </td>
                  <td className='!p-1 !text-sm'>{el?.beneficiary_type ?? ''}</td>

                  <td className='!p-1 !text-sm'>{el.document_id}</td>
                  <td className='!p-1 !text-sm'>{el.contact}</td>

                  {/* yyy */}
                  <td className='!p-1 !text-sm'>{el.nationality}</td>
                  <td className='!p-1 !text-sm'>{el.martial_status}</td>

                  <td className='!p-1 !text-sm'>
                    <div className='flex gap-2 justify-center'>
                      <div>{CalculateAge(el?.dob)}</div>
                      <div className='mt-1'>
                        {el.gender === 'male' ? (
                          <BsGenderMale />
                        ) : el.gender === 'female' ? (
                          <BsGenderFemale />
                        ) : (
                          <FaGenderless />
                        )}
                      </div>
                    </div>
                  </td>
                  {/* <td>{el.providers_name_en}</td> */}
                  <td className='flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1'>
                    <Tooltip title='View'>
                      <div onClick={() => handleViewClick(el)} className='text-xl cursor-pointer text-green-500'>
                        <MdOutlineRemoveRedEye />
                      </div>
                    </Tooltip>

                    <Tooltip title='Edit'>
                      <div onClick={() => onClickEdit(el)} className='text-xl cursor-pointer text-orange-400'>
                        <TbEditCircle />
                      </div>
                    </Tooltip>
                    <Chip
                      label={'Form Entries'}
                      color={'success'}
                      size='small'
                      onClick={() => {
                        navigate('/receptionist/general-form-entries', {
                          state: {
                            patientId: el?.rcm_ref,
                            patient: el
                          }
                        });
                      }}
                      className='!mr-[2px] rounded !cursor-pointer'
                      style={{ fontSize: '0.7rem', padding: '2px 4px' }}
                      variant='contained'
                      sx={{
                        backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                        borderColor: '#f14f3e',
                        color: 'white',
                        '&:hover': {
                          borderColor: '#f14f3e'
                        },
                        '&:focus': {
                          borderColor: '#f14f3e'
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        {/* <Pagination
          currentPage={pageNo}
          totalPages={data?.listPatients?.pages}
          onPageChange={onPageChange}
        /> */}
        <CustomPagination
          currentPage={patientList?.page || 1}
          rowsPerPage={patientList?.perPage || 5}
          totalRecords={patientList?.totalCount || 0}
          onPageChange={onPageChange}
        />
      </div>
      <AddBeneficiaryModal
        item={currentItem}
        showBeneficiaryModal={showEditModal}
        setShowBeneficiaryModal={setShowEditModal}
        setLoading={setLoading}
        setPatientList={setPatientList}
        pageNo={pageNo}
      />
      <ConformationModal open={showDeleteAlert} setOpen={setShowDeleteAlert} onDelete={deleteEntry} />

      <BeneficiaryDetailModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        patientID={selectedPatientID}
        setSelectedDetailID={setSelectedDetailID}
      />
      <AppLoader showLoader={Loading} />
      <iframe ref={iframeRef} style={{ display: 'none' }}></iframe>
    </div>
  );
}

export default BeneficiaryList;
