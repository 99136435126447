import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Formik, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import { Switch } from '@headlessui/react';
import Select from 'react-select';
import _ from 'lodash';
import { useQuery, gql, useMutation } from '@apollo/client';
// import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { ChevronRightIcon, ArrowRightIcon } from '@heroicons/react/20/solid';
import gqlPreAuth from '../../../../gql/preAuth';
import ItemCodesSearchNSelect from '../../../PreAuth/components/request/ItemCodesSearchNSelect';
import PrescriptionSearchNSelect from './PrescriptionSearchNSelect';
import MultiSelectComponent from '../../../PreAuth/components/request/MultiSelect';
import { items as sites, dentalItems as oralItems, items } from '../../../PreAuth/components/request/BodySites';
import { Button, Divider } from '@mui/material';
import priceList from '../../../../gql/priceList';
import { BiSearch, BiX } from 'react-icons/bi';
import DentalTeethSelectModalItem from '../../../Claims/components/request/DentalTeethSelectModalItem';
import moment from 'moment';

// import {HiOutlineZoomIn} from '@heroicons/react/20/solid/s';
// HiAdjustments
// import {FontAwesomeIcon} from 'fa-font'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '95%'
  }
};

let subtitle;

function AddItemModal({
  showAddItemModal,
  setShowAddItemModal,
  formData,
  setFormData,
  values: formikValues,
  setMainFormFieldValue,
  item,
  currentItemIndex,
  setCurrentItemIndex,
  patient,
  rcmDetail
}) {
  const navigate = useNavigate();
  const currentDate = new Date();
  const todayDate = moment().format('YYYY-MM-DD');
  const handleDiscountAmountChange = e => {
    const discountAmount = parseFloat(e.target.value);
    setFieldValue('discount_amount', discountAmount);

    if (!isNaN(discountAmount) && values.quantity && values.unit_price) {
      const subTotal = values.quantity * values.unit_price;
      const newFactor = (subTotal - discountAmount) / subTotal;
      setFieldValue('factor', newFactor.toFixed(2));
      const netBeforeTax = subTotal - discountAmount;
      const taxAmount = parseFloat(values.tax_amount) || 0;
      const netWithTax = netBeforeTax + taxAmount;
      setFieldValue('net', parseFloat(netWithTax).toFixed(2));
    }
  };

  const handleFactorChange = e => {
    const factor = parseFloat(e.target.value);
    setFieldValue('factor', factor);

    if (!isNaN(factor) && values.quantity && values.unit_price) {
      const subTotal = values.quantity * values.unit_price;
      const newDiscountAmount = subTotal * (1 - factor);
      setFieldValue('discount_amount', newDiscountAmount.toFixed(2));
      const netBeforeTax = subTotal * factor;
      const taxAmount = parseFloat(values.tax_amount) || 0;
      const netWithTax = netBeforeTax + taxAmount;
      setFieldValue('net', netWithTax.toFixed(2));
    }
  };
  const [enabled, setEnabled] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [showTeethSelectModal, setShowTeethSelectModal] = useState(false);
  // const [standardCodes, setStandardCodes] = useState([
  //   { code: "1", description: "Medical Devices" },
  // ]);

  // const {
  //   loading: loadingCodes,
  //   error: errorCodes,
  //   data: codesData,
  //   refetch,
  // } = useQuery(gqlPreAuth.GET_CODES, {
  //   variables: { fileName: "" },
  // });

  // React.useEffect(() => {
  //   if (codesData) {
  //     setStandardCodes(codesData?.getItemsCodeDescription?.data);
  //   }
  // }, [codesData]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  const types = [
    { id: 'medical-devices', name: 'Medical Devices' },
    { id: 'medication-codes', name: 'Medication Codes' },
    { id: 'transportation-srca', name: 'Transportation SRCA' },
    { id: 'imaging', name: 'Imaging' },
    { id: 'services', name: 'Services' },
    { id: 'laboratory', name: 'Laboratory' },
    { id: 'oral-health-op', name: 'Oral Health OP' },
    { id: 'procedures', name: 'Procedures' }
  ];

  const dentalSubSites = [
    { id: 'M', name: 'Mesial' },
    { id: 'O', name: 'Occlusal' },
    { id: 'I', name: 'Incisal' },
    { id: 'D', name: 'Distal' },
    { id: 'B', name: 'Buccal' },
    { id: 'V', name: 'Ventral' },
    { id: 'L', name: 'Lingual' },
    { id: 'MO', name: 'Mesioclusal' },
    { id: 'DO', name: 'Distoclusal' },
    { id: 'DI', name: 'Distoincisal' },
    { id: 'MOD', name: 'Mesioclusodistal' }
  ];

  const subSites = [
    { id: 'Right', name: 'Right' },
    { id: 'Left', name: 'Left' },
    { id: 'Upper', name: 'Upper' },
    { id: 'Down', name: 'Down' },
    { id: 'Anterior', name: 'Anterior' },
    { id: 'Posterior', name: 'Posterior' },
    { id: 'Interior', name: 'Interior' },
    { id: 'Exterior', name: 'Exterior' }
  ];

  const medSelectionReasons = [
    { id: 'Generic', name: 'Generic' },
    { id: 'patient-request', name: 'patient-request' },
    { id: 'innovative-noGeneric', name: 'innovative-noGeneric' },
    { id: 'physician-approval', name: 'physician-approval' },
    { id: 'physician-no-approval', name: 'physician-no-approval' },
    { id: 'drug-pharmacyUnavailable', name: 'drug-pharmacyUnavailable' },
    { id: 'drug-nationallyUnavailable', name: 'drug-nationallyUnavailable' },
    { id: 'Irreplaceable', name: 'Irreplaceable' }
  ];

  const supportingInfoOptions = () => {
    let options = formikValues?.supportingInfos?.map((option, index) => ({
      ...option,
      name: option.category,
      id: index + 1,
      label: option.category,
      value: index + 1
    }));

    return options;
  };

  const diagnosisOptions = () => {
    let options = formikValues?.diagnosisArr?.map((option, index) => ({
      ...option,
      name: option.codeDescription,
      id: index + 1,
      label: option.codeDescription,
      value: index + 1
    }));

    return options;
  };

  const careTeamsOptions = () => {
    let options = formikValues?.careTeamsArr?.map((option, index) => ({
      ...option,
      name: option.practitionerName,
      id: index + 1,
      label: option.practitionerName,
      value: index + 1
    }));

    return options;
  };

  const getInitialValues = (optionsArray, selectedIndexes) => {
    if (optionsArray?.length >= selectedIndexes?.length || selectedIndexes.length > 0) {
      const newSelectedIndexes = selectedIndexes.map(value => value - 1);

      const selectedObjectsArray = _.at(optionsArray, newSelectedIndexes);
      return selectedObjectsArray;
    } else {
      return [];
    }
  };

  //formula net = ((quantity * unit price) * factor) + tax
  const addData = itemData => {
    let data = _.cloneDeep(itemData);

    // data["net"] = getNetValue(itemData);

    let tempFormData = [...formikValues.items];
    if (item) {
      tempFormData[currentItemIndex] = data;
    } else {
      tempFormData.push(data);
    }
    setMainFormFieldValue('items', tempFormData);
    resetForm();
    setCurrentItemIndex(null);
    setShowAddItemModal(false);
  };

  // const getNetValue = (values) => {
  //   let quantity = parseFloat(values.quantity);
  //   let unit_price = parseFloat(values.unit_price);
  //   let tax_amount = parseFloat(values.tax_amount);
  //   let factor = parseFloat(values.factor);

  //   return quantity * unit_price * factor + tax_amount;
  // };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue, isValid } =
    useFormik({
      initialValues: {
        type: item?.type ? item?.type : '',
        standardCode: item?.standardCode ? item?.standardCode : '',
        standardCodeDescription: item?.standardCodeDescription ? item?.standardCodeDescription : '',
        non_standard_code: item?.non_standard_code ? item?.non_standard_code : null,
        non_standard_description: item?.non_standard_description ? item?.non_standard_description : null,
        body_site: item?.body_site ? item?.body_site : '',
        sub_site: item?.sub_site ? item?.sub_site : '',
        service_start_date: item?.service_start_date
          ? item?.service_start_date
          : currentDate.toISOString().substring(0, 10),
        service_end_date: item?.service_end_date ? item?.service_end_date : '',
        supporting_info: supportingInfoOptions().map(item => item.id), // item?.supporting_info ? item?.supporting_info :
        diagnosis: diagnosisOptions().map(item => item.id), // item?.diagnosis ? item?.diagnosis :
        care_teams: careTeamsOptions().map(item => item.id), // item?.care_teams ? item?.care_teams :
        is_package: item?.is_package ? 'true' : 'false',
        quantity: item?.quantity ? item?.quantity : 1,
        unit_price: item?.unit_price ? item?.unit_price : 1,
        tax_amount: item?.tax_amount ? item?.tax_amount : 0,
        factor: item?.factor ? item?.factor : 1,
        net: item?.net ? item?.net : 1,
        discount_amount: item?.discount_amount ? item?.discount_amount : 0,
        patient_share: item?.patient_share ? item?.patient_share : 0,
        patientShareAmount: item?.patientShareAmount ? item?.patientShareAmount : 0,
        payer_share: item?.payer_share ? item?.payer_share : 1,
        medicationSelectionReason: item?.medicationSelectionReason ? item?.medicationSelectionReason : '',
        prescribedMedication: item?.prescribedMedication ? item?.prescribedMedication : '',
        days_supply: item?.days_supply ? item?.days_supply : 1
      },

      enableReinitialize: true,

      validationSchema: () => {
        return Yup.object().shape({
          type: Yup.string().required('Type is required'),
          standardCode: Yup.string().required('Standard code is required'),
          standardCodeDescription: Yup.string().required('Standard code description is required'),
          // non_standard_code: Yup.number()
          //   .nullable()
          //   .required("Non-standard code is required"),
          // non_standard_description: Yup.string()
          //   .nullable()
          //   .required("Non-standard description is required"),
          // body_site: Yup.string().required("Body site is required"),
          // sub_site: Yup.string().required("Sub site is required"),
          // service_start_date: Yup.string().required("Service start date is required"),
          // service_end_date: Yup.string().required("Service end date is required"),
          // supporting_info: Yup.array().required("Supporting info is required"),
          // diagnosis: Yup.array().required("Diagnosis is required"),
          // care_teams: Yup.array().required("Care teams is required"),
          is_package: Yup.string().required('Is package is required'),
          quantity: Yup.string().required('Quantity is required'),
          unit_price: Yup.string().required('Unit price is required'),
          // invoice_no: Yup.string().required("Invoice No is required"),
          // tax_amount: Yup.string().required("Tax amount is required"),
          // factor: Yup.string().required("Factor is required"),
          // net: Yup.string().required("Net is required"),
          discount_amount: Yup.string().required('Discount amount is required'),
          // patient_share: Yup.string().required("Patient share is required"),
          patientShareAmount: Yup.string().required('Patient share amount is required'),
          care_teams: Yup.array().min(1, 'At least one care team required').nullable(),
          diagnosis: Yup.array().min(1, 'At least one diagonse required').nullable(),
          // payer_share: Yup.string().required("Payer share is required"),

          medicationSelectionReason:
            values?.type === 'medication-codes'
              ? Yup.string().required('Medication selection reason is required')
              : Yup.string(),

          prescribedMedication:
            values?.type === 'medication-codes'
              ? Yup.string().required('Prescribed medication is required')
              : Yup.string(),
          days_supply:
            values?.type === 'medication-codes' ? Yup.string().required('Enter number of days supply') : Yup.string()
        });
      },
      onSubmit: (values, { setSubmitting }) => {
        addData(values);
      }
    });

  useEffect(() => {
    setFieldValue(
      'supporting_info',
      supportingInfoOptions().map(item => item.id)
    );
  }, [formikValues?.supportingInfos]);

  useEffect(() => {
    setFieldValue(
      'diagnosis',
      diagnosisOptions().map(item => item.id)
    );
  }, [formikValues?.diagnosisArr]);

  useEffect(() => {
    setFieldValue(
      'care_teams',
      careTeamsOptions().map(item => item.id)
    );
  }, [formikValues?.careTeamsArr]);

  useEffect(() => {
    if (values.quantity && values.unit_price && values.factor) {
      setFieldValue(
        'net',
        parseFloat(values.quantity * values.unit_price * values.factor + values.tax_amount).toFixed(2)
      );
      // setFieldValue(
      //   "discount_amount",
      //   (
      //     values.unit_price -
      //     (
      //       values.quantity * values.unit_price * values.factor +
      //       values.tax_amount
      //     ).toFixed(2)
      //   ).toFixed(2)
      // );
    }
  }, [values.unit_price, values.factor]);

  // useEffect(() => {
  //   if (values.discount_amount && values.unit_price) {
  //     setFieldValue(
  //       "factor",
  //       (1 - values.discount_amount / values.unit_price).toFixed(2)
  //     );
  //   }
  // }, [values.discount_amount, values.unit_price]);

  const {
    loading: loadingSearchPriceListCode,
    error: errorSearchPriceListCode,
    data: dataSearchPriceListCode,
    refetch: refetchSearchPriceListCode
  } = useQuery(priceList.SEARCH_PRICE_LIST_CODE, {
    variables: {
      input: '',
      payerId: formikValues?.payerId || patient?.insurance_plans[0]?.payer_id
    },
    skip: true
  });

  const handleSearch = term => {
    let rcmPayerId = rcmDetail?.insurance_plans?.length ? rcmDetail?.insurance_plans[0]?.payer_id : '';
    refetchSearchPriceListCode({
      input: term,
      payerId: formikValues?.payerId || rcmPayerId || '2117'
    }).then(data => {
      setSearchResult(data?.data?.searchPriceListCode?.data || []);
    });
  };

  const debouncedSearch = _.debounce(handleSearch, 1000);

  const handleSearchChange = event => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    if (newSearchTerm.length >= 3) {
      debouncedSearch(newSearchTerm);
    } else {
      setSearchResult([]);
    }
  };

  const handleSelectSearchItem = item => {
    setFieldValue('type', item?.service_type);
    setFieldValue('id', item?.id);
    setFieldValue('standardCode', item?.service_code);
    setFieldValue('standardCodeDescription', item?.service_desc);
    setFieldValue('non_standard_code', item?.non_standard_code);
    setFieldValue('non_standard_description', item?.non_standard_desc);
    setFieldValue('unit_price', item?.unit_price);
    setFieldValue('factor', item?.factor);

    setSearchResult([]);
    setSearchTerm('');
  };

  useEffect(() => {
    if (values?.type === 'medication-codes') {
      setFieldValue('medicationSelectionReason', 'Generic');
    } else {
      setFieldValue('medicationSelectionReason', '');
      setFieldValue('prescribedMedication', '');
    }
  }, [values?.type]);

  return (
    <div className={`${showAddItemModal ? 'open' : ''} modal `} id='search-filter'>
      <div class='modal-content !w-full'>
        <div class='close'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            onClick={() => setShowAddItemModal(() => !showAddItemModal)}
          >
            <path
              d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z'
              fill='#DE5042'
            />
          </svg>
          <div class='bene-text !text-center mx-auto'>Add Item</div>
        </div>
        <div className='add_item_fields grid grid-cols-1 lg:grid-cols-2 mb-4'>
          <div className='field flex flex-col gap-1 relative'>
            <span className='absolute inset-y-0 left-0 pl-3 flex items-center'>
              <BiSearch className='h-5 w-5 text-gray-400' />
            </span>
            <input
              type='text'
              placeholder='Search for item by Service Code or Service Description'
              onChange={handleSearchChange}
              value={searchTerm}
              className='block w-full py-2 !px-10 '
            />
            {searchTerm.length > 0 && (
              <span
                className='absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer'
                onClick={() => {
                  setSearchResult([]);
                  setSearchTerm('');
                }}
              >
                <BiX className='h-7 w-7 text-gray-400' />
              </span>
            )}
            {searchResult.length > 0 ? (
              <ul className='absolute top-full left-0 z-50 bg-white border border-gray-300 rounded-md mt-1 w-full max-h-72 overflow-y-auto'>
                {searchResult.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelectSearchItem(item)}
                    className='py-1 px-3 cursor-pointer hover:bg-gray-100 border-b border-gray-300'
                  >
                    <div className='flex gap-3'>
                      <div className='my-auto w-36 font-semibold capitalize '>{item?.service_type}</div>
                      <div className='pl-3 border-l border-gray-500'>
                        <div className='border-b border-gray-200'>
                          {item?.service_code}
                          {' | '}
                          {item?.service_desc}
                        </div>
                        <div>
                          {item?.non_standard_code}
                          {' | '}
                          {item?.non_standard_desc}
                        </div>
                      </div>
                    </div>
                    {/* Assuming item has a 'name' property */}
                  </li>
                ))}
              </ul>
            ) : (
              searchTerm.length > 3 && (
                <ul className='absolute top-full left-0 z-50 bg-white border border-gray-300 rounded-md mt-1 w-full max-h-72 overflow-y-auto'>
                  <li className='py-1 px-3 cursor-pointer font-medium text-center text-lg'>No Result Found</li>
                </ul>
              )
            )}
          </div>
        </div>
        <div className='add_item_fields grid grid-cols-1 lg:grid-cols-5 gap-3'>
          <div className='field flex flex-col gap-1'>
            <label htmlFor='Select-Type'>Select Type*</label>
            <select name='type' onChange={handleChange} value={values.type} id='Select-Type'>
              <option value=''>Select Type*</option>
              {types.map((item, i) => {
                return (
                  <option key={i} value={item.id}>
                    <p>{item.name}</p>
                  </option>
                );
              })}
            </select>
            {errors.type && touched.type ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{errors.type}</p>
            ) : null}
          </div>
          <div className='field flex flex-col gap-1'>
            <label htmlFor='Select-Type'>
              Search for Code {' ('}
              {values.standardCode ? values.standardCode : ''}
              {')'}
            </label>

            <ItemCodesSearchNSelect
              typeName={values.type}
              setFieldValue={val => {
                setFieldValue(`standardCode`, val?.code);
                setFieldValue(`standardCodeDescription`, val?.description);
                if (values?.type === 'medication-codes') {
                  setFieldValue(`prescribedMedication`, val?.code);
                } else {
                  setFieldValue(`prescribedMedication`, '');
                }
              }}
            />
            {errors.standardCode && touched.standardCode ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{errors.standardCode}</p>
            ) : null}
            {errors.standardCodeDescription && touched.standardCodeDescription ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{errors.standardCodeDescription}</p>
            ) : null}
          </div>

          <div className='field flex flex-col gap-1'>
            <label htmlFor='Select-Type'>Non Standard Code</label>
            <input
              type='text'
              name='non_standard_code'
              value={values.non_standard_code}
              onChange={handleChange}
              placeholder='Non Standard Code'
            />
          </div>
          <div className='field flex flex-col gap-1'>
            <label htmlFor='Select-Type'>Non Standard Description</label>
            <input
              type='search'
              name='non_standard_description'
              value={values.non_standard_description}
              onChange={handleChange}
              placeholder='Non Standard Description'
            />
          </div>

          {/* <div className="field flex flex-col gap-1">
            <label htmlFor="Body-Site">Body Site</label>
            <select
              name="body_site"
              onChange={handleChange}
              value={values.body_site}
              id="Body-Site"
            >
              <option value="">Body Site</option>
              {values.type == "oral-health-op"
                ? oralItems.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })
                : sites.map((item) => {
                    return (
                      <option value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
            </select>
          </div> */}
          <div className='field flex flex-col gap-1'>
            <label htmlFor='Body-Site'>Body Site</label>
            <div className='flex'>
              <select
                name='body_site'
                onChange={handleChange}
                value={values.body_site}
                id='Body-Site'
                className={`${
                  values.type == 'oral-health-op' || formikValues.claimType === 'oral'
                    ? 'rounded-md !rounded-r-none'
                    : ''
                }`}
              >
                <option value=''>Body Site</option>
                {values.type == 'oral-health-op' || formikValues.claimType === 'oral'
                  ? oralItems.map(item => {
                      return (
                        <option key={item.id} value={item.id}>
                          <p>{item.name}</p>
                        </option>
                      );
                    })
                  : sites.map(item => {
                      return (
                        <option value={item.id}>
                          <p>{item.name}</p>
                        </option>
                      );
                    })}
              </select>
              {values.type == 'oral-health-op' || formikValues.claimType === 'oral' ? (
                <Button
                  autoFocus
                  size='small'
                  onClick={() => {
                    setShowTeethSelectModal(true);
                  }}
                  variant='contained'
                  style={{
                    backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
                  }}
                  className='!rounded-l-none !rounded-r-md'
                >
                  {'Select'}
                </Button>
              ) : null}
            </div>
          </div>
          <div className='field flex flex-col gap-1'>
            <label htmlFor='Sub-Site'>Sub Site</label>
            <select name='sub_site' onChange={handleChange} value={values.sub_site} id='Sub-Site'>
              <option value=''>Sub Site</option>
              {values.type == 'oral-health-op'
                ? dentalSubSites.map(item => {
                    return (
                      <option value={item.name}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })
                : subSites.map(item => {
                    return (
                      <option value={item.name}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
            </select>
          </div>

          <div className='field flex flex-col gap-1'>
            <label htmlFor='service_start_date'>Service Start Date</label>
            <input
              type='date'
              name='service_start_date'
              min={todayDate}
              value={values.service_start_date}
              onChange={handleChange}
              placeholder='Service Start Date'
            />
          </div>
          <div className='field flex flex-col gap-1'>
            <label htmlFor='service_end_date'>Service End Date</label>
            <input
              type='date'
              name='service_end_date'
              min={todayDate}
              value={values.service_end_date}
              onChange={handleChange}
              placeholder='Service End Date'
            />
          </div>

          <div className='flex flex-col gap-1'>
            <label htmlFor='service_end_date'>Supporting Info</label>

            <MultiSelectComponent
              multiValues={supportingInfoOptions()}
              setSelectedValue={val => {
                let temp = val.map(item => item.id);
                setFieldValue('supporting_info', [...temp]);
              }}
              initialSelectedValues={getInitialValues(supportingInfoOptions(), values.supporting_info)}
            />
          </div>
          <div className='flex flex-col gap-1'>
            <label htmlFor='service_end_date'>Diagnosis</label>

            <MultiSelectComponent
              multiValues={diagnosisOptions()}
              setSelectedValue={val => {
                let temp = val.map(item => item.id);
                setFieldValue('diagnosis', [...temp]);
              }}
              initialSelectedValues={getInitialValues(diagnosisOptions(), values.diagnosis)}
            />
            {errors.diagnosis && touched.diagnosis ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{errors.diagnosis}</p>
            ) : null}
          </div>
          <div className='flex flex-col gap-1'>
            <label htmlFor='service_end_date'>Care Teams</label>

            <MultiSelectComponent
              multiValues={careTeamsOptions()}
              setSelectedValue={val => {
                let temp = val.map(item => item.id);
                setFieldValue('care_teams', [...temp]);
              }}
              initialSelectedValues={getInitialValues(careTeamsOptions(), values.care_teams)}
            />
            {errors.care_teams && touched.care_teams ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{errors.care_teams}</p>
            ) : null}
          </div>

          {/* <div className="field flex flex-col gap-1">
            <label htmlFor="service_end_date">Is Package? *</label>
            <select
              name="is_package"
              onChange={handleChange}
              value={values.is_package}
              id="Is-Package"
            >
              <option value="">Is Package? *</option>
              <option value={true}>Yes</option>
              <option selected value={false}>
                No
              </option>
            </select>
            {errors.is_package && touched.is_package ? (
              <p className="max-w-2xl text-sm leading-6 text-red-500">
                {errors.is_package}
              </p>
            ) : null}
          </div> */}
          <div className='field flex flex-col gap-1'>
            <label htmlFor='is_package'>Is Package? *</label>
            <select
              name='is_package'
              onChange={handleChange}
              value={values.is_package ? 'true' : 'false'}
              id='Is-Package'
            >
              <option value=''>Is Package? *</option>
              <option value='true'>Yes</option>
              <option value='false'>No</option>
            </select>
            {errors.is_package && touched.is_package ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{errors.is_package}</p>
            ) : null}
          </div>

          {values.type == 'medication-codes' && (
            <>
              <div className='field flex flex-col gap-1'>
                <label htmlFor='medicationSelectionReason' className='block text-sm font-medium text-gray-700'>
                  Medication Selection Reason <span className='text-sm text-red-500'> *</span>
                </label>
                <select
                  id='medicationSelectionReason'
                  name='medicationSelectionReason'
                  onChange={handleChange}
                  value={values.medicationSelectionReason}
                  autoComplete='type-name'
                  className='block w-full rounded-md border-gray-300 focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm'
                >
                  <option value=''>
                    <p>Please Select</p>
                  </option>
                  {medSelectionReasons.map(item => {
                    return (
                      <option value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
                </select>
                {errors.medicationSelectionReason && touched.medicationSelectionReason ? (
                  <p className='max-w-2xl text-sm leading-6 text-red-500'>{errors.medicationSelectionReason}</p>
                ) : null}
              </div>
              <div className='field flex flex-col gap-1'>
                <label htmlFor='prescribedMedication' className='block text-sm font-medium text-gray-700'>
                  Prescribed Medication{' ('}
                  {values.prescribedMedication ? values.prescribedMedication : ''}
                  {')'} <span className='text-sm text-red-500'> *</span>
                </label>

                <ItemCodesSearchNSelect
                  typeName={values.type}
                  setFieldValue={val => {
                    setFieldValue(`prescribedMedication`, val.code);
                  }}
                />
                {errors.prescribedMedication && touched.prescribedMedication ? (
                  <p className='max-w-2xl text-sm leading-6 text-red-500'>{errors.prescribedMedication}</p>
                ) : null}
              </div>
              <div className='field flex flex-col gap-1'>
                <label htmlFor='days_supply' className='block text-sm font-medium text-gray-700'>
                  Days Supply *
                </label>
                <input
                  type='number'
                  name='days_supply'
                  value={values.days_supply}
                  onChange={handleChange}
                  placeholder='Enter number of days'
                />
                {errors.days_supply && touched.days_supply ? (
                  <p className='max-w-2xl text-sm leading-6 text-red-500'>{errors.days_supply}</p>
                ) : null}
              </div>
            </>
          )}
        </div>
        <Divider className='my-4' />
        <div className='add_item_fields grid grid-cols-1 lg:grid-cols-5 gap-3'>
          <div className='field flex flex-col gap-1'>
            <label htmlFor='service_end_date'>Invoice No*</label>
            <input
              type='text'
              name='invoice_no'
              value={values.invoice_no}
              onChange={handleChange}
              placeholder='Invoice No.'
              disabled
            />
            {errors.invoice_no && touched.invoice_no ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{errors.invoice_no}</p>
            ) : null}
          </div>

          <div className='field flex flex-col gap-1'>
            <label htmlFor='service_end_date'>Enter Quantity</label>
            <input
              placeholder='Enter Quantity'
              type='number'
              name='quantity'
              value={values.quantity}
              onChange={handleChange}
              onBlur={handleBlur}
              id='quantity'
            />
            {errors.quantity && touched.quantity ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{errors.quantity}</p>
            ) : null}
          </div>
          <div className='field flex flex-col gap-1'>
            <label htmlFor='service_end_date'>Enter Unit Price</label>
            <input
              disabled
              placeholder='Enter Unit Price'
              type='number'
              name='unit_price'
              value={values.unit_price}
              onChange={handleChange}
              // onChange={(e) => {
              //   handleChange(e);
              //   if (values.discount_amount && values.unit_price) {
              //     setFieldValue(
              //       "factor",
              //       (1 - values.discount_amount / values.unit_price).toFixed(2)
              //     );
              //   }
              // }}
              // onBlur={handleBlur}
              id='unit_price'
            />
            {errors.unit_price && touched.unit_price ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{errors.unit_price}</p>
            ) : null}
          </div>

          <div className='field flex flex-col gap-1'>
            <label htmlFor='service_end_date'>Discount Amount *</label>
            <input
              disabled
              type='number'
              name='discount_amount'
              value={values.discount_amount}
              onChange={handleChange}
              // onChange={(e) => {
              //   handleChange(e);
              //   if (values.discount_amount && values.unit_price) {
              //     setFieldValue(
              //       "factor",
              //       // (1 - values.discount_amount / values.unit_price).toFixed(1)
              //       (
              //         1 -
              //         values.discount_amount /
              //           (values.quantity * values.unit_price)
              //       ).toFixed(2)
              //     );
              //   }
              // }}
              placeholder='Discount Amount *'
            />
            {errors.discount_amount && touched.discount_amount ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{errors.discount_amount}</p>
            ) : null}
          </div>

          <div className='field flex flex-col gap-1'>
            <label htmlFor='service_end_date'>Patient Share (%)</label>
            <input
              disabled
              type='number'
              name='patient_share'
              value={values.patient_share}
              onChange={handleChange}
              placeholder='Patient Share (%)'
            />
          </div>

          <div className='field flex flex-col gap-1'>
            <label htmlFor='service_end_date'>Tax Amount</label>
            <input
              disabled
              type='number'
              name='tax_amount'
              value={values.tax_amount}
              min={0}
              onChange={handleChange}
              placeholder='Tax Amount'
              className='!w-full'
            />
          </div>
          <div className='field flex flex-col gap-1'>
            <label htmlFor='service_end_date'>Factor</label>

            <input
              disabled
              type='number'
              name='factor'
              value={values.factor}
              onChange={handleFactorChange}
              onBlur={handleBlur}
              placeholder='Factor'
            />
          </div>

          <div className='field flex flex-col gap-1'>
            <label htmlFor='service_end_date'>Net *</label>
            <input disabled type='number' name='net' value={values.net} onChange={handleChange} placeholder='Net *' />
          </div>

          <div className='field flex flex-col gap-1'>
            <label htmlFor='service_end_date'>Patient Share Amount *</label>
            <input
              disabled
              type='number'
              name='patientShareAmount'
              value={values.patientShareAmount}
              onChange={handleChange}
              placeholder='Patient Share Amount *'
            />
            {errors.patientShareAmount && touched.patientShareAmount ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{errors.patientShareAmount}</p>
            ) : null}
          </div>
          <div className='field flex flex-col gap-1'>
            <label htmlFor='service_end_date'>Payer Share</label>
            <input
              disabled
              type='text'
              name='payer_share'
              value={values.payer_share}
              onChange={handleChange}
              placeholder='Payer Share'
            />
          </div>
        </div>
        <div class='request-eligible-btns mt-8 justify-center'>
          <div
            class='cancel-btn'
            onClick={() => {
              resetForm();
              setShowAddItemModal(false);
            }}
          >
            <span>Cancel</span>
          </div>
          <div class='request-btn' onClick={handleSubmit} disabled={!isValid}>
            <span>{item ? 'Update' : 'Save'}</span>
          </div>
        </div>
      </div>
      <DentalTeethSelectModalItem
        setShowTeethSelectModal={setShowTeethSelectModal}
        showTeethSelectModal={showTeethSelectModal}
        setFieldValue={setFieldValue}
      />
    </div>
  );
}

export default AddItemModal;
