import React, { useEffect, useState } from 'react';
import { getCreditInvoiceList, ShowCreditInvocieApi } from './ApiMethods';
import { formatDate } from './CashTable';
import CustomPagination from './CustomPagination';
import { useNavigate } from 'react-router-dom';
import AppLoader from '../../../components/AppLoader';
import { Tooltip } from '@mui/material';
import { MdOutlineRemoveRedEye } from 'react-icons/md';

function CreditInvoiceTable() {
  const navigate = useNavigate();
  const [invoiceList, setInvoiceList] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCreditInvoiceList(setLoading, setInvoiceList, {
      page: 1,
      per_page: 8
    });
  }, []);

  const tableHeaders = [
    { name: 'Invoice Number' },
    { name: 'Date' },
    { name: 'Patient Name' },
    { name: 'Patient Share' },
    { name: 'Total Vat' },
    { name: 'File Number' },
    { name: 'Nationality' },
    { name: 'Status' },
    { name: 'Description' },
    { name: 'Actions' }
  ];

  const onPageChange = (newPage, newRowsPerPage) => {
    getCreditInvoiceList(setLoading, setInvoiceList, {
      page: newPage,
      per_page: newRowsPerPage
    });
  };

  const handelShowClick = data => {
    ShowCreditInvocieApi(setLoading, data?.id).then(response => {
      if (response?.status === 200) {
        navigate('/receptionist/show-invoice', {
          state: { data: response?.data }
        });
      }
    });
  };

  return (
    <>
      <div className='overflow-x-auto'>
        <div className='super-git-table !p-0 !m-0'>
          <table className='table-super-git !p-0'>
            <thead className='!p-0'>
              <tr className='!p-0'>
                {tableHeaders.map((el, index) => (
                  <th className='!p-2 !text-sm !bg-[#373d45]' key={'tableHeaders_' + index}>
                    {el.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {invoiceList?.data?.length > 0 ? (
                invoiceList.data.map((el, index) => (
                  <tr key={'invoiceData_' + index} className='!p-0'>
                    <td className='!p-1 !text-sm'>{el?.invoice_number}</td>
                    <td className='!p-1 !text-sm'>{formatDate(el?.date)}</td>
                    <td className='!p-1 !text-sm capitalize text-start'>{el?.customer_name}</td>
                    <td className='!p-1 !text-sm'>{el?.patient_share}</td>
                    <td className='!p-1 !text-sm'>
                      {el?.total_vat % 1 !== 0 ? el.total_vat?.toFixed(2) : el?.total_vat}
                    </td>
                    <td className='!p-1 !text-sm'>{el?.file_number}</td>
                    <td className='!p-1 !text-sm'>{el?.nationality}</td>
                    <td className='!p-1 !text-sm'>{el?.status}</td>
                    <td className='!p-1 !text-sm'>{el?.description}</td>

                    <td className='flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1'>
                      <Tooltip title='View'>
                        <div onClick={() => handelShowClick(el)} className='text-xl cursor-pointer text-green-500'>
                          <MdOutlineRemoveRedEye />
                        </div>
                      </Tooltip>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={tableHeaders.length} className='px-4 py-2 text-center text-sm text-gray-700'>
                    No invoices found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <CustomPagination
          currentPage={invoiceList?.page}
          rowsPerPage={invoiceList?.per_page}
          totalRecords={invoiceList?.total_record}
          onPageChange={onPageChange}
        />
        <AppLoader setShowLoader={setLoading} showLoader={loading} />
      </div>
    </>
  );
}

export default CreditInvoiceTable;
