import axios from 'axios';
import toast from 'react-hot-toast';

//LIve server
export const HIS_BACKEND_URL = 'https://apihis.supergitsa.com/api/v1';
export const ERP_BACKEND_URL = 'https://apierp.supergitsa.com/api/v1';
export const ApiUrl = 'https://labapi.supergitsa.com/api/';

export const apiHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    Authorization: '9bd9f28796f87a40b689b5e3fdf4f93aae4aefeb21b8c9a3892299525539753a'
  };
};

// Set up an axios instance
const apiClient = axios.create({
  baseURL: ApiUrl
});

// Add a request interceptor
apiClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = '9bd9f28796f87a40b689b5e3fdf4f93aae4aefeb21b8c9a3892299525539753a';
    }
    return config;
  },
  error => {
    // Handle request error here
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      // The server responded with a status outside the range 2xx
      const status = error.response.status;
      const data = error.response.data;

      switch (status) {
        case 400:
          console.error('Bad Request:', data.message || data);
          toast.error(data.message || data.error);
          break;
        case 401:
          console.error('Unauthorized:', data.message || data);
          toast.error(data.message || data.error);
          // Optional: Redirect to login or logout user
          break;
        case 403:
          console.error('Forbidden:', data.message || data);
          toast.error(data.message || data.error);
          break;
        case 404:
          console.error('Not Found:', data.message || data);
          toast.error(data.message || data.error);
          break;
        case 500:
          console.error('Internal Server Error:', data.message || data);
          toast.error(data.message || data.error);
          break;
        default:
          console.error(`Unhandled HTTP Error: ${status}`, data.message || data);
          toast.error(`Unhandled HTTP Error: ${status}`, data.message || data);
      }
    } else if (error.request) {
      // No response was received from the server
      console.error('No Response:', error.request);
    } else {
      // Other errors (e.g., network issues, config errors)
      console.error('Error:', error.message);
    }

    return Promise.reject(error);
  }
);

export default apiClient;
