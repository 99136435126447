import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function Spinner({ size = '30px', customClass }) {
  return (
    <div>
      <CircularProgress size={size} className={`${customClass ? customClass : '!text-[#ffab2c]'}`} />
    </div>
  );
}
