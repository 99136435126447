import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import {
  getAppoinmentsByID,
  getAppoinmentsList,
  getMedicalByID,
  getPatientByID,
  getPatientList,
  SearchPatient
} from './ApiMethods';
import { Avatar } from '@mui/material';
import { FemaleOutlined, MaleOutlined } from '@mui/icons-material';
import PatientAppoinmentHistory from './PatientAppoinmentHistory';
import PatientMedicalHistory from './PatientMedicalHistory';
import AppLoader from '../../../components/AppLoader';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import EmrForm from './EmrForm';
import { useCallback } from 'react';
import Spinner from './Spinner';

export default function NewAppointmentMain() {
  const [searchText, setSearchText] = useState('');
  const [Loading, setLoading] = useState(false);
  const [loading, setloading] = useState(false);
  const [activeTab, setActiveTab] = useState('appointment');
  const [open, setOpen] = useState(false);
  const [patientID, setPatientID] = useState(null);
  const [appointmentslist, setAppointmentList] = useState(null);
  const user = localStorage?.getItem('user');
  const businessId = JSON.parse(user)?.providers_id;
  const branchId = JSON.parse(user)?.providers_branch_id;
  const [patientList, setPatientList] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const prevSelectedPatientid = localStorage.getItem('visit_selected_patient_id');
  const [searchLoading, setSearchLoading] = useState(false);
  const practitioner_id = localStorage.getItem('practitioner_id');

  useEffect(() => {
    const fetchPatientList = async () => {
      setloading(true); // Start loading
      const user = JSON.parse(localStorage.getItem('user'));
      const practitionerId = user?.practitioner_id || null;
      await getPatientList(setloading, setPatientList, {
        practitioner_id: practitionerId
      });

      setLoading(false);
    };

    fetchPatientList();
  }, []);

  useEffect(() => {
    setFilteredPatients(
      patientList?.data?.filter(patient => patient.full_name?.toLowerCase().includes(searchText.toLowerCase()))
    );
  }, [searchText, patientList]);

  useEffect(() => {
    if (prevSelectedPatientid) {
      setPatientID(prevSelectedPatientid);
    }
  }, []);

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const fetchPatients = text => {
    setLoading(false);
    SearchPatient(setloading, setPatientList, {
      query: text
    });
    setSearchLoading(false);
  };

  const debouncedFetchPatients = useCallback(debounce(fetchPatients, 500), []);

  const handleSearchChange = e => {
    const value = e.target.value;
    setSearchText(value);
    setSearchLoading(true);
    setLoading(false);
    setPatientID(null);
    if (value) {
      debouncedFetchPatients(value);
    } else {
      getPatientList(setloading, setPatientList, {
        practitioner_id: practitioner_id || ''
      });
      setSearchLoading(false);
    }
  };

  return (
    <>
      <div className='grid grid-cols-6 gap-2 bg-white'>
        <div
          className={`col-span-1 hidden xl:block bg-gray-100 border border-gray-100 p-1 rounded-md h-[85vh]  flex-col`}
        >
          <div className='relative rounded-md shadow-sm mb-2'>
            <input
              type='text'
              name='search'
              id='search'
              onChange={handleSearchChange}
              className='block w-full rounded-md border-0 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              placeholder={'Search '}
            />
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
              {searchLoading ? (
                <Spinner size='20px' />
              ) : (
                <MagnifyingGlassIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
              )}
            </div>
          </div>

          <div className='h-[59vh] sm:h-[70vh] '>
            {/* This ensures it takes available space */}
            <ul className=' overflow-y-auto h-[76vh]'>
              {filteredPatients?.map((patient, index) => (
                <li
                  key={index}
                  className={`p-2 shadow-sm mb-2 flex rounded-md hover:shadow-2xl cursor-pointer ${
                    patientID === patient.patient_id ? 'bg-orange-500 text-white' : 'bg-white'
                  }`}
                  onClick={() => {
                    setPatientID(patient.patient_id);
                    localStorage.setItem('visit_selected_patient_id', patient.patient_id);
                  }}
                >
                  <div className='flex items-center'>
                    <Avatar
                      label={patient?.data ? patient?.full_name.charAt(0) : 'S'}
                      className='mr-2 text-black w-[2.5rem] h-[2.5rem]'
                      size='large'
                    />
                  </div>
                  <div>
                    <p className='font-bold flex text-sm gap-2 items-center justify-center'>
                      {patient?.full_name} &nbsp; ({patient.document_id})
                      <span className='mt-0.5'>
                        {patient?.gender === 'male' ? <MaleOutlined /> : <FemaleOutlined />}
                      </span>
                    </p>
                    <p className='text-xs'>ID: {patient.patient_id}</p>
                    <p className='text-xs'>DID: {patient.document_id}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          className={`relative xl:col-span-5 col-span-6 border bg-gray-100 p-1 border-gray-100 rounded-md shadow-inner h-[85vh] ${'col-span-5'}`}
        >
          <span className='absolute left-[10px] top-[5px] cursor-pointer xl:hidden' onClick={() => setOpen(true)}>
            <MenuIcon className='w-[35px] h-[35px]' />
          </span>
          <div className='h-[59vh] sm:h-[73vh] overflow-y-auto'>
            <PatientAppoinmentHistory
              data={appointmentslist}
              patientID={patientID}
              setAppointmentList={setAppointmentList}
              setAppApiloading={setLoading}
              app='true'
            />
          </div>
        </div>
      </div>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <div className=' col-span-1 bg-gray-100 border border-gray-100 p-2  h-[80vh]'>
          <div className='relative  shadow-sm mb-2'>
            <input
              type='text'
              name='search'
              id='search'
              onChange={handleSearchChange}
              className='block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
              placeholder={'Search '}
            />
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
              {searchLoading ? (
                <Spinner size='20px' />
              ) : (
                <MagnifyingGlassIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
              )}
            </div>
          </div>
          <div>
            <ul className='h-[60vh] overflow-y-auto'>
              {filteredPatients?.map((patient, index) => (
                <li
                  key={index}
                  className={`p-2 shadow-sm  mb-2 flex rounded-md hover:shadow-2xl cursor-pointer ${
                    patientID === patient.patient_id ? ' bg-orange-500 text-white' : 'bg-white '
                  } `}
                  onClick={() => {
                    setPatientID(patient.patient_id);
                    localStorage.setItem('visit_selected_patient_id', patient.patient_id);
                  }}
                >
                  <div className='flex items-center'>
                    <Avatar
                      label={patient.patient_name ? patient.patient_name.charAt(0) : 'S'}
                      className='mr-2 text-black w-[2.5rem] h-[2.5rem]'
                      size='large'
                    />
                  </div>
                  <div>
                    <p className='font-bold flex text-sm gap-2 items-center justify-center'>
                      {patient?.full_name}

                      <span className='mt-0.5'>{patient?.gender === 'M' ? <MaleOutlined /> : <FemaleOutlined />}</span>
                    </p>

                    <p className='text-xs text-gray-600'>ID: {patient.patient_id}</p>
                    <p className='text-xs text-gray-600'>DID: {patient.document_id}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Drawer>
      <AppLoader showLoader={Loading} />
    </>
  );
}
