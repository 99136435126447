import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { FaArrowLeft } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';

import CreateNewHomeCareEntries from './CreateNewHomeCareEntries';
import ViewHomeCareFormEntries from './ViewHomeCareFormEntries';

const HomeCareFormEntries = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const visitId = location?.state?.visitId;
  const patient = location?.state?.patient;

  const [activeTab, setActiveTab] = useState('view');
  const tabList = [
    {
      label: 'View Saved Entries',
      comapre: 'view',
      command: () => setActiveTab('view')
    },
    {
      label: 'Create New Entry',
      comapre: 'newEntry',
      command: () => setActiveTab('newEntry')
    }
  ];
  return (
    <React.Fragment>
      <h2 className='text-xl font-semibold mb-2 flex items-center gap-1 p-1 rounded-md text-gray-800'>
        <div className='flex justify-end items-end cursor-pointer' onClick={() => navigate(-1)}>
          <IconButton aria-label='go back' className='!mr-2 !w-[30px] !h-[30px] !text-white !bg-red-400 !rounded-full'>
            <FaArrowLeft />
          </IconButton>
        </div>
        Back
      </h2>
      <div className={`border bg-gray-100 p-2 border-gray-100 rounded-md shadow-inner`}>
        <ul className='grid grid-flow-col mb-2 text-center border rounded-md text-gray-500 p-1'>
          {tabList?.map(tab => (
            <li key={tab.comapre}>
              <a
                className={`flex justify-center py-2 cursor-pointer hover:text-primary ${
                  activeTab === tab?.comapre ? 'gradient-background rounded shadow text-white' : ''
                }`}
                onClick={() => tab?.command()}
              >
                {tab.label}
              </a>
            </li>
          ))}
        </ul>
        <div className='h-[90vh] lg:h-[70vh] overflow-y-auto mt-3'>
          {activeTab === 'view' ? (
            <ViewHomeCareFormEntries visitId={visitId} patient={patient} />
          ) : activeTab === 'newEntry' ? (
            <CreateNewHomeCareEntries visitId={visitId} />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeCareFormEntries;
