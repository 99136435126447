import React, { useEffect, useRef, useState } from 'react'
import { Chip } from '@mui/material'
import { formatDateTime } from '../../util/FomateDateTime'
import moment from 'moment'

function ViewVitalReportsModal ({
  openVitalReportsModal,
  setOpenVitalReportsModal,
  reportsData,
  visit_date,
  isVitalSigns
}) {
  const modalRef = useRef(null)
  const printRef = useRef(null)
  const patientData = reportsData?.data?.[0]?.patient ?? null
  const [progressNotesData, setProgressnotesData] = useState([])
  const [vitalSignsData, setVitalSignsData] = useState([])
  const [nursingStaffData, setNursignStaffData] = useState([])
  const [age, setAge] = useState(null)
  const user = localStorage?.getItem('user')
  const parseduser = JSON.parse(user)

  const [diagnosis, setDiagnosis] = useState([])
  const prinfRef = useRef(null)
  const iframeRef = useRef(null)

  useEffect(() => {
    if (reportsData?.data?.length) {
      let progressNotes = reportsData?.data?.map(data => {
        return {
          date: data?.created_at,
          notes: data?.doctor_remarks,
          initialIds: data?.careTeamsArr?.map(team => team?.practitionerName)
        }
      })
      let staffData = reportsData?.data?.map(data => {
        return {
          date: data?.created_at,
          staffNames: data?.careTeamsArr?.map(team => team?.practitionerName)
        }
      })
      let existingVitalSigns = [
        'vital-sign-systolic',
        'vital-sign-diastolic',
        'vital-sign-height',
        'vital-sign-weight',
        'temperature',
        'respiratory-rate',
        'pulse',
        'SP02',
        'RBS',
        'pain-score'
      ]
      let vitalSigns = reportsData?.data?.map(data => {
        return {
          date: data?.created_at,
          signs: data?.supportingInfos?.map(data => {
            if (existingVitalSigns?.includes(data?.category)) {
              return data
            }
          }),
          signatureName: data?.careTeamsArr?.[0]?.practitionerName || '',
          signatureId: data?.patient?.document_id
        }
      })
      let diagnosisData = reportsData?.data?.[0]?.diagnosisArr?.map(data => {
        return {
          name: data?.codeDescription
        }
      })

      if (patientData?.dob) {
        handleCalculateAge(patientData?.dob)
      }
      setNursignStaffData(staffData)
      setVitalSignsData(vitalSigns)
      setProgressnotesData(progressNotes)
      setDiagnosis(diagnosisData)
    }
  }, [reportsData])

  const handelModelClose = () => {
    setOpenVitalReportsModal(false)
    setNursignStaffData([])
    setProgressnotesData([])
    setVitalSignsData([])
    setDiagnosis([])
  }

  const handlePrint = () => {
    let printContents = printRef.current.innerHTML
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = printContents
    printContents = tempDiv.innerHTML

    // Get the iframe reference
    const iframe = iframeRef.current
    const doc = iframe.contentWindow.document

    doc.open()
    doc.write('<html><head><title>List of Reports</title>')
    doc.write(
      '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css">'
    )
    doc.write('<style>')
    doc.write(`
      @media print {
        @page {
          margin: 20px 0 20px 0;
        }
        body {
          margin: 1cm;
        }
        img{
          height:100px;
          width:100px;
        }
        h5 {
          font-size:22px;
          font-weight:600;
        }
        .pateint-heading{
          width:100px;
        }
        .ps-2{
          padding:4px;
        }
        th {
          padding: 10px !important; 
          text-align: center !important; 
          font-size: 12px !important; 
          border: 1px solid #ccc; 
        }
        td {
          padding: 8px !important; 
          border: 1px solid #ccc; 
        }
      }
    `)
    doc.write('</style>')
    // Write the content to the iframe
    doc.write('</head><body>')
    doc.write(printContents)
    doc.write('</body></html>')
    doc.close()

    // Trigger print on iframe load
    iframe.onload = () => {
      iframe.contentWindow.print()
    }
  }

  const handleCalculateAge = birthDate => {
    if (birthDate) {
      const today = moment()
      const dob = moment(birthDate)
      const years = today.diff(dob, 'years')
      dob.add(years, 'years')
      const months = today.diff(dob, 'months')
      dob.add(months, 'months')
      const days = today.diff(dob, 'days')
      if (years > 0) {
        setAge(`${years} years`)
      } else if (months > 0) {
        setAge(`${months} months`)
      } else if (days > 0) {
        setAge(`${days} days`)
      } else {
        setAge('Less than a day')
      }
    }
  }

  return (
    <div>
      <div
        className={`${
          openVitalReportsModal ? 'open' : ''
        } add-item-modal modal relative`}
        id='myModal'
      >
        <div
          ref={modalRef}
          className='modal-content absolute !top-[5%] bg-white left-0 mx-auto !h-[90vh] max-h-[90vh] !w-[100%] !max-w-[1500px] overflow-y-auto !z-[9999999]'
          style={{ overflowY: 'unset' }}
        >
          <div className='flex items-center justify-between pb-2'>
            <div className='close gap-3 flex items-center !mb-0'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                onClick={handelModelClose}
              >
                <path
                  d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z'
                  fill='#DE5042'
                />
              </svg>
              <div className='bene-text'>List of Reports</div>
            </div>
            <Chip
              label={'Print Data'}
              color={'success'}
              size='small'
              onClick={handlePrint}
              className='!mr-[2px] rounded !cursor-pointer w-[130px] font-[600] !h-[32px]'
              style={{ fontSize: '1rem', padding: '2px 4px' }}
              variant='contained'
              sx={{
                backgroundImage:
                  'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                borderColor: '#f14f3e',
                color: 'white',
                '&:hover': {
                  borderColor: '#f14f3e'
                },
                '&:focus': {
                  borderColor: '#f14f3e'
                }
              }}
            />
          </div>
          <div
            className='w-full h-full border !border-[#000] px-2 pt-4 pb-2 overflow-y-auto'
            ref={printRef}
          >
            <div className='flex items-start justify-around'>
              <div className='border !border-[#000] w-[350px]'>
                <div className='flex items-center border-b border-[#000] px-2 patient-card '>
                  <div className='w-[100px] pateint-heading border-r border-[#000]'>
                    <p className='py-1'>Name</p>
                  </div>
                  <div className='flex-1 ps-2'>
                    <p>{patientData?.full_name}</p>
                  </div>
                </div>
                <div className='flex items-center border-b border-[#000] px-2 patient-card'>
                  <div className='w-[100px] pateint-heading border-r border-[#000]'>
                    <p className='py-1'>MRN</p>
                  </div>
                  <div className='flex-1 ps-2'>
                    <p>{patientData?.mrn}</p>
                  </div>
                </div>
                <div className='flex items-center border-b border-[#000] px-2 patient-card'>
                  <div className='w-[100px] pateint-heading border-r border-[#000]'>
                    <p className='py-1'>Gender</p>
                  </div>
                  <div className='flex-1 ps-2'>
                    <p>{patientData?.gender}</p>
                  </div>
                </div>
                <div className='flex items-center border-b border-[#000] px-2 patient-card'>
                  <div className='w-[100px] pateint-heading border-r border-[#000]'>
                    <p className='py-1'>Age</p>
                  </div>
                  <div className='flex-1 ps-2'>
                    <p>{age}</p>
                  </div>
                </div>
                <div className='flex items-center border-b border-[#000] px-2 patient-card'>
                  <div className='w-[100px] pateint-heading border-r border-[#000]'>
                    <p className='py-1'>Visit Date</p>
                  </div>
                  <div className='flex-1 ps-2'>
                    <p>{formatDateTime(visit_date)}</p>
                  </div>
                </div>
                <div className='flex items-center px-2 patient-card'>
                  <div className='w-[100px] pateint-heading'>
                    <p className='py-1'>Diagnosis</p>
                  </div>
                  <div className='flex-1 ps-2 border-l border-[#000]'>
                    {diagnosis?.map(data => (
                      <span>{data?.name}</span>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <img
                  src={`data:image/png;base64,${parseduser?.business_logo}`}
                  alt='logo'
                  className='w-[120px] h-[120px]'
                />
              </div>
            </div>
            {!isVitalSigns ? (
              <div>
                <h5 className='text-center !text-[22px] font-[600] pt-4'>
                  Home Healthcare Patient Progress Notes
                </h5>
                <table className='w-full border !border-[#000] p-2'>
                  <thead className='border !border-[#000] !border-t-0'>
                    <tr className='h-[40px] bg-gray-200 '>
                      <th className='view-reports-table-th w-[200px] view-reports-table-th'>
                        Date
                      </th>
                      <th className='view-reports-table-th view-reports-table-th'>
                        Notes
                      </th>
                      <th className='view-reports-table-th w-[150px] view-reports-table-th'>
                        Initial ID#
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {progressNotesData?.map(
                      (data, index) =>
                        data?.notes && (
                          <tr key={index} className=' border-b border-[#000]'>
                            <td className='view-reports-table-th w-[200px]'>
                              {formatDateTime(data?.date)}
                            </td>
                            <td className='view-reports-table-th'>
                              <p
                                className='pb-1'
                                dangerouslySetInnerHTML={{
                                  __html: data?.notes || ''
                                }}
                              ></p>
                            </td>
                            <td className='view-reports-table-th w-[150px]'>
                              {data?.initialIds?.map(data => (
                                <p className='pb-1'>{data}</p>
                              ))}
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                <h5 className='text-center !text-[22px] font-[600] pt-4'>
                  Vital Signs
                </h5>
                <table className='w-full border !border-[#000] p-2'>
                  <thead className='border !border-[#000] !border-t-0'>
                    <tr className='h-[40px] bg-gray-200'>
                      <th className='view-reports-table-th w-[200px]'>Date</th>
                      <th className='view-reports-table-th w-[200px]'>Time</th>
                      <th className='view-reports-table-th w-[150px]'>
                        BP (mm[Hg])
                      </th>
                      <th className='view-reports-table-th w-[150px]'>
                        PR (/min)
                      </th>
                      <th className='view-reports-table-th w-[150px]'>
                        RR (/min)
                      </th>
                      <th className='view-reports-table-th w-[150px]'>
                        Temp (cel)
                      </th>
                      <th className='view-reports-table-th w-[150px]'>
                        SP02 (%)
                      </th>
                      <th className='view-reports-table-th w-[150px]'>
                        Pain Score
                      </th>
                      <th className='view-reports-table-th w-[150px]'>
                        RBS (mg/dl)
                      </th>
                      <th className='view-reports-table-th w-[150px]'>
                        Staff Name and Discipline
                      </th>
                      <th className='view-reports-table-th w-[150px]'>
                        Signature and ID Number
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {vitalSignsData?.map((data, index) => (
                      <tr key={index} className='border-b border-[#000]'>
                        <td className='view-reports-table-th w-[200px]'>
                          {' '}
                          {moment(data?.date).format('YYYY-MM-DD')}
                        </td>
                        <td className='view-reports-table-th w-[200px]'>
                          {' '}
                          {data?.date
                            ? moment(data?.date).format('hh:mm A')
                            : '-'}
                        </td>
                        <td className='view-reports-table-th w-[150px]'>
                          {data?.signs?.find(
                            sign => sign?.category === 'vital-sign-systolic'
                          )?.value || ''}
                        </td>
                        <td className='view-reports-table-th w-[150px]'>
                          {data?.signs?.find(sign => sign?.category === 'pulse')
                            ?.value || ''}
                        </td>
                        <td className='view-reports-table-th w-[150px]'>
                          {data?.signs?.find(
                            sign => sign?.category === 'respiratory-rate'
                          )?.value || ''}
                        </td>
                        <td className='view-reports-table-th w-[150px]'>
                          {data?.signs?.find(
                            sign => sign?.category === 'temperature'
                          )?.value || ''}
                        </td>
                        <td className='view-reports-table-th w-[150px]'>
                          {data?.signs?.find(sign => sign?.category === 'SP02')
                            ?.value || ''}
                        </td>
                        <td className='view-reports-table-th w-[150px]'>
                          {data?.signs?.find(
                            sign => sign?.category === 'pain-score'
                          )?.value || ''}
                        </td>
                        <td className='view-reports-table-th w-[150px]'>
                          {data?.signs?.find(sign => sign?.category === 'RBS')
                            ?.value || ''}
                        </td>
                        <td className='view-reports-table-th w-[150px]'>
                          {data?.signatureName || ''}
                        </td>
                        <td className='view-reports-table-th w-[150px]'>
                          {data?.signatureId || ''}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <iframe ref={iframeRef} style={{ display: 'none' }}></iframe>
    </div>
  )
}

export default ViewVitalReportsModal
