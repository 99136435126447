import { Formik, useFormikContext } from "formik";
import { Switch } from "@headlessui/react";
import _ from "lodash";
import { useEffect, useMemo } from "react";
// import AdmissionSpecialitySearchNSelect from "./AdmissionSpecialitySearchNSelect";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function EncounterForm({ onChange, refId }) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();

  const currentDate = new Date().toISOString().split("T")[0];
  const nextDate = new Date();
  nextDate.setDate(nextDate.getDate() + 1);
  const defaultEndDate = nextDate.toISOString().split("T")[0];

  const status = [
    { id: "planned", name: "Planned" },
    { id: "arrived", name: "Arrived" },
    { id: "triaged", name: "Triaged" },
    { id: "in-progress", name: "In Progress" },
    { id: "onleave", name: "On Leave" },
    // { id: "finished", name: "Finished" },
    // { id: "cancelled", name: "Cancelled" },
    // { id: "entered-in-error", name: "Entered in Error" },
    // { id: "unknown", name: "Unknown" },
  ];

  // Dynamic encounter classes based on claimType and subType
  const classes = useMemo(() => {
    const baseClasses = [
      { id: "AMB", name: "Ambulatory" },
      { id: "EMER", name: "Emergency" },
      { id: "HH", name: "Home Health" },
      { id: "IMP", name: "Inpatient Encounter" },
      { id: "SS", name: "Short Stay" },
      { id: "FLD", name: "Field Care" },
      { id: "OBSENC", name: "Observation" },
      { id: "PREV", name: "Pre-admission" },
      { id: "VR", name: "Virtual" },
      { id: "OTH", name: "Other Encounters" },
    ];

    // Customize classes based on claimType
    switch (values.claimType) {
      case "institutional":
        return [
          { id: "IMP", name: "Inpatient Encounter" },
          { id: "SS", name: "Short Stay" },
        ];

      case "professional":
        return values.subType === "op"
          ? [
              { id: "VR", name: "Virtual" },
              { id: "AMB", name: "Ambulatory" },
              { id: "OTH", name: "Other Encounters" },
            ]
          : [{ id: "EMER", name: "Emergency" }];

      case "oral":
        return [{ id: "AMB", name: "Ambulatory" }];

      default:
        return baseClasses;
    }
  }, [values.claimType, values.subType]);

  const defaultClass = "AMB";

  const serviceEventType = [
    {
      id: "ICSE",
      name: "Initial client service event",
    },
    {
      id: "SCSE",
      name: "Subsequent client service event",
    },
  ];

  const defaultServiceEvent = "ICSE";

  const serviceTypes = [
    { id: "acute-care", name: "Acute Care" },
    { id: "sub-acute-care", name: "Sub-Acute Care" },
    { id: "rehabilitation", name: "Rehabilitation" },
    { id: "mental-behavioral", name: "Mental & Behavioral" },
    { id: "geriatric-care", name: "Geriatric Care" },
    // { id: "newborn", name: "Newborn" },
    // { id: "family-planning", name: "Family Planning" },
    // { id: "dental-care", name: "Dental Care" },
    // { id: "palliative-care", name: "Palliative Care" },
    // { id: "others", name: "Others" },
    // { id: "unknown", name: "Unknown" },
  ];

  const defaultServiceType = "acute-care";

  const adminSources = [
    { id: "IA", name: "IA - Immediate Admission" },
    {
      id: "EER",
      name: "EER - Admission from hospital ER",
    },
    {
      id: "EOP",
      name: "EOP - Emergency Admission from hospital outpatient",
    },
    {
      id: "EGPHC",
      name: "EGPHC - Emergency Admission by referral from government primary healthcare center",
    },
    {
      id: "EGGH",
      name: "EGGH - Emergency Admission by referral from general government hospital",
    },
    {
      id: "EPPHC",
      name: "EPPHC - Emergency Admission by referral from private primary healthcare center",
    },
    {
      id: "EPH",
      name: "EPH - Emergency Admission by referral from private hospital",
    },
    {
      id: "EIC",
      name: "EIC - Emergency Admission by insurance company",
    },
    {
      id: "EWGS",
      name: "EWGS - Elective waiting list admission government free Scheme",
    },
    {
      id: "EWSS",
      name: "EWSS - Elective waiting list admission self-payment Scheme",
    },
    {
      id: "EWIS",
      name: "EWIS - Elective waiting list admission insurance coverage Scheme",
    },
    {
      id: "EMBA",
      name: "EMBA - Emergency Maternity Birth Admission",
    },
    {
      id: "PMBA",
      name: "PMBA - Planned Maternity Birth Admission",
    },
    { id: "Others", name: "Others" },
  ];

  const dischargeDispositions = [
    { id: "acute-hospital", name: "Discharge/transfer to an Acute Hospital" },
    { id: "SDTC", name: "Statistical Discharge-Type Change" },
    { id: "SDFL", name: "Statistical discharge from leave" },
    { id: "home", name: "Home/Other" },
    {
      id: "DTRAS",
      name: "Discharge /Transfer to a Residential Ageing Service",
    },
    { id: "DTPH", name: "Discharge/Transfer to a Psychiatric Hospital" },
    {
      id: "DTOHA",
      name: "Discharge /Transfer to Other Health Care Accommodation",
    },
    { id: "LAMA", name: "Left Against Medical Advice" },
    { id: "died", name: "Died" },
    { id: "in-hospital", name: "Patient still in hospital" },
  ];

  const emergencyArivalCode = [
    { id: "GEMSA", name: "Ground EMS Ambulance" },
    { id: "MOHA", name: "Ground MOH Ambulance" },
    { id: "GCDA", name: "Ground Civil Defense Ambulance" },

    { id: "GMA", name: "Ground Military Ambulance" },
    { id: "GPA", name: "Ground Private Ambulance" },
    { id: "EMSAA", name: "EMS Air Ambulance" },
    { id: "ACDA", name: "Air Civil Défense Ambulance" },
    { id: "AMA", name: "Air Military Ambulance" },
    { id: "PV", name: "Police Vehicle" },
    { id: "OGV", name: "Other Government Vehicles" },
    { id: "PV", name: "Personal Vehicle" },
    { id: "other", name: "Other" },
    { id: "unknown", name: "Not stated/unknown" },
  ];

  const emergencyDepartmentDisposition = [
    { id: "AH", name: "Admitted to this hospital" },
    {
      id: "NAD",
      name: "Non-admitted patient ED episode completed-departed",
    },
    {
      id: "NAR",
      name: "Non-admitted patient ED episode completed-referred",
    },
    { id: "DNW", name: "Did Not Wait" },
    { id: "LAOR", name: "Left at Own Risk" },
    { id: "DED", name: "Died in ED" },
    { id: "DOA", name: "Dead on Arrival" },
    { id: "R", name: "Registered" },
  ];

  const triageCategory = [
    { id: "IR", name: "Immediate resuscitation" },
    { id: "VU", name: "Very Urgent" },
    { id: "U", name: "Urgent" },
    { id: "SER", name: "Standard ER" },
    { id: "NU", name: "Non-Urgent" },
  ];

  const intendedLengthOfStays = [
    { id: "ISD", name: "Intended same day" },
    { id: "IO", name: "Intended overnight" },
  ];

  const reAdmission = [{ id: "R", name: "Re-Admission" }];

  const priorities = [
    // { id: "A", name: "ASAP" },
    // { id: "CR", name: "callback results" },
    // { id: "CS", name: "callback for scheduling" },
    // { id: "CSP", name: "callback placer for scheduling" },
    // { id: "CSR", name: "contact recipient for scheduling" },
    { id: "EL", name: "Elective" },
    { id: "EM", name: "Emergency" },
    // { id: "P", name: "preop" },
    // { id: "PRN", name: "as needed" },
    // { id: "R", name: "routine" },
    // { id: "RR", name: "rush reporting" },
    // { id: "S", name: "stat" },
    // { id: "T", name: "timing critical" },
    // { id: "UD", name: "use as directed" },
    // { id: "UR", name: "urgent" },
  ];

  useEffect(() => {
    if (
      values.claimType === "institutional" ||
      values.claimType === "professional" ||
      values.claimType === "oral"
    ) {
      setFieldValue("isEncounter", true);
    } else {
      setFieldValue("isEncounter", false);
    }
  }, [values.claimType]);

  return (
    <div class="personal-data !p-3 !mb-3 !bg-gray-100">
      <div class="personal-title !mb-3">
        <div class="inventory-svg">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="34" height="34" rx="8" fill="#F4F6F8"></rect>
            <path
              d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
              fill="#F5793B"
            ></path>
            <path
              d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
              fill="#F5793B"
            ></path>
          </svg>
        </div>
        <div class="personal-head">
          <span>Encounter</span>
          <div className="flex space-x-3 md:mt-0 md:ml-4 mr-2">
            <Switch
              checked={values.isEncounter ?? true} // Default to true if not already set
              onChange={() => setFieldValue("isEncounter", !values.isEncounter)}
              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-amber-600 focus:ring-offset-2"
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className="pointer-events-none absolute h-full w-full rounded-md bg-white"
              />
              <span
                aria-hidden="true"
                className={classNames(
                  values.isEncounter ? "bg-amber-600" : "bg-gray-200",
                  "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
                )}
              />
              <span
                aria-hidden="true"
                className={classNames(
                  values.isEncounter ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
      {values.isEncounter && (
        <>
          {/* {values.claimType === "institutional" ||
          values.claimType === "professional" ||
          values.claimType === "oral" ||
          values.claimType === "vission" ? ( */}
          <>
            <div class="encounter-fields">
              <span>Encounter</span>
            </div>
            <div class="personal-fields grid grid-cols-1 lg:grid-cols-2 gap-2">
              <div className="flex flex-col gap-1">
                <label htmlFor="status">Status*</label>
                <select
                  name="encounterStatus"
                  onChange={handleChange}
                  value={values.encounterStatus}
                  id="status"
                >
                  <option value={null}>Status*</option>
                  {status.map((item) => {
                    return (
                      <option value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
                </select>
                {errors.encounterStatus && touched.encounterStatus ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.encounterStatus}
                  </p>
                ) : null}
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="class">Class*</label>

                <select
                  name="encounterClass"
                  onChange={handleChange}
                  value={values.encounterClass}
                  id="class"
                >
                  <option value="">Class*</option>
                  {classes.map((item) => {
                    return (
                      <option value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
                </select>
                {errors.encounterClass && touched.encounterClass ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.encounterClass}
                  </p>
                ) : null}
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="serviceType">Service Type</label>
                <select
                  name="serviceType"
                  onChange={handleChange}
                  value={values.serviceType}
                  id="service-type"
                >
                  <option value="">Service Type</option>
                  {serviceTypes.map((item) => {
                    return (
                      <option value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* {values.claimType === "professional" ||
                values.claimType === "oral" ? ( */}
              <div className="flex flex-col gap-1">
                <label htmlFor="serviceEventType">Service Event Type*</label>

                <select
                  name="serviceEventType"
                  onChange={handleChange}
                  value={values.serviceEventType}
                  id="service-event-type"
                >
                  <option value={null}>Service Event Type*</option>
                  {serviceEventType.map((item) => {
                    return (
                      <option value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
                </select>
                {errors.serviceEventType && touched.serviceEventType ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.serviceEventType}
                  </p>
                ) : null}
              </div>
              {/* ) : null} */}
              <div className="flex flex-col gap-1">
                <label htmlFor="encounterPriority">Priority</label>
                <select
                  name="encounterPriority"
                  onChange={handleChange}
                  value={values.encounterPriority}
                  id="priority"
                >
                  <option value="">Priority</option>
                  {priorities.map((item) => {
                    return (
                      <option value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="periodStart">Period Start*</label>
                <input
                  type="date"
                  name="periodStart"
                  value={values.periodStart}
                  onChange={handleChange}
                  id="date"
                  placeholder="Period Start Date *"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                />
                {errors.periodStart && touched.periodStart ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.periodStart}
                  </p>
                ) : null}
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="periodEnd">Period End</label>
                <input
                  type="date"
                  name="periodEnd"
                  value={values.periodEnd}
                  onChange={handleChange}
                  id="date"
                  placeholder="Period End Date"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                />
                {errors.periodEnd && touched.periodEnd ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.periodEnd}
                  </p>
                ) : null}
              </div>
            </div>
          </>
          {/* ) : null} */}

          {/* {values.claimType === "professional" && values.subType === "emr" ? ( */}
          <>
            <div class="encounter-fields">
              <span>Encounter Emergency</span>
            </div>
            <div class="personal-fields grid grid-cols-1 lg:grid-cols-2 gap-2">
              <div className="flex flex-col gap-1">
                <label htmlFor="emergencyArivalCode">
                  Emergency Arival Code*
                </label>
                <select
                  name="emergencyArivalCode"
                  onChange={handleChange}
                  value={values.emergencyArivalCode}
                  id="emergencyArivalCode"
                >
                  <option value="">Emergency Arival Code</option>
                  {emergencyArivalCode.map((item) => {
                    return (
                      <option value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
                </select>
                {errors.emergencyArivalCode && touched.emergencyArivalCode ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.emergencyArivalCode}
                  </p>
                ) : null}
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="emergencyServiceDate">
                  Emergency Service Date*
                </label>
                <input
                  type="date"
                  name="emergencyServiceDate"
                  value={values.emergencyServiceDate}
                  onChange={handleChange}
                  id="emergencyServiceDate"
                  placeholder="Emergency Service Date"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                />
                {errors.emergencyServiceDate && touched.emergencyServiceDate ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.emergencyServiceDate}
                  </p>
                ) : null}
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="emergencyDepartmentDisposition">
                  Emergency Department Disposition
                </label>
                <select
                  name="emergencyDepartmentDisposition"
                  onChange={handleChange}
                  value={values.emergencyDepartmentDisposition}
                  id="emergencyDepartmentDisposition"
                >
                  <option value="">Emergency Department Disposition</option>
                  {emergencyDepartmentDisposition.map((item) => {
                    return (
                      <option value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
                </select>
                {errors.emergencyDepartmentDisposition &&
                touched.emergencyDepartmentDisposition ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.emergencyDepartmentDisposition}
                  </p>
                ) : null}
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="triageCategory">Triage Category*</label>
                <select
                  name="triageCategory"
                  onChange={handleChange}
                  value={values.triageCategory}
                  id="triageCategory"
                >
                  <option value="">Triage Category</option>
                  {triageCategory.map((item) => {
                    return (
                      <option value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
                </select>
                {errors.triageCategory && touched.triageCategory ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.triageCategory}
                  </p>
                ) : null}
              </div>

              <div className="flex flex-col gap-1">
                <label htmlFor="triageDate">Triage Date*</label>
                <input
                  type="date"
                  name="triageDate"
                  value={values.triageDate}
                  onChange={handleChange}
                  id="triageDate"
                  placeholder="Triage Date"
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                />
                {errors.triageDate && touched.triageDate ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.triageDate}
                  </p>
                ) : null}
              </div>
            </div>
          </>
          {/* ) : null} */}
          {/* {values.claimType === "institutional" ? ( */}
          <>
            <div class="encounter-fields">
              <span>Hospitalization</span>
            </div>
            <div class="personal-fields grid grid-cols-1 lg:grid-cols-2 gap-2">
              <div className="flex flex-col gap-1">
                <label htmlFor="adminSource">Admin Source*</label>
                <select
                  name="adminSource"
                  onChange={handleChange}
                  value={values.adminSource}
                  id="adminsource"
                >
                  <option value="">Admin Source</option>
                  {adminSources.map((item) => {
                    return (
                      <option value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
                </select>
                {errors.adminSource && touched.adminSource ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.adminSource}
                  </p>
                ) : null}
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="readmission">Re-Admission</label>
                <select
                  name="readmission"
                  onChange={handleChange}
                  value={values.readmission}
                  id="readmission"
                >
                  <option value="">Readmission</option>
                  {reAdmission.map((item) => {
                    return (
                      <option value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
                </select>
                {errors.readmission && touched.readmission ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.readmission}
                  </p>
                ) : null}
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="dischargeDisposition">
                  Discharge Disposition
                </label>
                <select
                  name="dischargeDisposition"
                  onChange={handleChange}
                  value={values.dischargeDisposition}
                  id="discharge-disposition"
                >
                  <option value="">Discharge Disposition</option>
                  {dischargeDispositions.map((item) => {
                    return (
                      <option value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
                </select>
                {errors.dischargeDisposition && touched.dischargeDisposition ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.dischargeDisposition}
                  </p>
                ) : null}
              </div>
              {/* <div>
                  <label htmlFor="admissionSpeciality">
                    Admission Speciality*{" "}
                    {values.admissionSpeciality &&
                      `(${values.admissionSpeciality})`}
                  </label>
                  <AdmissionSpecialitySearchNSelect
                    setFieldValue={(val) => {
                      setFieldValue(`admissionSpeciality`, val.code);
                    }}
                  />
                  {errors.admissionSpeciality && touched.admissionSpeciality ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {errors.admissionSpeciality}
                    </p>
                  ) : null}
                </div>
                <div>
                  <label htmlFor="dischargeSpeciality">
                    Discharge Speciality*{" "}
                    {values.dischargeSpeciality &&
                      `(${values.dischargeSpeciality})`}
                  </label>
                  <AdmissionSpecialitySearchNSelect
                    setFieldValue={(val) => {
                      setFieldValue(`dischargeSpeciality`, val.code);
                    }}
                  />
                  {errors.dischargeSpeciality && touched.dischargeSpeciality ? (
                    <p className="max-w-2xl text-sm leading-6 text-red-500">
                      {errors.dischargeSpeciality}
                    </p>
                  ) : null}
                </div> */}

              <div className="flex flex-col gap-1">
                <label htmlFor="intendedLengthOfStay">
                  Intended Length Of Stay*
                </label>
                <select
                  name="intendedLengthOfStay"
                  onChange={handleChange}
                  value={values.intendedLengthOfStay}
                  id="intendedLengthOfStay"
                >
                  <option value={null}>Intended Length Of Stay</option>
                  {intendedLengthOfStays.map((item) => {
                    return (
                      <option value={item.id}>
                        <p>{item.name}</p>
                      </option>
                    );
                  })}
                </select>
                {errors.intendedLengthOfStay && touched.intendedLengthOfStay ? (
                  <p className="max-w-2xl text-sm leading-6 text-red-500">
                    {errors.intendedLengthOfStay}
                  </p>
                ) : null}
              </div>
            </div>
          </>
          {/* ) : null} */}
        </>
      )}
    </div>
  );
}

export default EncounterForm;
