import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
  Divider,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";

// API endpoint
const API_URL = "https://labapi.supergitsa.com/api";

// Validation schema using Yup
const ElementSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  name_en: Yup.string().required("Element Name (English) is required"),
  name_ar: Yup.string().required("Element Name (Arabic) is required"),
  sub_category_id: Yup.number().required("Sub-category ID is required"),
  value: Yup.number().required("Value is required"),
  unit: Yup.string().required("Unit is required"),
  comment: Yup.string(),
});

export default function ElementList({ selectedSubCategoryId }) {
  const [elements, setElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [openElementDialog, setOpenElementDialog] = useState(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [elementToDelete, setElementToDelete] = useState(null);

  const fetchElements = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/element/all/${selectedSubCategoryId}`
      );
      if (response.status === 200) {
        setElements(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching elements:", error);
      toast.error("Failed to fetch elements");
    }
  };

  useEffect(() => {
    fetchElements();
  }, [selectedSubCategoryId]);

  const handleAddElement = () => {
    setSelectedElement(null); // Reset selected element
    setOpenElementDialog(true);
  };

  const handleEditElement = (element) => {
    setSelectedElement(element); // Set the element to be edited
    setOpenElementDialog(true);
  };

  const handleDeleteElement = (element) => {
    setElementToDelete(element);
    setConfirmDeleteDialog(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${API_URL}/element/${elementToDelete.id}`);
      setConfirmDeleteDialog(false);
      fetchElements();
      toast.success("Element deleted successfully");
    } catch (error) {
      console.error("Error deleting element:", error);
      toast.error("Failed to delete element");
    }
  };

  const handleSaveElement = async (values, { resetForm }) => {
    try {
      if (selectedElement) {
        // Update element
        await axios.put(`${API_URL}/element/${selectedElement.id}`, values);
        fetchElements();
        toast.success("Element updated successfully");
      } else {
        // Add new element
        const response = await axios.post(`${API_URL}/element`, values);
        if (response.status === 200) {
          fetchElements();
          toast.success("Element added successfully");
        }
      }
      setOpenElementDialog(false);
      resetForm();
    } catch (error) {
      console.error("Error saving element:", error);
      toast.error("Failed to save element");
    }
  };

  const handleCancelAdd = () => {
    setOpenElementDialog(false);
  };

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 2, backgroundColor: "#fff" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Element</Typography>
          <IconButton onClick={handleAddElement} sx={{ color: "#ff5722" }}>
            <AddCircleOutlineIcon fontSize="large" />
          </IconButton>
        </Box>
        <Divider sx={{ backgroundColor: "#ff5722" }} />
        <List>
          {elements.map((element) => (
            <ListItem key={element.id}>
              <ListItemText
                primary={element.name_en}
                secondary={element.name_ar}
              />
              <IconButton
                onClick={() => handleEditElement(element)}
                sx={{ color: "#000" }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => handleDeleteElement(element)}
                sx={{ color: "#000" }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* Dialog for adding/updating element */}
      <Dialog open={openElementDialog} onClose={handleCancelAdd}>
        <DialogTitle>
          {selectedElement ? "Edit Element" : "Add New Element"}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              type: selectedElement?.type || "",
              name_en: selectedElement?.name_en || "",
              name_ar: selectedElement?.name_ar || "",
              sub_category_id: selectedSubCategoryId || "",
              value: selectedElement?.value || "",
              unit: selectedElement?.unit || "",
              comment: selectedElement?.comment || "",
            }}
            validationSchema={ElementSchema}
            onSubmit={handleSaveElement}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <FormControl fullWidth margin="dense" variant="outlined">
                  <InputLabel>Type</InputLabel>
                  <Select
                    name="type"
                    label="Type"
                    value={values.type}
                    onChange={handleChange}
                    error={touched.type && Boolean(errors.type)}
                  >
                    <MenuItem value="input">Input</MenuItem>
                    <MenuItem value="textarea">Textarea</MenuItem>
                    <MenuItem value="space">Space</MenuItem>
                    <MenuItem value="bool">Bool</MenuItem>
                    <MenuItem value="table-border">Table-Border</MenuItem>
                  </Select>
                  {touched.type && errors.type && (
                    <Typography color="error" variant="body2">
                      {errors.type}
                    </Typography>
                  )}
                </FormControl>
                <Field
                  as={TextField}
                  name="name_en"
                  label="Element Name (English)"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  onChange={handleChange}
                  error={touched.name_en && Boolean(errors.name_en)}
                  helperText={touched.name_en && errors.name_en}
                />
                <Field
                  as={TextField}
                  name="name_ar"
                  label="Element Name (Arabic)"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  onChange={handleChange}
                  error={touched.name_ar && Boolean(errors.name_ar)}
                  helperText={touched.name_ar && errors.name_ar}
                />

                <Field
                  as={TextField}
                  name="value"
                  label="Value"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  onChange={handleChange}
                  error={touched.value && Boolean(errors.value)}
                  helperText={touched.value && errors.value}
                />
                <FormControl fullWidth margin="dense" variant="outlined">
                  <InputLabel>Unit</InputLabel>
                  <Select
                    name="unit"
                    label="Unit"
                    value={values.unit}
                    onChange={handleChange}
                    error={touched.unit && Boolean(errors.unit)}
                  >
                    <MenuItem value="kg">kg</MenuItem>
                    <MenuItem value="g">g</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                  {touched.unit && errors.unit && (
                    <Typography color="error" variant="body2">
                      {errors.unit}
                    </Typography>
                  )}
                </FormControl>
                <Field
                  as={TextField}
                  name="comment"
                  label="Comment"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows={3}
                  onChange={handleChange}
                />
                <DialogActions>
                  <Button onClick={handleCancelAdd}>Cancel</Button>
                  <Button type="submit">Save</Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* Confirmation Dialog for Delete */}
      <Dialog
        open={confirmDeleteDialog}
        onClose={() => setConfirmDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this element?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteDialog(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
