import { Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { CheckIcon } from '@heroicons/react/20/solid';
import apiClient from '../formCreation/labAPiConfig';
import ReactSignatureCanvas from 'react-signature-canvas';
import toast from 'react-hot-toast';

function GeneralFormResults({ id, patientId }) {
  const [formFields, setFormFields] = useState({
    formHeader: [],
    formBody: [],
    formFooter: []
  });

  const getForm = useCallback(async () => {
    try {
      // Fetch the form data
      const formResponse = await apiClient.get(`category/${id}?request_type=general`);
      const formElements = {
        formHeader: [],
        formBody: [],
        formFooter: [],
        subCategories: []
      };

      if (formResponse?.data?.status === 200 && formResponse?.data?.data?.subcategories?.length) {
        formResponse?.data?.data?.subcategories?.forEach(subcategory => {
          if (subcategory?.elements) {
            const elementData = {
              type: subcategory.type,
              name_en: subcategory.name_en,
              name_ar: subcategory.name_ar,
              subcategoryId: subcategory?.id,
              elements: [...subcategory.elements]
            };

            // Push the elementData to the appropriate array based on the type
            switch (subcategory.type) {
              case 'header':
                formElements.formHeader.push(elementData);
                break;
              case 'body':
                formElements.formBody.push(elementData);
                break;
              case 'footer':
                formElements.formFooter.push(elementData);
                break;
              default:
                break;
            }
          }
        });
        if (!formElements?.formBody?.length) formElements.formBody = [];
        if (!formElements?.formHeader?.length) formElements.formHeader = [];
        if (!formElements?.formFooter?.length) formElements.formFooter = [];
        setFormFields(formElements);
      } else {
        setFormFields(formElements);
      }
    } catch (error) {
      console.error('Error fetching form or categories:', error);
    }
  }, [id]);

  useEffect(() => {
    getForm();
  }, [getForm]);

  const getInitialValues = () => {
    const initialValues = {};
    [...formFields?.formHeader, ...formFields?.formBody, ...formFields?.formFooter].forEach(field => {
      initialValues[field.id] = field.type === 'bool' ? false : field.value || '';
    });
    return initialValues;
  };

  const renderSubCategoryRows = formData => {
    if (!formData) return false;
    // Extract column count from subCategory name, default to 12 columns per row
    const match = formData?.name_en?.match(/Col(\d+)/);
    const columnCount = match ? parseInt(match[1], 10) : 1; // Default to 12 columns if no match
    const colSpan = 12 / columnCount; // Calculate column span per element

    if (!formData?.elements?.length) {
      console.warn('No elements found for the specified subCategory.');
    }

    // Group elements into rows based on the column span
    const rows = [];
    let currentRow = [];
    let currentRowWidth = 0;

    formData?.elements?.forEach(element => {
      const elementColSpan = element.type === 'heading' ? 12 : colSpan; // Full row for headings

      // Check if adding this element exceeds the row's width (12)
      if (currentRowWidth + elementColSpan > 12) {
        rows.push(currentRow); // Add the row if it's full
        currentRow = []; // Start a new row
        currentRowWidth = 0; // Reset the width
      }

      // Add the element to the current row
      currentRow.push({ ...element, colSpan: elementColSpan });
      currentRowWidth += elementColSpan;
    });

    // Push the last row if it's not empty
    if (currentRow.length > 0) {
      rows.push(currentRow);
    }

    // Render rows and columns
    return rows.map((row, rowIndex) => (
      <div className='row align-items-end' key={`row-${rowIndex}`}>
        {row.map((col, colIndex) => (
          <div className={`mb-2 col-${col.colSpan}`} key={`col-${colIndex}`}>
            {renderFields(colIndex, col)}
          </div>
        ))}
      </div>
    ));
  };

  const renderFields = (index, field) => {
    switch (field.type) {
      case 'input':
        return (
          <div className='form-group' key={index}>
            <label className='text-sm'>{field.name_en}</label>
            <Field name={`${field.id}.value`}>
              {({ field: formikField }) => (
                <input
                  required={field?.is_required}
                  {...formikField}
                  type='text'
                  placeholder={field.name_en || 'Enter text'}
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              )}
            </Field>
          </div>
        );
      case 'date':
        return (
          <div className='form-group' key={index}>
            <label className='text-sm'>{field.name_en}</label>
            <Field name={`${field.id}.value`}>
              {({ field: formikField }) => (
                <input
                  required={field?.is_required}
                  {...formikField}
                  type='date'
                  placeholder={field.name_en || 'Enter text'}
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              )}
            </Field>
          </div>
        );
      case 'time':
        return (
          <div className='form-group' key={index}>
            <label className='text-sm'>{field.name_en}</label>
            <Field name={`${field.id}.value`}>
              {({ field: formikField }) => (
                <input
                  required={field?.is_required}
                  {...formikField}
                  type='time'
                  placeholder={field.name_en || 'Enter text'}
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              )}
            </Field>
          </div>
        );
      case 'textarea':
        return (
          <div className='form-group' key={index}>
            <label className='text-sm mt-3'>{field.name_en}</label>
            <Field name={`${field.id}.value`}>
              {({ field: formikField }) => (
                <textarea
                  required={field?.is_required}
                  {...formikField}
                  rows='5'
                  placeholder={field.name_en || 'Enter text'}
                  className='mt-1 h-[130px] block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              )}
            </Field>
          </div>
        );
      case 'bool':
        return (
          <div key={index} className='form-group'>
            <Field name={`${field.id}.value`} type='checkbox'>
              {({ field: formikField }) => <input {...formikField} className='mr-2' type='checkbox' />}
            </Field>
            <label className='text-sm mt-3 ms-1'>{field.name_en || 'Check'}</label>
          </div>
        );
      case 'heading':
        return (
          <h1 key={index} className='mt-4 mb-2 font-semibold text-black/70 text-lg'>
            {field.name_en || ''}
          </h1>
        );
      case 'space':
        return (
          <div key={index} style={{ margin: '20px 0' }}>
            {field.name_en || 'Space'}
          </div>
        );
      case 'table-border':
        return (
          <div key={index} style={{ border: '1px solid black', padding: '10px', marginBottom: '15px' }}>
            {field.name_en || 'Table Border Content'}
          </div>
        );
      case 'image':
        return (
          <div className='form-group' key={index}>
            <label className='text-sm'>{field.name_en}</label>
            <div className='border border-gray-300 rounded my-2'>
              <img src={field?.value} alt='img' className='h-[200px] rounded w-full' />
            </div>
          </div>
        );
      case 'signature':
        return (
          <div className='form-group' key={index}>
            <label className='text-sm'>{field.name_en}</label>
            <Field name={`${field.id}.value`}>
              {({ field: formikField }) => (
                <div>
                  <ReactSignatureCanvas
                    ref={ref => (formikField.ref = ref)}
                    penColor='black'
                    canvasProps={{
                      className:
                        'mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                    }}
                    onEnd={() => {
                      const signatureData = formikField.ref.toDataURL();
                      formikField.onChange({
                        target: { name: `${field.id}.value`, value: signatureData }
                      });
                    }}
                  />
                  <div className='mt-2 flex space-x-2'>
                    <button
                      type='button'
                      className='text-sm bg-gray-200 px-3 py-1 rounded'
                      onClick={() => formikField.ref.clear()}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              )}
            </Field>
          </div>
        );
      default:
        return null;
    }
  };

  // Handle form submission
  const handleSubmit = async values => {
    const header = formFields?.formHeader
      ?.map(data => {
        if (data?.elements?.length) {
          return {
            ...data,
            elements: data.elements.map(element => ({
              ...element,
              value: values[element.id] // Directly assign the updated value
            }))
          };
        }
        return null;
      })
      ?.filter(data => data !== null);

    const body = formFields?.formBody
      ?.map(data => {
        if (data?.elements?.length) {
          return {
            ...data,
            elements: data.elements.map(element => ({
              ...element,
              value: values[element.id]
            }))
          };
        }
        return null;
      })
      ?.filter(data => data !== null);

    const footer = formFields?.formFooter
      ?.map(data => {
        if (data?.elements?.length) {
          return {
            ...data,
            elements: data.elements.map(element => ({
              ...element,
              value: values[element.id]
            }))
          };
        }
        return null;
      })
      ?.filter(data => data !== null);

    const dataToSubmit = {
      patient_id: String(patientId),
      data: {
        header,
        body,
        footer
      }
    };

    try {
      const response = await apiClient.post('internal-result-upload/general', dataToSubmit);
      if (response.data.status === 200) {
        console.log('Form submitted successfully:', response.data.message);
        toast.success('Result uploaded successfully');
      } else {
        console.error('Form submission failed:', response.data.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <>
      <div className='benefeciary-create'>
        <div style={{ margin: '0 auto', maxWidth: '1000px', width: '100%' }}>
          {formFields?.formHeader?.length || formFields?.formBody?.length || formFields?.formFooter?.length ? (
            <div class='personal-data !p-4 !mb-3'>
              <Formik
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={getInitialValues()}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className='form-container'>
                      {formFields?.formHeader && formFields?.formHeader?.map(data => renderSubCategoryRows(data))}
                      {formFields?.formBody && formFields?.formBody?.map(data => renderSubCategoryRows(data))}
                      {formFields?.formFooter && formFields?.formFooter?.map(data => renderSubCategoryRows(data))}
                    </div>

                    <div className='text-center mb-3 mt-2'>
                      <Button
                        variant='contained'
                        type='submit'
                        style={{
                          backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
                        }}
                        startIcon={<CheckIcon />}
                        size='small'
                        disabled={isSubmitting}
                      >
                        Save Results
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
}

export default GeneralFormResults;
