import React, { useState } from 'react';
import { Tabs, Tab, Button, Tooltip, InputAdornment, IconButton, TextField } from '@mui/material';
import { format } from 'date-fns';
import CustomPagination from '../HIS/components/CustomPagination';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import SearchIcon from '@mui/icons-material/Search';

const sampleDischargedPatients = [
  {
    admittedOn: '2024-11-10 08:45',
    dischargedOn: '2024-11-15 14:30',
    hospitalNo: '2411001234',
    ipNumber: 'H2400001',
    name: 'John Doe',
    ageSex: '45 Y/M',
    phone: '9876543210',
    billStatus: 'Paid'
  },
  {
    admittedOn: '2024-10-22 12:00',
    dischargedOn: '2024-11-03 10:15',
    hospitalNo: '2411001235',
    ipNumber: 'H2400002',
    name: 'Jane Smith',
    ageSex: '50 Y/F',
    phone: '9876543211',
    billStatus: 'Unpaid'
  },
  {
    admittedOn: '2024-11-05 16:45',
    dischargedOn: '2024-11-12 18:00',
    hospitalNo: '2411001236',
    ipNumber: 'H2400003',
    name: 'Alice Johnson',
    ageSex: '35 Y/F',
    phone: '9876543212',
    billStatus: 'Paid'
  },
  {
    admittedOn: '2024-11-06 09:30',
    dischargedOn: '2024-11-14 20:00',
    hospitalNo: '2411001237',
    ipNumber: 'H2400004',
    name: 'Bob Brown',
    ageSex: '28 Y/M',
    phone: '9876543213',
    billStatus: 'Pending'
  },
  {
    admittedOn: '2024-11-07 11:15',
    dischargedOn: '2024-11-16 13:45',
    hospitalNo: '2411001238',
    ipNumber: 'H2400005',
    name: 'Charlie Wilson',
    ageSex: '40 Y/M',
    phone: '9876543214',
    billStatus: 'Paid'
  }
];

const sampleAdmittedPatients = [
  {
    admittedOn: '2024-11-18 11:30',
    hospitalNo: '2411005678',
    ipNumber: 'H2400051',
    name: 'Emily Clark',
    ageSex: '30 Y/F',
    admittingDoctor: 'Dr. Adam Smith',
    bedFeature: 'Private Room',
    bedCode: 'PR-101'
  },
  {
    admittedOn: '2024-11-17 15:45',
    hospitalNo: '2411005679',
    ipNumber: 'H2400052',
    name: 'Michael Johnson',
    ageSex: '50 Y/M',
    admittingDoctor: 'Dr. Susan Brown',
    bedFeature: 'Semi-Private Room',
    bedCode: 'SP-202'
  },
  {
    admittedOn: '2024-11-16 09:15',
    hospitalNo: '2411005680',
    ipNumber: 'H2400053',
    name: 'Olivia Williams',
    ageSex: '40 Y/F',
    admittingDoctor: 'Dr. Emily Davis',
    bedFeature: 'ICU',
    bedCode: 'ICU-303'
  }
  // Add more sample admitted patients data as needed...
];

const DischargeSummary = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [fromDate, setFromDate] = useState('2024-11-13');
  const [toDate, setToDate] = useState('2024-11-20');

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className='p-2'>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label='Discharged Patients' />
        <Tab label='Admitted Patients' />
      </Tabs>

      {activeTab === 0 && (
        <div className='mt-4'>
          {/* <div className='my-4 flex items-center space-x-2 gap-6'>
            <TextField
              label='From'
              type='date'
              value={fromDate}
              size='small'
              onChange={e => setFromDate(e.target.value)}
              className='w-52 rounded'
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label='To'
              type='date'
              size='small'
              value={toDate}
              onChange={e => setToDate(e.target.value)}
              className='w-52 rounded'
              InputLabelProps={{ shrink: true }}
            />

            <Button size='small' variant='contained' color='success'>
              OK
            </Button>
          </div>
          <div className='flex justify-between items-center mb-4'>
            <TextField
              variant='outlined'
              size='small'
              placeholder='Search'
              className='mr-4 w-1/5'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button size='small' variant='contained' color='primary'>
              Print
            </Button>
          </div> */}
          <div className='super-git-table !p-0 !m-0'>
            <table className='table-super-git !p-0'>
              <thead className='!p-0'>
                <tr className='!p-0'>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Admitted On</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Discharged On</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Hospital No</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>IP Number</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Name</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Age/Sex</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Phone</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Bill Status</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {[]?.map((patient, index) => (
                  <tr key={index} className='!p-0'>
                    <td className='!p-1 !text-sm'>{format(new Date(patient.admittedOn), 'yyyy-MM-dd HH:mm')}</td>
                    <td className='!p-1 !text-sm'>{format(new Date(patient.dischargedOn), 'yyyy-MM-dd HH:mm')}</td>
                    <td className='!p-1 !text-sm'>{patient.hospitalNo}</td>
                    <td className='!p-1 !text-sm'>{patient.ipNumber}</td>
                    <td className='!p-1 !text-sm'>{patient.name}</td>
                    <td className='!p-1 !text-sm'>{patient.ageSex}</td>
                    <td className='!p-1 !text-sm'>{patient.phone}</td>
                    <td className='!p-1 !text-sm'>{patient.billStatus}</td>
                    <td className='flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1'>
                      <Tooltip title='View'>
                        <div className='text-xl cursor-pointer text-green-500'>
                          <MdOutlineRemoveRedEye />
                        </div>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <CustomPagination currentPage={1} rowsPerPage={5} totalRecords={5} onPageChange={() => {}} />
        </div>
      )}

      {activeTab === 1 && (
        <div className='mt-4'>
          {/* <div className='flex justify-between items-center mb-4'>
            <TextField
              variant='outlined'
              size='small'
              placeholder='Search'
              className='mr-4 w-1/5'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button size='small' variant='contained' color='primary'>
              Print
            </Button>
          </div> */}
          <div className='super-git-table !p-0 !m-0'>
            <table className='table-super-git !p-0'>
              <thead className='!p-0'>
                <tr className='!p-0'>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Admitted On</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Hospital No</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>IP Number</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Name</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Age/Sex</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Admitting Doctor</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Bed Feature</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Bed Code</th>
                  <th className='!p-2 !text-sm !bg-[#373d45]'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {[]?.map((patient, index) => (
                  <tr key={index} className='!p-0'>
                    <td className='!p-1 !text-sm'>{format(new Date(patient.admittedOn), 'yyyy-MM-dd HH:mm')}</td>
                    <td className='!p-1 !text-sm'>{patient.hospitalNo}</td>
                    <td className='!p-1 !text-sm'>{patient.ipNumber}</td>
                    <td className='!p-1 !text-sm'>{patient.name}</td>
                    <td className='!p-1 !text-sm'>{patient.ageSex}</td>
                    <td className='!p-1 !text-sm'>{patient.admittingDoctor}</td>
                    <td className='!p-1 !text-sm'>{patient.bedFeature}</td>
                    <td className='!p-1 !text-sm'>{patient.bedCode}</td>

                    <td className='flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1'>
                      <Tooltip title='View'>
                        <div className='text-xl cursor-pointer text-green-500'>
                          <MdOutlineRemoveRedEye />
                        </div>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <CustomPagination currentPage={1} rowsPerPage={5} totalRecords={5} onPageChange={() => {}} />
        </div>
      )}
    </div>
  );
};

export default DischargeSummary;
