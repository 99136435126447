import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
  Divider,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";

// API endpoint
const API_URL = "https://labapi.supergitsa.com/api";

// Validation schema using Yup
const SubCategorySchema = Yup.object().shape({
  category_id: Yup.number().required("Category ID is required"),
  type: Yup.string().required("Type is required"),
  name_en: Yup.string().required("SubCategory Name (English) is required"),
  name_ar: Yup.string().required("SubCategory Name (Arabic) is required"),
});

export default function SubCategoryList({
  selectedCategoryId,
  setSelectedSubCategoryId,
}) {
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [openSubCategoryDialog, setOpenSubCategoryDialog] = useState(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [subCategoryToDelete, setSubCategoryToDelete] = useState(null);

  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/sub-category/all/${selectedCategoryId}`
      );
      if (response.status === 200) {
        setSubCategories(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      toast.error("Failed to fetch subcategories");
    }
  };

  // Fetch data from the API
  useEffect(() => {
    fetchSubCategories();
  }, [selectedCategoryId]);

  const handleAddSubCategory = () => {
    setSelectedSubCategory(null); // Reset selected subcategory
    setOpenSubCategoryDialog(true);
  };

  const handleEditSubCategory = (subCategory) => {
    setSelectedSubCategory(subCategory); // Set the subcategory to be edited
    setOpenSubCategoryDialog(true);
  };

  const handleDeleteSubCategory = (subCategory) => {
    setSubCategoryToDelete(subCategory);
    setConfirmDeleteDialog(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${API_URL}/sub-category/${subCategoryToDelete.id}`);

      setConfirmDeleteDialog(false);
      fetchSubCategories();
      toast.success("SubCategory deleted successfully");
    } catch (error) {
      console.error("Error deleting subcategory:", error);
      toast.error("Failed to delete subcategory");
    }
  };

  const handleSaveSubCategory = async (values, { resetForm }) => {
    try {
      if (selectedSubCategory) {
        // Update subcategory
        await axios.put(
          `${API_URL}/sub-category/${selectedSubCategory.id}`,
          values
        );

        fetchSubCategories();
        toast.success("SubCategory updated successfully");
      } else {
        // Add new subcategory
        const response = await axios.post(`${API_URL}/sub-category`, values);
        if (response.status === 200) {
          fetchSubCategories();
          toast.success("SubCategory added successfully");
        }
      }
      setOpenSubCategoryDialog(false);
      resetForm();
    } catch (error) {
      console.error("Error saving subcategory:", error);
      toast.error("Failed to save subcategory");
    }
  };

  const handleCancelAdd = () => {
    setOpenSubCategoryDialog(false);
  };

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 2, backgroundColor: "#fff" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">SubCategory</Typography>
          <IconButton onClick={handleAddSubCategory} sx={{ color: "#ff5722" }}>
            <AddCircleOutlineIcon fontSize="large" />
          </IconButton>
        </Box>
        <Divider sx={{ backgroundColor: "#ff5722" }} />
        <List>
          {subCategories.map((subCategory) => (
            <ListItem
              key={subCategory.id}
              onClick={() => setSelectedSubCategoryId(subCategory.id)}
              className="cursor-pointer hover:bg-gray-100"
            >
              <ListItemText
                primary={subCategory.name_en}
                secondary={subCategory.name_ar}
              />
              <IconButton
                onClick={() => handleEditSubCategory(subCategory)}
                sx={{ color: "#000" }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => handleDeleteSubCategory(subCategory)}
                sx={{ color: "#000" }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* Dialog for adding/updating subcategory */}
      <Dialog open={openSubCategoryDialog} onClose={handleCancelAdd}>
        <DialogTitle>
          {selectedSubCategory ? "Edit SubCategory" : "Add New SubCategory"}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              category_id: selectedCategoryId || "",
              type: selectedSubCategory?.type || "",
              name_en: selectedSubCategory?.name_en || "",
              name_ar: selectedSubCategory?.name_ar || "",
            }}
            validationSchema={SubCategorySchema}
            onSubmit={handleSaveSubCategory}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <FormControl fullWidth margin="dense" variant="outlined">
                  <InputLabel>Type</InputLabel>
                  <Select
                    name="type"
                    label="Type"
                    value={values.type}
                    onChange={handleChange}
                    error={touched.type && Boolean(errors.type)}
                  >
                    <MenuItem value="header">Header</MenuItem>
                    <MenuItem value="body">Body</MenuItem>
                    <MenuItem value="footer">Footer</MenuItem>
                  </Select>
                  {touched.type && errors.type && (
                    <Typography color="error" variant="body2">
                      {errors.type}
                    </Typography>
                  )}
                </FormControl>
                <Field
                  as={TextField}
                  name="name_en"
                  label="SubCategory Name (English)"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  onChange={handleChange}
                  error={touched.name_en && Boolean(errors.name_en)}
                  helperText={touched.name_en && errors.name_en}
                />
                <Field
                  as={TextField}
                  name="name_ar"
                  label="SubCategory Name (Arabic)"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  onChange={handleChange}
                  error={touched.name_ar && Boolean(errors.name_ar)}
                  helperText={touched.name_ar && errors.name_ar}
                />
                <DialogActions>
                  <Button onClick={handleCancelAdd}>Cancel</Button>
                  <Button type="submit">Save</Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* Confirmation Dialog for Delete */}
      <Dialog
        open={confirmDeleteDialog}
        onClose={() => setConfirmDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this subcategory?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteDialog(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
