import React, { useEffect, useState } from 'react';

import FormList from '../../../components/formCreation/FormList';
import CategoryList from '../../../components/formCreation/CategoryList';
import HomeCareFormResult from '../../../components/homeCareEntries/HomeCareFormResult';

const CreateNewHomeCareEntries = ({ visitId }) => {
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedFormId, setSelectedFormId] = useState(null);

  useEffect(() => {
    setSelectedCategoryId(null);
    setSelectedFormId(null);
  }, [selectedReportId]);

  return (
    <div className='h-full overflow-auto'>
      <div className='grid grid-cols-12 gap-3'>
        <div className='col-span-12 xl:col-span-3'>
          <FormList selectedFormType={'visit'} setSelectedReportId={setSelectedReportId} isAllowActions={false} />
        </div>
        {selectedReportId && (
          <div className='col-span-12 xl:col-span-3'>
            <CategoryList
              selectedFormType={'visit'}
              selectedReportId={selectedReportId}
              setSelectedCategoryId={setSelectedCategoryId}
              setSelectedFormId={setSelectedFormId}
              isAllowActions={false}
            />
          </div>
        )}
        {selectedCategoryId && (
          <div className='col-span-12 xl:col-span-6'>
            <HomeCareFormResult id={selectedCategoryId} visitId={visitId} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateNewHomeCareEntries;
