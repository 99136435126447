import { Bar } from 'react-chartjs-2';

const PatientsChart = ({ chartData }) => {
  if (!chartData || chartData?.length === 0) {
    return (
      <div className='bg-white p-4 rounded-lg shadow-md flex flex-col items-center justify-center h-full w-full'>
        <h3 className='text-xl font-semibold'>Patient Chart</h3>
        <p className='text-gray-500'>No data available to display.</p>
      </div>
    );
  }

  // Process chartData dynamically
  const labels = [...new Set(chartData?.flatMap(item => item.status_breakdown.map(s => s.status)))];
  const datasets = chartData?.map((doctor, index) => ({
    label: doctor?.practitioner_Name,
    backgroundColor: [
      '#4E79A7', // Muted Blue
      '#A0CBE8', // Light Blue
      '#F28E2B', // Orange
      '#FFBE7D', // Light Orange
      '#59A14F', // Green
      '#8CD17D', // Light Green
      '#B6992D', // Olive
      '#499894', // Teal
      '#E15759', // Red
      '#FF9F40', // Light Orange-Red
      '#F1CE63', // Light Yellow
      '#BCBD22' // Yellow-Green
    ][index % 12], // Loop through colors
    borderWidth: 1,
    hoverBackgroundColor: [
      '#4E79A7',
      '#A0CBE8',
      '#F28E2B',
      '#FFBE7D',
      '#59A14F',
      '#8CD17D',
      '#B6992D',
      '#499894',
      '#E15759',
      '#FF9F40',
      '#F1CE63',
      '#BCBD22'
    ][index % 12],
    hoverBorderColor: [
      '#4E79A7',
      '#A0CBE8',
      '#F28E2B',
      '#FFBE7D',
      '#59A14F',
      '#8CD17D',
      '#B6992D',
      '#499894',
      '#E15759',
      '#FF9F40',
      '#F1CE63',
      '#BCBD22'
    ][index % 12],
    data: labels?.map(label => doctor?.status_breakdown?.find(status => status?.status === label)?.count || 0)
  }));

  const data = {
    labels,
    datasets
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          display: true
        }
      },
      y: {
        grid: {
          display: true
        },
        ticks: {
          beginAtZero: true
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top'
      },
      title: {
        display: true,
        text: 'Patient Visits by Status'
      }
    },
    barThickness: 15 // Adjust for bar size
  };

  return (
    <div className='bg-white p-4 rounded-lg shadow-md flex flex-col items-center justify-between h-full w-full'>
      <h3 className='text-xl font-semibold'>Patient Chart</h3>
      <div className='h-[500px] w-full'>
        <Bar data={data} options={options} height={260} />
      </div>
    </div>
  );
};

export default PatientsChart;
