import React, { useCallback, useEffect, useState } from 'react';
import { Button, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import debounce from 'lodash/debounce';

import { gerOrdersList } from '../components/ApiMethods';
import AppLoader from '../../../components/AppLoader';
import CustomPagination from './CustomPagination';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import OrderDetailsModal from '../../../components/orders/OrderDetailsModal';
import { formatDateTime } from '../../../util/FomateDateTime';

const OrdersList = () => {
  const [ordersList, setOrdersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    gerOrdersList(setLoading, setOrdersList, {
      page: 1,
      per_page: 10,
      query: searchTerm
    });
  }, []);

  const onPageChange = (newPage, newRowsPerPage) => {
    gerOrdersList(setLoading, setOrdersList, {
      page: newPage,
      per_page: newRowsPerPage,
      query: searchTerm
    });
  };

  const debouncedGetOrdersList = useCallback(
    debounce(query => {
      gerOrdersList(setLoading, setOrdersList, {
        page: 1,
        per_page: 10,
        query
      });
    }, 300),
    []
  );

  const handleSearchChange = e => {
    const query = e.target.value;
    setSearchTerm(query);
    debouncedGetOrdersList(query);
  };

  const handleViewOrderDetails = order => {
    setSelectedOrder(order);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrder(null);
  };

  const sequenceOrderNumber = index => {
    return index + 1 + (ordersList?.page - 1) * ordersList?.per_page;
  };

  const tableHeaders = [
    { name: 'No #' },
    { name: 'Order Date' },
    { name: 'Order No' },
    { name: 'Patient Name' },
    { name: 'Beneficiary Type' },
    { name: 'Nationality' },
    { name: 'Payment Method' },
    { name: 'Number Of Sessions' },
    { name: 'Remaining Sessions' },
    { name: 'Actions' }
  ];

  return (
    <div className='superGit_container !p-0'>
      <div className='flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3'>
        <div className=''>
          <Tooltip placement='right' title='Keep track of your beneficiaries' className='my-auto !mt-2'>
            <span className='font-semibold text-black/70 text-lg '>
              ORDERS
              <span className='italic text-lg ml-1'>
                ({ordersList?.total_orders || 0}
                <span className='text-xs font-bold italic'>Entries</span>)
              </span>
            </span>
          </Tooltip>
        </div>
        <div className='flex gap-3 flex-col md:flex-row md:items-center'>
          <input
            type='text'
            name='search'
            id='search'
            value={searchTerm}
            onChange={handleSearchChange}
            className='block w-full rounded-md border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            placeholder={'Search orders'}
          />
          <Button
            variant='contained'
            onClick={() => navigate('/receptionist/create-order')}
            className='w-[250px] px-2'
            style={{
              backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
            }}
            startIcon={<AddIcon />}
            size='small'
          >
            Create Order
          </Button>
        </div>
      </div>
      <div className='super-git-table !p-0 !m-0'>
        <table className='table-super-git !p-0'>
          <thead className='!p-0'>
            <tr className='!p-0'>
              {tableHeaders.map((el, index) => {
                return (
                  <th className='!p-2 !text-sm !bg-[#373d45]' key={'tableHeaders_' + index}>
                    {el.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {ordersList?.data?.map((el, index) => {
              return (
                <tr className='!p-0' key={index}>
                  <td className='!p-1 !text-sm'>{sequenceOrderNumber(index)}</td>
                  <td className='!p-1 !text-sm'>{formatDateTime(el?.order_date)}</td>
                  <td className='!p-1 !text-sm'>{el?.order_no}</td>
                  <td className='!p-1 !text-sm'>{el?.patient?.full_name}</td>
                  <td className='!p-1 !text-sm'>{el?.patient?.beneficiary_type}</td>
                  <td className='!p-1 !text-sm'>{el?.patient?.nationality}</td>
                  <td className='!p-1 !text-sm'>{el?.payment_method}</td>
                  <td className='!p-1 !text-sm'>{el?.number_of_sessions}</td>
                  <td className='!p-1 !text-sm'>{el?.left_sessions}</td>
                  <td className='flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1'>
                    <Tooltip title='View'>
                      <div className='text-xl cursor-pointer text-green-500' onClick={() => handleViewOrderDetails(el)}>
                        <MdOutlineRemoveRedEye />
                      </div>
                    </Tooltip>
                    <Chip
                      label={'Create Special Visit'}
                      color={'success'}
                      size='small'
                      disabled={el?.left_sessions <= 0}
                      onClick={() =>
                        navigate('/receptionist/create-home-visit', {
                          state: {
                            orderNo: el?.order_no,
                            selectedPatient: el?.patient,
                            orderItems: el?.items,
                            selectedOrder: el
                          }
                        })
                      }
                      className='!mr-[2px] rounded !cursor-pointer'
                      style={{ fontSize: '0.7rem', padding: '2px 4px' }}
                      variant='contained'
                      sx={{
                        backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                        borderColor: '#f14f3e',
                        color: 'white',
                        '&:hover': {
                          borderColor: '#f14f3e'
                        },
                        '&:focus': {
                          borderColor: '#f14f3e'
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <CustomPagination
        currentPage={ordersList?.page || 1}
        rowsPerPage={ordersList?.per_page || 5}
        totalRecords={ordersList?.total_orders || 0}
        onPageChange={onPageChange}
      />
      <OrderDetailsModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        setSelectedOrder={setSelectedOrder}
        orderData={selectedOrder}
      />
      <AppLoader showLoader={loading} />
    </div>
  );
};

export default OrdersList;
