import { Chip } from '@mui/material';
import React, { useState } from 'react';

import ViewHomeCareFormDetailsModal from './ViewHomeCareFormDetailsModal';
import { formatDateTime } from '../../../util/FomateDateTime';

const HomeCareFormResultsTable = ({ tableData, patient }) => {
  const [openFormDetailsModal, setOpenFormDetailsModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const tableHeaders = [
    { name: 'No #' },
    { name: 'Created AT' },
    { name: 'Patient Name' },
    { name: 'Beneficiary Type' },
    { name: 'Nationality' },
    { name: 'Actions' }
  ];

  return (
    <div>
      <div className='super-git-table !p-0 !m-0'>
        <table className='table-super-git !p-0'>
          <thead className='!p-0'>
            <tr className='!p-0'>
              {tableHeaders.map((el, index) => {
                return (
                  <th className='!p-2 !text-sm !bg-[#373d45]' key={'tableHeaders_' + index}>
                    {el.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((el, index) => {
              return (
                <tr className='!p-0' key={index}>
                  <td className='!p-1 !text-sm'>{index + 1}</td>
                  <td className='!p-1 !text-sm'>{formatDateTime(el?.created_at)}</td>
                  <td className='!p-1 !text-sm'>{patient?.full_name}</td>
                  <td className='!p-1 !text-sm'>{patient?.beneficiary_type}</td>
                  <td className='!p-1 !text-sm'>{patient?.nationality}</td>
                  <td className='flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1'>
                    <Chip
                      label={'View Results'}
                      color={'success'}
                      onClick={() => {
                        setOpenFormDetailsModal(true);
                        setSelectedItem(el);
                      }}
                      size='small'
                      className='!mr-[2px] rounded !cursor-pointer'
                      style={{ fontSize: '0.7rem', padding: '2px 4px' }}
                      variant='contained'
                      sx={{
                        backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                        borderColor: '#f14f3e',
                        color: 'white',
                        '&:hover': {
                          borderColor: '#f14f3e'
                        },
                        '&:focus': {
                          borderColor: '#f14f3e'
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ViewHomeCareFormDetailsModal
        openFormDetailsModal={openFormDetailsModal}
        setOpenFormDetailsModal={setOpenFormDetailsModal}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    </div>
  );
};

export default HomeCareFormResultsTable;
