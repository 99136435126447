import React from 'react'
import { useState } from 'react'
import moment from 'moment'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import toast from 'react-hot-toast'

import { viewVitalReports } from '../../pages/HIS/components/ApiMethods'
import ViewVitalReportsModal from './ViewVitalReportsModal'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function OrderDetailsModal ({
  open,
  setOpen,
  handleClose,
  setSelectedOrder,
  orderData
}) {
  console.log(orderData?.order_date)

  const [loading, setLoading] = useState(false)
  const [vitalReportData, setVitalReportsData] = useState(null)
  const [openVitalReportsModal, setOpenVitalReportsModal] = useState(false)
  const [isVitalsView, setIsVitalSignsView] = useState(false)
  const [reportsDate, setReportDate] = useState(() => {
    const today = moment()
    const oneMonthBefore = today.clone().subtract(1, 'months')
    return {
      start_date: oneMonthBefore.format('YYYY-MM-DD'),
      end_date: today.format('YYYY-MM-DD')
    }
  })

  const handleViewReports = async isViewVitalSign => {
    if (isViewVitalSign) {
      setIsVitalSignsView(true)
    } else {
      setIsVitalSignsView(false)
    }

    let orderDate = orderData?.order_date?.split('T')[0]
    let payload = {
      patient_id: orderData?.patient?.his_ref,
      from_date: orderDate
    }
    let reportsData = await viewVitalReports(setLoading, payload)

    if (reportsData?.data) {
      setVitalReportsData(reportsData)
      setOpenVitalReportsModal(true)
    } else {
      toast.error('No reports data found.')
    }
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          handleClose()
          setSelectedOrder(null)
        }}
        TransitionComponent={Transition}
        style={{ background: '#ffab2c' }}
      >
        <AppBar sx={{ position: 'relative' }} className='!z-10'>
          <Toolbar className='' style={{ background: '#ffab2c' }}>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => {
                handleClose()
                setSelectedOrder(null)
              }}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              Order Detail
            </Typography>
            <Button
              autoFocus
              color='inherit'
              size='small'
              onClick={() => {
                handleClose()
                setSelectedOrder(null)
              }}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <div className='p-3'>
          <div className='mt-2'>
            <span className='text-2xl font-semibold'>Order Detail</span>
          </div>
          {orderData && (
            <div className='my-2'>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2'>
                {/* Patient Information Card */}
                <div className='card p-2 shadow-md'>
                  <div className='flex justify-between'>
                    <span className='font-semibold'>Order Number</span>
                    <span className='capitalize'>
                      {orderData?.order_no || '____'}
                    </span>
                  </div>

                  <div className='flex justify-between mt-2'>
                    <span className='font-semibold'>Order Date</span>
                    <span className='capitalize'>
                      {orderData?.order_date || '____'}
                    </span>
                  </div>

                  <div className='flex justify-between mt-2'>
                    <span className='font-semibold'>Order Status</span>
                    <span className='capitalize'>
                      {orderData?.order_status || '____'}
                    </span>
                  </div>

                  <div className='flex justify-between mt-2'>
                    <span className='font-semibold'>Payment Method</span>
                    <span className='capitalize'>
                      {orderData?.payment_method || '____'}
                    </span>
                  </div>
                </div>

                {/* Document Information Card */}
                <div className='card p-2 shadow-md'>
                  <div className='flex justify-between'>
                    <span className='font-semibold'>Specialty</span>
                    <span className='capitalize'>
                      {orderData?.specialty || '____'}
                    </span>
                  </div>

                  <div className='flex justify-between mt-2'>
                    <span className='font-semibold'>
                      Total Number Of Sessions
                    </span>
                    <span className='capitalize'>
                      {orderData?.number_of_sessions || '____'}
                    </span>
                  </div>

                  <div className='flex justify-between mt-2'>
                    <span className='font-semibold'>Taken Sessions</span>
                    <span className='capitalize'>
                      {orderData?.taken_sessions || '____'}
                    </span>
                  </div>

                  <div className='flex justify-between mt-2'>
                    <span className='font-semibold'>Remain Sessions</span>
                    <span>{orderData?.left_sessions || '____'}</span>
                  </div>
                </div>

                <div className='card p-2 shadow-md'>
                  <div className='flex justify-between'>
                    <span className='font-semibold'>Patient Name</span>
                    <span className='capitalize'>
                      {orderData?.patient?.full_name || '____'}
                    </span>
                  </div>

                  <div className='flex justify-between mt-2'>
                    <span className='font-semibold'>Nationality</span>
                    <span>{orderData?.patient?.nationality || '____'}</span>
                  </div>

                  <div className='flex justify-between mt-2'>
                    <span className='font-semibold'>Beneficiary Type</span>
                    <span className='capitalize'>
                      {orderData?.patient?.beneficiary_type || '____'}
                    </span>
                  </div>

                  <div className='flex justify-between mt-2'>
                    <span className='font-semibold'>Address</span>
                    <span className='capitalize'>
                      {orderData?.patient?.address || '____'}
                    </span>
                  </div>
                </div>
              </div>
              {orderData?.patient?.insurance_plans?.length ? (
                <div className='mt-6'>
                  <span className='text-2xl font-semibold'>
                    Insurance Plans
                  </span>
                  {orderData?.patient?.insurance_plans?.map((el, index) => (
                    <div key={index} className='card shadow-md p-2 m-2'>
                      <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3'>
                        <div>
                          <div className='flex justify-between'>
                            <span className='font-semibold'>Payer Name</span>
                            <span className='capitalize'>
                              {el?.payer_name.length > 35
                                ? el?.payer_name.substring(0, 35) + '...'
                                : el?.payer_name || '____'}
                            </span>
                          </div>

                          <div className='flex justify-between mt-2'>
                            <span className='font-semibold'>Is Primary</span>
                            <span className='capitalize'>
                              {el.is_primary ? 'Yes' : 'No'}
                            </span>
                          </div>

                          <div className='flex justify-between mt-2'>
                            <span className='font-semibold'>Issue Date</span>
                            <span className='capitalize'>
                              {el?.issue_date || '____'}
                            </span>
                          </div>

                          <div className='flex justify-between mt-2'>
                            <span className='font-semibold'>Expiry Date</span>
                            <span className='capitalize'>
                              {el?.expiry_date || '____'}
                            </span>
                          </div>

                          <div className='flex justify-between mt-2'>
                            <span className='font-semibold'>
                              Relation With Subscriber
                            </span>
                            <span className='capitalize'>
                              {el?.relation_with_subscriber || '____'}
                            </span>
                          </div>

                          <div className='flex justify-between mt-2'>
                            <span className='font-semibold'>
                              Member Card ID
                            </span>
                            <span className='capitalize'>
                              {el?.member_card_id || '____'}
                            </span>
                          </div>
                        </div>

                        <div>
                          <div className='flex justify-between'>
                            <span className='font-semibold'>Policy Number</span>
                            <span className='capitalize'>
                              {el?.policy_number || '____'}
                            </span>
                          </div>

                          <div className='flex justify-between mt-2'>
                            <span className='font-semibold'>Patient Share</span>
                            <span className='capitalize'>
                              {el?.patient_share || '____'}
                            </span>
                          </div>

                          <div className='flex justify-between mt-2'>
                            <span className='font-semibold'>Max Limit</span>
                            <span className='capitalize'>
                              {el?.max_limit || '____'}
                            </span>
                          </div>

                          <div className='flex justify-between mt-2'>
                            <span className='font-semibold'>Network ID</span>
                            <span className='capitalize'>
                              {el?.network_id || '____'}
                            </span>
                          </div>

                          <div className='flex justify-between mt-2'>
                            <span className='font-semibold'>Coverage Type</span>
                            <span className='capitalize'>
                              {el?.coverage_type || '____'}
                            </span>
                          </div>

                          <div className='flex justify-between mt-2'>
                            <span className='font-semibold'>
                              Sponsor Number
                            </span>
                            <span className='capitalize'>
                              {el?.sponsor_no || '____'}
                            </span>
                          </div>
                        </div>

                        <div>
                          <div className='flex justify-between'>
                            <span className='font-semibold'>
                              Policy Class Name
                            </span>
                            <span className='capitalize'>
                              {el?.policy_class || '____'}
                            </span>
                          </div>

                          <div className='flex justify-between mt-2'>
                            <span className='font-semibold'>Policy Holder</span>
                            <span className='capitalize'>
                              {el?.policy_holder || '____'}
                            </span>
                          </div>

                          <div className='flex justify-between mt-2'>
                            <span className='font-semibold'>
                              Insurance Status
                            </span>
                            <span className='capitalize'>
                              {el?.insurance_status || '____'}
                            </span>
                          </div>

                          <div className='flex justify-between mt-2'>
                            <span className='font-semibold'>
                              Insurance Type
                            </span>
                            <span className='capitalize'>
                              {el?.insurance_type || '____'}
                            </span>
                          </div>

                          <div className='flex justify-between mt-2'>
                            <span className='font-semibold'>
                              Insurance Duration
                            </span>
                            <span className='capitalize'>
                              {el?.insurance_duration || '____'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )}
              {orderData?.items?.length ? (
                <div className='mt-6'>
                  <span className='text-2xl font-semibold'>Items</span>
                  <div className='overflow-x-auto mt-2'>
                    <table className='table-auto border-collapse border border-gray-200 w-full text-left text-sm'>
                      <thead className='bg-gray-200'>
                        <tr>
                          <th className='!p-2 !text-sm border border-gray-300'>
                            No
                          </th>
                          <th className='!p-2 !text-sm border border-gray-300'>
                            Name
                          </th>
                          <th className='!p-2 !text-sm border border-gray-300'>
                            Unit Price
                          </th>
                          <th className='!p-2 !text-sm border border-gray-300'>
                            Quantity
                          </th>
                          <th className='!p-2 !text-sm border border-gray-300'>
                            Type
                          </th>
                          <th className='!p-2 !text-sm border border-gray-300'>
                            Standard Code
                          </th>
                          <th className='!p-2 !text-sm border border-gray-300'>
                            Non Standard Code
                          </th>
                          <th className='!p-2 !text-sm border border-gray-300'>
                            Standard Description
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderData?.items?.map((el, index) => (
                          <tr key={index} className='hover:bg-gray-50'>
                            <td className='border border-gray-300 p-1 text-sm'>
                              {index + 1}
                            </td>
                            <td className='border border-gray-300 p-1 text-sm'>
                              {el?.name?.length > 50
                                ? el?.name.substring(0, 50) + '...'
                                : el?.name || '____'}
                            </td>
                            <td className='border border-gray-300 p-1 text-sm'>
                              {el?.unit_price || '____'}
                            </td>
                            <td className='border border-gray-300 p-1 text-sm'>
                              {el?.quantity || '____'}
                            </td>
                            <td className='border border-gray-300 p-1 text-sm capitalize'>
                              {el?.type || '____'}
                            </td>
                            <td className='border border-gray-300 p-1 text-sm'>
                              {el.standard_code || '____'}
                            </td>
                            <td className='border border-gray-300 p-1 text-sm'>
                              {el.non_standard_code || '____'}
                            </td>{' '}
                            <td className='border border-gray-300 p-1 text-sm capitalize'>
                              {el?.standard_description || '____'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className='mt-4'>
                <span className='text-2xl font-semibold'>Reports</span>
                <div className='overflow-x-auto mt-2'>
                  <table className='table-auto border-collapse border border-gray-200 w-full text-left text-sm'>
                    <thead className='bg-gray-200'>
                      <tr>
                        <th className='!p-2 !text-sm border border-gray-300'>
                          No
                        </th>
                        <th className='!p-2 !text-sm border border-gray-300'>
                          Name
                        </th>
                        <th className='!p-2 !text-sm border border-gray-300'>
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='hover:bg-gray-50'>
                        <td className='border border-gray-300 p-1 text-sm'>
                          1
                        </td>
                        <td className='border border-gray-300 p-1 text-sm'>
                          View Vital Signs Report
                        </td>
                        <td className='border border-gray-300 p-1 text-sm capitalize'>
                          <Button
                            variant='contained'
                            size='small'
                            type='submit'
                            style={{
                              backgroundImage:
                                'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
                            }}
                            className='!capitalize !h-[25px]'
                            onClick={() => handleViewReports(true)}
                          >
                            View Data
                          </Button>
                        </td>
                      </tr>
                      <tr className='hover:bg-gray-50'>
                        <td className='border border-gray-300 p-1 text-sm'>
                          2
                        </td>
                        <td className='border border-gray-300 p-1 text-sm'>
                          View Home Healthcare Patient Progress Notes
                        </td>
                        <td className='border border-gray-300 p-1 text-sm capitalize'>
                          <Button
                            variant='contained'
                            size='small'
                            type='submit'
                            style={{
                              backgroundImage:
                                'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
                            }}
                            className='!capitalize !h-[25px]'
                            onClick={() => handleViewReports(false)}
                          >
                            View Data
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <ViewVitalReportsModal
                isVitalSigns={isVitalsView}
                visit_date={orderData?.order_date}
                patientData={orderData?.patient}
                openVitalReportsModal={openVitalReportsModal}
                setOpenVitalReportsModal={setOpenVitalReportsModal}
                reportsData={vitalReportData}
              />
            </div>
          )}
        </div>
      </Dialog>
    </div>
  )
}
