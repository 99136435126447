import React from "react";

const MedicalInvoice = () => {
  return (
    <div className="max-w-[400px] mx-auto bg-white p-4 font-sans text-[11px] leading-tight">
      {/* Header - Saudi Safwa Medical */}
      <div className="text-center space-y-0.5">
        <div className="flex justify-center items-center gap-1">
          <div className="text-base font-bold">مجمع صفوة السعودية الطبي</div>
        </div>
        <div className="text-base font-bold">SAUDI SAFWA MEDICAL</div>
        <div>
          <span className="text-xs font-semibold">الرقم الضريبي</span> /
          302087323500003
        </div>
        <div className="border-b border-black pb-1"></div>
      </div>

      {/* Tax Invoice Header */}
      <div className="text-center mt-2 space-y-0.5">
        <div className="flex flex-col justify-center gap-2">
          <span className="text-sm font-bold">فاتورة ضريبية مبسطة</span>
          <span className="text-sm font-bold">Simplified Tax Invoice</span>
          <div className="border-b border-black pb-1"></div>
        </div>
        <div className="flex justify-center items-center flex-col mt-3">
          <div className="w-3/4 flex justify-center gap-1 border-2 p-2 border-black text-sm font-bold">
            <span>CREDIT INVOICE</span>
            <span>/</span>
            <span>فاتورة الائتمان</span>
          </div>

          <div className="w-3/4 mt-2 space-y-1">
            <div className="grid grid-cols-3">
              <span className="text-xs font-semibold text-left">FileNo: </span>
              <span className="text-xs text-left">A/021937</span>
              <span className="text-xs font-semibold text-right">
                رقم الملف
              </span>
            </div>
            <div className="grid grid-cols-3">
              <span className="text-xs font-semibold text-left">Inv. # </span>
              <span className="text-xs text-left">077759</span>
              <span className="text-xs font-semibold text-right">
                رقم الفاتورة
              </span>
            </div>
            <div className="grid grid-cols-3">
              <span className="text-xs font-semibold text-left">Date:</span>
              <span className="text-xs text-left">2024/06/30 8:10:35pm</span>
              <span className="text-xs font-semibold text-right">تاريخ</span>
            </div>
          </div>
        </div>
        <div className="border-b border-black pb-1"></div>
      </div>

      {/* File and Invoice Details */}

      {/* Patient Information */}
      <div className="mt-3 space-y-1">
        <div className="grid grid-cols-3">
          <span className="text-xs font-semibold">Patient Name</span>
          <span className="text-xs">EHAB RAMADAN MOHAMMED</span>
          <span className="text-xs font-semibold text-right">اسم المريض</span>
        </div>
        <div className="grid grid-cols-3">
          <span className="text-xs font-semibold">Patient Id No:</span>
          <span className="text-xs ">2423046842</span>
          <span className="text-xs font-semibold text-right">
            رقم هوية المريض
          </span>
        </div>
        <div className="grid grid-cols-3">
          <span className="text-xs font-semibold">Company</span>
          <span className="text-xs ">ALMOUTATWIRA MODERN SAUDI</span>
          <span className="text-xs font-semibold text-right">شركة</span>
        </div>
        <div className="grid grid-cols-3">
          <span className="text-xs font-semibold">Company vat No:</span>
          <span className="text-xs ">0</span>
          <span className="text-xs font-semibold text-right">
            رقم ضريبة القيمة المضافة للشركة
          </span>
        </div>
        <div className="grid grid-cols-3">
          <span className="text-xs font-semibold">Insurance vat No:</span>
          <span className="text-xs ">0</span>
          <span className="text-xs font-semibold text-right">
            رقم ضريبة القيمة المضافة للتأمين
          </span>
        </div>
        <div className="grid grid-cols-3">
          <span className="text-xs font-semibold">Nationality</span>
          <span className="text-xs ">EG-EGYPT</span>
          <span className="text-xs font-semibold text-right">جنسية</span>
        </div>
        <div className="grid grid-cols-3">
          <span className="text-xs font-semibold">Age/Gender</span>
          <span className="text-xs ">46 Years / Male</span>
          <span className="text-xs font-semibold text-right">
            العمر / الجنس
          </span>
        </div>
        <div className="grid grid-cols-3">
          <span className="text-xs font-semibold">Doctor:</span>
          <span className="text-xs ">IQAMA BALADIYA D/L MEDICAL</span>
          <span className="text-xs font-semibold text-right">الطبيب</span>
        </div>
      </div>

      {/* Service Table */}
      <div className="mt-4">
        <table className="w-full text-[12px]">
          <thead>
            <tr className="border-y border-black">
              <th className="py-1 text-left ">
                <div>الرمز / الوصف</div>
                <div className="mt-1">CODE/DESCRIPTION</div>
              </th>
              <th className="py-1 text-center ">
                <div>الكمية</div>
                <div className="mt-1">QTY</div>
              </th>
              <th className="py-1 text-right ">
                <div>إجمالي</div>
                <div className="mt-1">GROSS</div>
              </th>
              <th className="py-1 text-right ">
                <div>الخصم</div>
                <div className="mt-1">DISC</div>
              </th>
              <th className="py-1 text-right ">
                <div>الضريبة</div>
                <div className="mt-1">VAT15%</div>
              </th>
              <th className="py-1 text-right ">
                <div>الصافي</div>
                <div className="mt-1">NET</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-left ">
                <div>NAS003 BALADIYA MEDICAL WITH INJECTION</div>
              </td>
              <td className="text-center">1</td>
              <td className="text-right">261.00</td>
              <td className="text-right">0.00</td>
              <td className="text-right">39.15</td>
              <td className="text-right">261.00</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Totals */}
      <div className="mt-2 space-y-1">
        <div className="flex justify-end gap-3">
          <span className="text-sm font-semibold">
            Gross Total / المجموع قبل الضريبة
          </span>
          <span className="text-sm font-semibold">261.00</span>
        </div>
        <div className="flex justify-end gap-3">
          <span className="text-sm font-semibold">
            VAT TOTAL / قيمة الضريبة
          </span>
          <span className="text-sm font-semibold">39.15</span>
        </div>
        <div className="border-b border-black pb-1"></div>
        <div className="flex justify-end gap-3">
          <span className="text-sm font-semibold">
            Net Amount / صافي القيمة
          </span>
          <span className="text-sm font-semibold">300.15</span>
        </div>
        <div className="border-b border-black pb-1"></div>
      </div>

      {/* Additional Details */}
      <div className="mt-3 space-y-1">
        <div className="flex">
          <span className="w-32 font-bold text-lg">TOKEN NO:-</span>
          <span className="font-bold text-lg">0</span>
        </div>
        <div className="flex">
          <span className="w-32  font-bold text-lg">ROOM NO:-</span>
          <span className="font-bold text-lg">0</span>
        </div>
        <div className="flex">
          <span className="w-32  font-bold text-lg">FLOOR NO:-</span>
          <span className="font-bold text-lg">0</span>
        </div>
      </div>

      {/* Contact Information */}
      <div className="mt-4 text-center space-y-1">
        <div className="flex flex-col items-center">
          <div className="text-sm font-semibold">للشكوى والاقتراحات</div>
          <div className="text-sm font-semibold">
            Your Comments & Suggestions:
          </div>
          {/* <div className="flex items-center gap-2">
            <span>: التواصل على الرقم</span>
            <div className="flex items-center gap-1">
              <div className="w-6 h-6 bg-gray-200 flex items-center justify-center rounded-sm">
                <span className="text-xs">📞</span>
              </div>
              <span>0507226008</span>
            </div>
          </div> */}
        </div>
        <div className="text-sm font-semibold mt-3">PHONE: 0507226008</div>
      </div>

      {/* Footer */}
      <div className="mt-4 text-center space-y-2">
        <div className="text-base text-left font-semibold">
          SAUDI SAFWA MEDICAL
        </div>
        <div className="border-b border-black pb-1"></div>

        <div className="h-24 w-24 mx-auto bg-gray-200"></div>
        <div className="text-sm font-semibold">Thank You for your visit</div>
        <div className="text-sm font-semibold">
          مع تمنياتنا لك بالصحة والعافية
        </div>
      </div>
    </div>
  );
};

export default MedicalInvoice;
