import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
  Divider,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from 'react-hot-toast';
import apiClient from './labAPiConfig';

// Validation schema using Yup
const CategorySchema = Yup.object().shape({
  form_id: Yup.number().required('Form ID is required'),
  type: Yup.string().required('Type is required'),
  name_en: Yup.string().required('Category Name (English) is required'),
  name_ar: Yup.string().required('Category Name (Arabic) is required')
});

export default function CategoryList({
  selectedReportId,
  selectedFormType,
  setSelectedCategoryId,
  setSelectedFormId,
  isAllowActions
}) {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  const fetchCategories = useCallback(async () => {
    try {
      const response = await apiClient.get(`category/all/${selectedReportId}?request_type=${selectedFormType}`);
      if (response.data.status === 200) {
        setCategories(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      toast.error('Failed to fetch categories');
    }
  }, [selectedReportId, selectedFormType]);

  // Fetch data from the API
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories, selectedReportId, selectedFormType]);

  const handleAddCategory = () => {
    setSelectedCategory(null); // Reset selected category
    setOpenCategoryDialog(true);
  };

  const handleEditCategory = category => {
    setSelectedCategory(category); // Set the category to be edited
    setOpenCategoryDialog(true);
  };

  const handleDeleteCategory = category => {
    setCategoryToDelete(category);
    setConfirmDeleteDialog(true);
  };

  const confirmDelete = async () => {
    try {
      await apiClient.delete(`category/${categoryToDelete.id}?request_type=${selectedFormType}`);
      setConfirmDeleteDialog(false);
      fetchCategories();
      toast.success('Category deleted successfully');
    } catch (error) {
      console.error('Error deleting category:', error);
      toast.error('Failed to delete category');
    }
  };

  const handleSaveCategory = async (values, { resetForm }) => {
    try {
      if (selectedCategory) {
        // Update category
        await apiClient.put(`category/${selectedCategory.id}`, values);
        fetchCategories();
        toast.success('Category updated successfully');
      } else {
        // Add new category
        const response = await apiClient.post(`category`, values);
        if (response.data.status === 200) {
          fetchCategories();
          toast.success('Category added successfully');
        }
      }
      setOpenCategoryDialog(false);
      resetForm();
    } catch (error) {
      console.error('Error saving category:', error);
      toast.error('Failed to save category');
    }
  };

  const handleCancelAdd = () => {
    setOpenCategoryDialog(false);
  };

  return (
    <Container maxWidth='xs'>
      <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#fff' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant='h6'>Categories</Typography>
          {isAllowActions && (
            <IconButton onClick={handleAddCategory} sx={{ color: '#ff5722' }}>
              <AddCircleOutlineIcon fontSize='large' />
            </IconButton>
          )}
        </Box>
        <Divider sx={{ backgroundColor: '#ff5722' }} />
        <List>
          {categories.map(category => (
            <ListItem
              key={category.id}
              onClick={() => {
                setSelectedCategoryId(category.id);
                if (setSelectedFormId) {
                  setSelectedFormId(category?.form_id);
                }
              }}
              className='cursor-pointer hover:bg-gray-100'
            >
              <ListItemText primary={category?.name_en} secondary={category?.type} />
              {isAllowActions && (
                <React.Fragment>
                  <IconButton onClick={() => handleEditCategory(category)} sx={{ color: '#000' }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteCategory(category)} sx={{ color: '#000' }}>
                    <DeleteIcon />
                  </IconButton>
                </React.Fragment>
              )}
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* Dialog for adding/updating category */}
      <Dialog open={openCategoryDialog} onClose={handleCancelAdd}>
        <DialogTitle>{selectedCategory ? 'Edit Category' : 'Add New Category'}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              form_id: selectedReportId || '',
              type: selectedCategory?.type || '',
              name_en: selectedCategory?.name_en || '',
              name_ar: selectedCategory?.name_ar || '',
              request_type: selectedFormType || 'visit'
            }}
            validationSchema={CategorySchema}
            onSubmit={handleSaveCategory}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <FormControl fullWidth margin='dense' variant='outlined'>
                  <InputLabel>Type</InputLabel>
                  <Select
                    name='type'
                    label='Type'
                    value={values.type}
                    onChange={handleChange}
                    error={touched.type && Boolean(errors.type)}
                  >
                    <MenuItem value='primary'>Primary</MenuItem>
                    <MenuItem value='secondary'>Secondary</MenuItem>
                  </Select>
                  {touched.type && errors.type && (
                    <Typography color='error' variant='body2'>
                      {errors.type}
                    </Typography>
                  )}
                </FormControl>
                <Field
                  as={TextField}
                  name='name_en'
                  label='Category Name (English)'
                  fullWidth
                  margin='dense'
                  variant='outlined'
                  onChange={handleChange}
                  error={touched.name_en && Boolean(errors.name_en)}
                  helperText={touched.name_en && errors.name_en}
                />
                <Field
                  as={TextField}
                  name='name_ar'
                  label='Category Name (Arabic)'
                  fullWidth
                  margin='dense'
                  variant='outlined'
                  onChange={handleChange}
                  error={touched.name_ar && Boolean(errors.name_ar)}
                  helperText={touched.name_ar && errors.name_ar}
                />
                <DialogActions>
                  <Button onClick={handleCancelAdd}>Cancel</Button>
                  <Button type='submit'>Save</Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* Confirmation Dialog for Delete */}
      <Dialog open={confirmDeleteDialog} onClose={() => setConfirmDeleteDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this category?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteDialog(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color='error'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
