import React from 'react';
import { Outlet } from 'react-router-dom';













function Emergency(){
    return (
        <Outlet />
    )
}



export default Emergency;