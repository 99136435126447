import React, { useContext } from "react";
import { Navigate, Routes, Route } from "react-router-dom";

import Login from "../pages/auth/Login";
import LandingPage from "../components/landing/LandingPage";
import { appContext } from "../context/ContextProvider";
import Dashboard from "../pages/Dashboard/Dashboard";
import DDashboard from "../pages/Dashboard/DDashboard";
import LaboratoryForm from "../pages/serviceItem/components/LaboratoryForm";
import ServiceItemMain from "../pages/serviceItem/ServiceItemMain";

function AuthRouter() {
  const { token, user } = useContext(appContext);

  const getDashboardPath = () => {
    if (user?.RoleName === "reception") {
      return "/receptionist/rdashboard";
    } else if (user?.RoleName === "doctor") {
      return "/his/ddashboard";
    }
    return "/dashboard"; // Default to admin dashboard if no role matches
  };

  return (
    <Routes>
      {/* Landing page is always accessible */}
      <Route path="/" element={<LandingPage />} />

      {/* Login page route */}
      <Route path="/login" element={<Login />} />
      {/* <Route path="lab-form-1" element={<ServiceItemMain />} /> */}

      {/* Protected routes based on user's role */}
      {token ? (
        <>
          <Route
            path="/dashboard"
            element={<Navigate to={getDashboardPath()} />}
          />
          <Route path="/receptionist/rdashboard" element={<Dashboard />} />
          <Route path="/his/ddashboard" element={<DDashboard />} />
        </>
      ) : (
        <Route path="*" element={<Navigate to="/login" />} />
      )}
    </Routes>
  );
}

export default AuthRouter;
