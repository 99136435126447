import React, { useState } from 'react';
import { Formik, FieldArray, Field, useFormikContext } from 'formik';
import { PlusIcon as PlusIcon } from '@heroicons/react/24/solid';
import { TrashIcon as TrashIconMini, PlusCircleIcon } from '@heroicons/react/20/solid';
import { useQuery, gql, useMutation } from '@apollo/client';
// import { ToastContainer, toast } from "react-toastify";
import _ from 'lodash';
import DiagnosisSearchNSelect from '../../PreAuth/components/request/DiagnosisSearchNSelect';
import supportingInfoElements from '../../PreAuth/components/request/SupportingInfoElement';
import { items as sites, dentalItems as oralItems } from '../../PreAuth/components/request/BodySites';
import ItemCodesSearchNSelect from '../../PreAuth/components/request/ItemCodesSearchNSelect';
import { MdAttachFile, MdDownload } from 'react-icons/md';
import DentalTeethSelectModal from '../../PreAuth/components/DentalTeethSelectModal';
import { Button, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import toast from 'react-hot-toast';

const menuItems = [
  { text: 'Inbox', icon: <InboxIcon /> },
  { text: 'Starred', icon: <MailIcon /> },
  { text: 'Send email', icon: <InboxIcon /> },
  { text: 'Drafts', icon: <MailIcon /> },
  { text: 'All mail', icon: <InboxIcon /> },
  { text: 'Trash', icon: <MailIcon /> },
  { text: 'Spam', icon: <InboxIcon /> }
];

function SupportingInfoForm({ requireSupportingInfoElements }) {
  const compulsoryCategories = [
    'chief-complaint',
    'vital-sign-systolic',
    'vital-sign-diastolic',
    'vital-sign-height',
    'vital-sign-weight',
    'temperature',
    'respiratory-rate',
    'pulse'
  ];
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue } =
    useFormikContext();
  const handleRemove = (remove, index, supportingInfos) => {
    const currentCategory = supportingInfos[index].category;

    if (compulsoryCategories.includes(currentCategory)) {
      const categoryCount = supportingInfos.filter(info => info.category === currentCategory).length;

      if (categoryCount === 1) {
        toast.error(`${currentCategory} is compulsory.`);
        return;
      }
    }

    remove(index);
  };
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = newOpen => () => {
    setOpen(newOpen);
  };

  const DrawerList = push => (
    <Box sx={{ width: 250 }} role='presentation' onClick={toggleDrawer(false)}>
      <List>
        {(requireSupportingInfoElements && requireSupportingInfoElements.length > 0
          ? requireSupportingInfoElements
          : supportingInfoElements
        ).map((item, index) => (
          <ListItem key={item.id} disablePadding className='!p-0'>
            <ListItemButton
              onClick={() => {
                push(item.object);
                setSupportingInfo(index);
              }}
              className='!p-0'
            >
              <ListItemIcon className='!ml-2'>{item.icon}</ListItemIcon>
              <ListItemText primary={item?.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const [showTeethSelectModal, setShowTeethSelectModal] = useState(false);
  const [supportingInfo, setSupportingInfo] = useState('');

  const addData = values => {
    let data = _.cloneDeep(values);
    // createInsurancePlan({ variables: { input: data } });
  };

  const transformedData = () => {
    let data = [];

    (requireSupportingInfoElements && requireSupportingInfoElements.length > 0
      ? requireSupportingInfoElements
      : supportingInfoElements
    ).map(obj => {
      // iterate over each object and transform its category property
      data.push({
        ...obj, // spread operator to copy all other properties
        object: {
          ...obj.object, // copy all other properties of nested object
          category: obj.object.category.toLowerCase().replace(/\s+/g, '-')
        }
      });
    });

    return data;
  };

  function downloadBase64File(base64Data, fileName, fileType) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }

  const tableHeaders = [
    {
      name: 'No.'
    },
    {
      name: 'Category'
    },
    {
      name: 'Code'
    },
    {
      name: 'Timing'
    },
    {
      name: 'Value'
    },
    {
      name: 'Attachment'
    },
    {
      name: 'Reason'
    },
    {
      name: ''
    }
  ];

  const handleImageChange = (e, setFieldValue, index) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setFieldValue(`supportingInfos.${index}.image_title`, file.name);
      setFieldValue(`supportingInfos.${index}.image_content_type`, file.type);

      // Use the extracted base64Data for further processing or upload
      const base64Data = reader.result.split(',')[1]; // Remove data type prefix

      setFieldValue(`supportingInfos.${index}.image_value`, base64Data);
    };
  };

  const missingToothReasons = [
    { id: 'extraction', name: 'Extraction' },
    { id: 'congenital', name: 'Congenital' },
    { id: 'unknown', name: 'Unknown' },
    { id: 'other', name: 'Other' }
  ];

  const investigationResult = [
    {
      id: 'IRA',
      name: 'Investigation results attached'
    },
    {
      id: 'INP',
      name: 'Investigation(s)not performed'
    },
    {
      id: 'IRP',
      name: 'Investigation results pending'
    },
    { id: 'NA', name: 'Not applicable or Other' }
  ];

  const getRequiredInvertigationName = desiredId => {
    const result = _.find(investigationResult, { id: desiredId });
    return result.name;
  };

  const getRequiredItemName = desiredId => {
    const result = _.find(oralItems, { id: desiredId });
    return result.name;
  };

  return (
    <form className='space-y-6' action='#'>
      <div className='personal-data !p-3 !mb-3 !bg-gray-100'>
        <div className='md:grid md:grid-cols-3 md:gap-6'>
          <div className='md:col-span-3 md:mt-0'>
            <FieldArray name='supportingInfos'>
              {({ push, remove }) => {
                return (
                  <>
                    <div className='mb-2 flex justify-end'>
                      <div className='w-full'>
                        <List className='flex items-start flex-wrap gap-3'>
                          {(requireSupportingInfoElements && requireSupportingInfoElements.length > 0
                            ? requireSupportingInfoElements
                            : supportingInfoElements
                          ).map((item, index) => (
                            <ListItem
                              key={item.id}
                              disablePadding
                              className='!w-fit border !border-gray-600 !p-0 !rounded-lg flex flex-row items-center'
                            >
                              <Tooltip title={item.name} arrow>
                                <ListItemButton
                                  onClick={() => {
                                    push(item.object);
                                    setSupportingInfo(index);
                                  }}
                                  className='!px-2 py-1 w-fit flex flex-row items-center'
                                >
                                  <span className='text-sm font-medium mb-1'>{item.label}</span>{' '}
                                  <ListItemIcon className='!m-auto !min-w-0 !rounded-md'>{item.icon}</ListItemIcon>
                                </ListItemButton>
                              </Tooltip>
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    </div>

                    {/* supporting-table-head table-not-scorll table-width-sets  */}
                    <div className='super-git-table'>
                      {/* table table-hover !mb-0  */}
                      <table className='table-super-git chief-compaint-table-new'>
                        {/* bg-amber-600 text-white !h-9 */}
                        <thead className=''>
                          <tr className=''>
                            {tableHeaders?.map((el, index) => {
                              return (
                                <th
                                  className='!py-2.5 !pr-0 !pl-8 !bg-[#ffab2c]'
                                  // className="py-2 first:!rounded-tl-lg last:!rounded-tl-lg"
                                  key={'tableHeaders_' + index}
                                >
                                  {el.name}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {values.supportingInfos.map((item, index) => {
                            return (
                              <tr key={'addressform'} className='bg-transparent shadow-none border-b !border-gray-300'>
                                <td>{index + 1}</td>
                                <td>{values.supportingInfos[index]?.category}</td>
                                <td>
                                  {'code' in item &&
                                    (item.category != 'missingtooth' && item.category != 'investigation-result' ? (
                                      <>
                                        <Tooltip
                                          title={
                                            values.supportingInfos[index]?.code_text
                                              ? values.supportingInfos[index].code_text +
                                                ' - ' +
                                                values.supportingInfos[index]?.code
                                              : ''
                                          }
                                        >
                                          <span className=''>
                                            {values.supportingInfos[index]?.code
                                              ? values.supportingInfos[index]?.code
                                              : ''}

                                            {values.supportingInfos[index]?.code_text
                                              ? '( ' +
                                                values.supportingInfos[index].code_text.slice(0, 20) +
                                                '... ' +
                                                ' )'
                                              : ''}
                                          </span>
                                        </Tooltip>
                                        {item.category != 'lab-test' ? (
                                          <DiagnosisSearchNSelect
                                            values={values?.supportingInfos[index]}
                                            setFieldValue={val => {
                                              setFieldValue(`supportingInfos.${index}.code`, val.CodeId);
                                              setFieldValue(`supportingInfos.${index}.codeText`, val.AsciiDescription);
                                            }}
                                          />
                                        ) : (
                                          <ItemCodesSearchNSelect
                                            typeName={'loinc'}
                                            setFieldValue={val => {
                                              setFieldValue(`supportingInfos.${index}.code`, val.code);
                                              setFieldValue(`supportingInfos.${index}.codeText`, val.description);
                                            }}
                                          />
                                        )}
                                      </>
                                    ) : item.category == 'investigation-result' ? (
                                      <div className='flex'>
                                        <select
                                          id={`supportingInfos.${index}.code`}
                                          name={`supportingInfos.${index}.code`}
                                          onChange={e => {
                                            let val = e.target.value;
                                            setFieldValue(`supportingInfos.${index}.code`, val);
                                            setFieldValue(
                                              `supportingInfos.${index}.codeText`,
                                              getRequiredInvertigationName(val)
                                            );
                                          }}
                                          value={values?.supportingInfos[index]?.code}
                                          autoComplete='type-name'
                                          className='block w-full rounded-md rounded-r-none border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm'
                                        >
                                          <option value=''>
                                            <p>Please Select</p>
                                          </option>
                                          {investigationResult.map(item => {
                                            return (
                                              <option value={item.id}>
                                                <p>{item.name}</p>
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    ) : (
                                      <div className='flex'>
                                        <select
                                          id={`supportingInfos.${index}.code`}
                                          name={`supportingInfos.${index}.code`}
                                          onChange={e => {
                                            let val = e.target.value;
                                            setFieldValue(`supportingInfos.${index}.code`, val);
                                            setFieldValue(
                                              `supportingInfos.${index}.codeText`,
                                              getRequiredItemName(val)
                                            );
                                          }}
                                          value={values?.supportingInfos[index]?.code}
                                          autoComplete='type-name'
                                          className='block w-full rounded-md rounded-r-none border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm'
                                        >
                                          <option value=''>
                                            <p>Please Select</p>
                                          </option>
                                          {oralItems.map(item => {
                                            return (
                                              <option value={item.id}>
                                                <p>{item.name}</p>
                                              </option>
                                            );
                                          })}
                                        </select>

                                        <DentalTeethSelectModal
                                          setShowTeethSelectModal={setShowTeethSelectModal}
                                          showTeethSelectModal={showTeethSelectModal}
                                          setFieldValue={setFieldValue}
                                          index={index}
                                          getRequiredItemName={getRequiredItemName}
                                        />

                                        <Button
                                          autoFocus
                                          size='small'
                                          onClick={() => {
                                            setShowTeethSelectModal(true);
                                          }}
                                          variant='contained'
                                          style={{
                                            backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
                                          }}
                                          className='!rounded-l-none !rounded-r-md'
                                        >
                                          {'Select'}
                                        </Button>
                                      </div>
                                    ))}
                                </td>
                                <td>
                                  <div className='flex items-center gap-1'>
                                    {'startDate' in item && (
                                      <Field
                                        placeholder='Start Date'
                                        value={item?.startDate}
                                        type='date'
                                        className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm'
                                        name={`supportingInfos.${index}.startDate`}
                                        onChange={handleChange}
                                      />
                                    )}
                                    {'endDate' in item && (
                                      <Field
                                        placeholder='End Date'
                                        value={item?.endDate}
                                        type='date'
                                        className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm'
                                        name={`supportingInfos.${index}.endDate`}
                                        onChange={handleChange}
                                      />
                                    )}
                                  </div>
                                </td>
                                {/* <td>
                                  {"value" in item && (
                                    <div className="relative mt-2 rounded-md shadow-sm">
                                      <Field
                                        placeholder="Enter Value"
                                        type="text"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                                        name={`supportingInfos.${index}.value`}
                                      />
                                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                        <span
                                          className="text-gray-500 sm:text-sm"
                                          id="price-currency"
                                        >
                                          {item.valueUnit}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </td> */}
                                <td>
                                  {'value' in item && (
                                    <div className='relative mt-2 rounded-md shadow-sm'>
                                      {item.category === 'chief-complaint' ? (
                                        <textarea
                                          placeholder='Enter Value'
                                          className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm'
                                          name={`supportingInfos.${index}.value`}
                                          value={item?.value}
                                          onChange={handleChange}
                                          rows={1}
                                        />
                                      ) : (
                                        <Field
                                          placeholder='Enter Value'
                                          type='text'
                                          className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm'
                                          name={`supportingInfos.${index}.value`}
                                        />
                                      )}
                                      <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                                        <span className='text-gray-500 sm:text-sm' id='price-currency'>
                                          {item.valueUnit}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {'image_value' in item && (
                                    <>
                                      {!values.supportingInfos[index].image_title ? (
                                        <input
                                          placeholder='Attachment'
                                          type='file'
                                          className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm'
                                          onChange={e => {
                                            handleImageChange(e, setFieldValue, index);
                                          }}
                                          accept='image/*'
                                          name={`imagefile`}
                                        />
                                      ) : (
                                        <div className='flex gap-2'>
                                          {values?.supportingInfos[index]?.image_content_type === 'image/jpeg' ||
                                          values?.supportingInfos[index]?.image_content_type === 'image/png' ? (
                                            <div className='w-20 h-20 my-auto'>
                                              <img
                                                src={`data:${values?.supportingInfos[index]?.image_content_type};base64,${values?.supportingInfos[index]?.image_value}`}
                                                alt={`${values?.supportingInfos[index]?.image_title} Image`}
                                                className='rounded-md'
                                              />
                                            </div>
                                          ) : null}
                                          <label className='flex gap-2 my-auto'>
                                            {!(
                                              values?.supportingInfos[index]?.image_content_type === 'image/jpeg' ||
                                              values?.supportingInfos[index]?.image_content_type === 'image/png'
                                            ) ? (
                                              <span className='font-bold text-lg'>
                                                <MdAttachFile />
                                              </span>
                                            ) : null}

                                            {values.supportingInfos[index].image_title}

                                            <span
                                              className='font-bold text-lg my-auto ml-6'
                                              onClick={() =>
                                                downloadBase64File(
                                                  values?.supportingInfos[index]?.image_value,
                                                  values?.supportingInfos[index]?.image_title,
                                                  values?.supportingInfos[index]?.image_content_type
                                                )
                                              }
                                            >
                                              <MdDownload />
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {'reason' in item &&
                                    (item.category != 'missingtooth' ? (
                                      <>
                                        <Field
                                          placeholder='Enter Reason'
                                          type='text'
                                          className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm'
                                          name={`supportingInfos.${index}.reason`}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <select
                                          id={`supportingInfos.${index}.reason`}
                                          name={`supportingInfos.${index}.reason`}
                                          onChange={e => {
                                            let val = e.target.value;
                                            setFieldValue(`supportingInfos.${index}.reason`, val);
                                          }}
                                          value={values?.supportingInfos?.index?.reason}
                                          autoComplete='type-name'
                                          className='block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm'
                                        >
                                          <option value=''>
                                            <p>Please Select</p>
                                          </option>
                                          {missingToothReasons.map(item => {
                                            return (
                                              <option value={item.id}>
                                                <p>{item.name}</p>
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </>
                                    ))}
                                </td>
                                <td>
                                  <button
                                    onClick={() => handleRemove(remove, index, values.supportingInfos)}
                                    type='button'
                                    className='ml-3 inline-flex justify-center rounded-full border px-2 py-2 border-transparent bg-amber-600'
                                  >
                                    <TrashIconMini className='h-[16px] w-[16px] text-white' aria-hidden='true' />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                );
              }}
            </FieldArray>
          </div>
        </div>
        {errors.supportingInfos && touched.supportingInfos ? (
          <p className='max-w-2xl mt-2 text-sm leading-6 text-red-500'>{errors.supportingInfos}</p>
        ) : null}
      </div>
    </form>
  );
}

export default SupportingInfoForm;
