import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { appContext } from '../../context/ContextProvider';

const LandingPage = () => {
  const { token, user, setToken, setUser, setSelectedRole } = useContext(appContext);
  const navigate = useNavigate();

  const handleSectionClick = roleName => {
    if (!token) {
      // If the user is not authenticated, navigate to the login page
      navigate('/login');
    } else if (user && user.RoleName !== roleName) {
      setUser(null);
      setToken(null);
      localStorage.removeItem('token');
      navigate('/login');
    } else {
      setSelectedRole(roleName);
      navigate(`/dashboard/${roleName}`);
    }
  };

  const sections = [
    { label: 'Doctor', RoleName: 'doctor' },
    { label: 'Nurces', RoleName: 'nurces' },
    {
      label: 'Pharmacy',
      RoleName: 'pharmacy',
      link: 'https://portal.pharma.super-git.com/'
    },
    { label: 'Lab', RoleName: 'lab' },
    {
      label: 'Radiology',
      RoleName: 'radiology'
    },
    {
      label: 'Accounts',
      RoleName: 'accounts',
      link: 'https://portalerp.supergitsa.com/'
    },
    { label: 'HR', RoleName: 'hr', link: 'https://portal.hms.super-git.com/' },
    {
      label: 'Reception',
      RoleName: 'reception'
    },
    { label: 'RCM', RoleName: 'rcm', link: 'https://nphies.super-git.com/' }
  ];

  const handleImageClick = section => {
    if (section.link) {
      window.open(section.link, '_blank');
    } else {
      handleSectionClick(section.RoleName);
    }
  };

  return (
    <div
      className='relative min-h-screen flex flex-col items-center justify-center bg-cover bg-center'
      style={{
        backgroundImage: "url('/SUPERGIT%20SLIDE/frame-01.png')" // Background image
      }}
    >
      <div className='absolute inset-0 bg-black opacity-5'></div>
      <img src='/images/supergit-01.png' alt='Logo' className='w-24 h-24 mb-4 relative z-10' />

      <div className='relative flex flex-col items-center justify-center'>
        <div className='flex flex-wrap justify-center mt-6 '>
          {sections.slice(0, 5).map((section, index) => (
            <div key={index} className='m-2'>
              <img
                src={`/SUPERGIT%20SLIDE/${section.label.toUpperCase()}-01.png`}
                alt={section.label}
                className='w-48 h-48 object-contain cursor-pointer'
                onClick={() => handleImageClick(section)}
              />
            </div>
          ))}
        </div>
        <div className='flex flex-wrap justify-center'>
          {sections.slice(5).map((section, index) => (
            <div key={index} className='m-2'>
              <img
                src={`/SUPERGIT%20SLIDE/${section.label.toUpperCase()}-01.png`}
                alt={section.label}
                className='w-48 h-48 object-contain cursor-pointer'
                onClick={() => handleImageClick(section)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
