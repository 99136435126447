import React, { useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getSidebarNavigation } from '../../../router/routes';
import { appContext } from '../../../context/ContextProvider';
import './Scrollbar.css';
import { IconButton } from '@mui/material';
import { Add, PlusOne } from '@mui/icons-material';

export default function Sidebar({ setTooltipContent }) {
  const { sidebarOpen, activeLink, setActiveLink } = useContext(appContext);
  const { sidebar } = getSidebarNavigation();
  const location = useLocation();
  const navigate = useNavigate();

  const handleSidebarClick = link => {
    console.log('Link clicked:', link.path);
    setActiveLink(link.path);
  };

  const handleMouseEnter = (e, linkName) => {
    // Get tooltip position relative to the viewport
    const rect = e.currentTarget.getBoundingClientRect();
    setTooltipContent({
      text: linkName,
      x: rect.right + 10, // Position tooltip to the right of the sidebar
      y: rect.top + rect.height / 2 // Center vertically on the link
    });
  };

  const handleMouseLeave = () => {
    setTooltipContent(null); // Hide tooltip when not hovering
  };

  return (
    <div
      className={`side h-screen ${
        sidebarOpen ? 'w-[54px] lg:w-[110px]' : 'hidden'
      } text-white flex flex-col custom-scrollbar`}
      style={{
        backgroundColor: '#373d45',
        overflowX: 'hidden' // Ensure no horizontal scroll
      }}
    >
      <Link to='/' className='relative flex items-center justify-center h-16'>
        <img src='/images/supergit-02.png' alt='Logo' className='mt-3 h-12 w-12 md:h-12 md:w-12 shadow-2xl' />
      </Link>
      <div className='flex-grow mt-8 md:mt-10 space-y-4 overflow-y-auto overflow-x-hidden no-scrollbar'>
        <nav className='flex flex-col gap-y-[10px]'>
          {sidebar?.map((link, index) => (
            <div
              key={index}
              className='relative link-container flex gap-1 justify-start ps-2'
              onMouseEnter={e => handleMouseEnter(e, link.name)}
              onMouseLeave={handleMouseLeave}
            >
              <Link to={link.path} onClick={() => handleSidebarClick(link)} className='group'>
                <div
                  className={`relative w-fit flex items-center justify-center py-1 text-gray-400 rounded-xl shadow-2xl transition-all duration-300 ease-in-out transform group-hover:scale-105 ${
                    activeLink === link.path || location.pathname === link.path
                      ? 'bg-gray-600 text-white group-hover:bg-gray-700 group-hover:text-gray-100'
                      : 'iconcolor'
                  }`}
                >
                  <img src={link.icon} className='w-6 md:w-7 h-6 md:h-7 mx-2' alt={link.alt} />
                </div>

                <p
                  className={`text-[12px] mt-1 group-hover:text-primary ${
                    activeLink === link.path || location.pathname === link.path ? '!text-primary' : 'text-white'
                  }`}
                >
                  {link?.name}
                </p>
              </Link>

              {link.createButton && link.createButton.length > 0
                ? link.createButton?.map((btn, i) => (
                    <IconButton key={i} className='text-white' onClick={() => navigate(btn.path)}>
                      <Add />
                    </IconButton>
                  ))
                : null}
            </div>
          ))}
        </nav>
      </div>

      <div className='flex-shrink-0'>
        <div className='flex items-center justify-center p-4'>
          <img src='/imageHis/off.svg' className='w-6 md:w-8 h-6 md:h-8 mx-2' alt='Signout' />
        </div>
      </div>
    </div>
  );
}
