import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { FaUserInjured, FaMale, FaFemale   } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import { TbEditCircle } from "react-icons/tb";
import { MdCheck, MdOutlineRemoveRedEye, MdAirlineSeatIndividualSuite, MdPin, MdHourglassBottom  } from "react-icons/md";
import { PiFanFill } from "react-icons/pi";
import { FaUserDoctor } from "react-icons/fa6";
import { ImLab } from "react-icons/im";
import { GiSlashedShield } from "react-icons/gi";
import { TiPin } from "react-icons/ti";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CustomPagination from "../HIS/components/CustomPagination";
import toast from "react-hot-toast";
import FilterModal from './components/modals/FilterModal';




const tableHeaders = [
    { name: "ID #" },
    { name: "Hospital No" },
    { name: "Name" },
    { name: "Age" },
    { name: "Gender" },
    { name: "Visit Date Time" },
    { name: "Action" },
];


const tableData = [
    {
        id: 1,
        hpNo: "HP-01959",
        name: "zain khan",
        age: "28",
        gender: "Male",
        dateTIme: "03/09/2024 - 12:00 PM ",
    },
    {
        id: 2,
        hpNo: "HP-959626",
        name: "Ali khan",
        age: "45",
        gender: "Male",
        dateTIme: "03/09/2024 - 12:00 PM ",
    },
    {
        id: 3,
        hpNo: "HP-4848",
        name: "zain khan",
        age: "28",
        gender: "Male",
        dateTIme: "03/09/2024 - 12:00 PM ",
    },
    {
        id: 4,
        hpNo: "HP-595659",
        name: "Ali khan",
        age: "45",
        gender: "Male",
        dateTIme: "03/09/2024 - 12:00 PM ",
    },
]






function EmergencyList (){
    const navigate = useNavigate();
    const [showFilter, setShowFilter] = useState(false);

   
    return (<>
        <div className="superGit_container !p-0">
          <FilterModal 
            showFilter={showFilter}
            setShowFilter={setShowFilter}
          />
            <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
                <div className="">
                    <Tooltip
                        placement="right"
                        title="Keep track of your Patients List"
                        className="my-auto !mt-2"
                    >
                        <span className="font-semibold text-black/70 text-lg ">
                        Patients List
                        <span className="italic text-lg ml-1">
                            (0
                            <span className="text-xs font-bold italic">Entries</span>)
                        </span>
                        </span>
                </Tooltip>
                </div>
                 
                <div className="flex gap-3 flex-col md:flex-row">
                    {/* <Button
                        variant="outlined"
                        // onClick={() => downloadExcelFile("Beneficiaries")}
                        className="!text-white bg-gradient-to-r from-[#f14f3e] to-[#fab768] hover:opacity-80"
                        startIcon={<FileDownloadOutlinedIcon />}
                        size="small"
                    >
                        Download
                    </Button> */}
                    <Button
                        variant="contained"
                        onClick={() =>
                        navigate("/emergency/add", { state: { name: "b-form" } })
                        }
                        style={{
                        backgroundImage:
                            "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                        }}
                        startIcon={<AddIcon />}
                        size="small"
                    >
                        Create New patients
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => setShowFilter(() => !showFilter)}
                        className="!text-white bg-gradient-to-r from-[#f14f3e] to-[#fab768] hover:opacity-80"
                        startIcon={<FilterAltOutlinedIcon />}
                        size="small"
                    >
                        Apply Filter
                    </Button>
                </div>
            </div>

            <div className="super-git-table !p-0 !m-0">
              <table className="table-super-git !p-0">
                    <thead className="!p-0">
                        <tr className="!p-0">
                        {tableHeaders.map((el, index) => {
                            return (
                            <th
                                className="!p-2 !text-sm !bg-[#373d45]"
                                key={"tableHeaders_" + index}
                            >
                                {el.name}
                            </th>
                            );
                        })}
                        </tr>
                    </thead>
                    <tbody>
            {tableData?.map((el, index) => {
              return (<>
                <tr className="!p-0" key={"beneficiariesData_" + index}>
                  <td className="!p-1 !text-sm">
                    {index + 1}
                  </td>
                  <td className="!p-1 !text-sm capitalize text-center">
                  {el.hpNo}
                  </td>
                  <td className="!p-1 !text-sm">
                  {el.name}
                  </td>
                  <td className="!p-1 !text-sm">
                  {el.age}
                  </td>

                  <td className="!p-1 !text-sm">
                    {el.gender}
                  </td>
                  <td className="!p-1 !text-sm ">
                    {el.dateTIme}
                  </td>

                  
                  {/* <td>{el.providers_name_en}</td> */}
                  <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1">
                    <Tooltip title="Edit">
                      <div
                        // onClick={() => onClickEdit(el)}
                        className="text-xl cursor-pointer text-orange-400"
                      >
                        <TbEditCircle />
                      </div>
                    </Tooltip>
                  </td>
                </tr>
                </>);
            })}
          </tbody>
        </table>
        
            </div>
            <div>
        <CustomPagination
          currentPage={1}
          rowsPerPage={5}
          totalRecords={0}
          // onPageChange={onPageChange}
        />
        </div>
        </div>
    </>)
}




export default EmergencyList;