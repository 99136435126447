import { CircularProgress } from '@mui/material';
import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '50%',
    border: 'none',
    background: 'none'
  },
  overlay: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    transition: 'backdrop-filter 0.3s ease-in-out'
  }
};

function AppLoader({ showLoader, size = '3rem' }) {
  return (
    <Modal isOpen={showLoader} style={customStyles}>
      <div className='items-center justify-center flex'>
        <CircularProgress className='!text-[#ffab2c]' size={size} />
      </div>
    </Modal>
  );
}

export default AppLoader;
