import React, { useEffect, useState } from "react";
import FormList from "./FormList";
import CategoryList from "./CategoryList";
import SubCategoryList from "./SubCategoryList";
import ElementList from "./ElementList";

export default function LabForms() {
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);

  useEffect(() => {
    setSelectedCategoryId(null);
    setSelectedSubCategoryId(null);
  }, [selectedReportId]);

  useEffect(() => {
    setSelectedSubCategoryId(null);
  }, [selectedCategoryId]);

  return (
    <div className="grid grid-cols-4">
      <FormList setSelectedReportId={setSelectedReportId} />
      {selectedReportId && (
        <CategoryList
          selectedReportId={selectedReportId}
          setSelectedCategoryId={setSelectedCategoryId}
        />
      )}
      {selectedCategoryId && (
        <SubCategoryList
          selectedCategoryId={selectedCategoryId}
          setSelectedSubCategoryId={setSelectedSubCategoryId}
        />
      )}
      {selectedSubCategoryId && (
        <ElementList selectedSubCategoryId={selectedSubCategoryId} />
      )}
    </div>
  );
}
