import React, { useEffect, useState } from 'react';

import FormList from '../../../components/formCreation/FormList';
import CategoryList from '../../../components/formCreation/CategoryList';
import SubCategoryList from '../../../components/formCreation/SubCategoryList';
import ElementList from '../../../components/formCreation/ElementList';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const FormCreation = () => {
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [selectedFormType, setSelectedFormType] = useState('visit');

  useEffect(() => {
    setSelectedReportId(null);
  }, [selectedFormType]);

  useEffect(() => {
    setSelectedCategoryId(null);
    setSelectedSubCategoryId(null);
  }, [selectedReportId]);

  useEffect(() => {
    setSelectedSubCategoryId(null);
  }, [selectedCategoryId]);

  return (
    <div>
      <FormControl fullWidth margin='dense' variant='outlined' className='bg-white my-3 !w-[300px] !ms-6'>
        <InputLabel>Select Form Type</InputLabel>
        <Select name='type' label='Type' value={selectedFormType} onChange={e => setSelectedFormType(e.target.value)}>
          <MenuItem value='labs'>Labs</MenuItem>
          <MenuItem value='radiology'>Radiology</MenuItem>
          <MenuItem value='visit'>Homecare</MenuItem>
          <MenuItem value='general'>General</MenuItem>
        </Select>
      </FormControl>
      <div className=' grid grid-cols-1 xl:grid-cols-4'>
        <FormList setSelectedReportId={setSelectedReportId} selectedFormType={selectedFormType} isAllowActions={true} />
        {selectedReportId && (
          <CategoryList
            selectedReportId={selectedReportId}
            setSelectedCategoryId={setSelectedCategoryId}
            selectedFormType={selectedFormType}
            isAllowActions={true}
          />
        )}
        {selectedCategoryId && (
          <SubCategoryList
            selectedCategoryId={selectedCategoryId}
            setSelectedSubCategoryId={setSelectedSubCategoryId}
            selectedFormType={selectedFormType}
          />
        )}
        {selectedSubCategoryId && (
          <ElementList selectedSubCategoryId={selectedSubCategoryId} selectedFormType={selectedFormType} />
        )}
      </div>
    </div>
  );
};

export default FormCreation;
