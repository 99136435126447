import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { TbEditCircle } from 'react-icons/tb';
import { LuTrash2 } from 'react-icons/lu';

import { getNursesList } from '../HIS/components/ApiMethods';
import CustomPagination from '../HIS/components/CustomPagination';
import AppLoader from '../../components/AppLoader';

const NursingList = () => {
  const [nursesList, setNursesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getNursesList(setLoading, setNursesList, {
      page: 1,
      per_page: 10
    });
  }, []);

  const onPageChange = (newPage, newRowsPerPage) => {
    getNursesList(setLoading, setNursesList, {
      page: newPage,
      per_page: newRowsPerPage
    });
  };

  const tableHeaders = [
    { name: 'SQ #' },
    { name: 'Name' },
    { name: 'Document ID' },
    { name: 'Role' },
    { name: 'Actions' }
  ];

  return (
    <div className='physicians_container'>
      <div className='physicians_page !bg-transparent'>
        <div className='flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3'>
          <div className=''>
            <Tooltip placement='right' title='Keep track of your physicians accounts' className='my-auto !mt-2'>
              <span className='font-semibold text-black/70 text-lg '>
                NURSES
                <span className='italic text-lg ml-1'>
                  ({nursesList?.data?.length || 0} <span className='text-xs font-bold italic'>Entries</span>)
                </span>
              </span>
            </Tooltip>
          </div>

          <div class=' flex gap-3 flex-col md:flex-row'>
            <Button
              variant='contained'
              size='small'
              onClick={() => navigate('/nurses/form')}
              style={{
                backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
              }}
              startIcon={<AddIcon />}
            >
              Add New Nurse
            </Button>
          </div>
        </div>
      </div>
      <div className='super-git-table'>
        <table className='table-super-git'>
          <thead className='!p-0'>
            <tr className='!p-0'>
              {tableHeaders.map((el, index) => {
                return (
                  <th className='!p-2 !text-sm !bg-[#373d45]' key={'tableHeaders_' + index}>
                    {el.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {nursesList?.data?.map((el, index) => {
              return (
                <tr key={index}>
                  <td className='!p-1 !text-sm'>{index + 1}</td>
                  <td className='!p-1 !text-sm capitalize'>{el?.name}</td>
                  <td className='!p-1 !text-sm'>{el?.document_id}</td>
                  <td className='!p-1 !text-sm'>{el?.role}</td>
                  <td className='flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1'>
                    <Tooltip
                      title='Edit'
                      onClick={() => navigate('/nurses/edit/form/' + el.nurse_id, { state: { nurse: el } })}
                    >
                      <div className='text-xl cursor-pointer'>
                        <TbEditCircle />
                      </div>
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <div className='text-xl cursor-pointer'>
                        <LuTrash2 />
                      </div>
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        <CustomPagination
          currentPage={nursesList?.page || 1}
          rowsPerPage={nursesList?.per_page || 5}
          totalRecords={nursesList?.totalCount || 0}
          onPageChange={onPageChange}
        />
      </div>
      <AppLoader showLoader={loading} />
    </div>
  );
};

export default NursingList;
