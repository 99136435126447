import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { FaUserInjured, FaMale, FaFemale   } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import { TbEditCircle } from "react-icons/tb";
import { MdCheck, MdOutlineRemoveRedEye, MdAirlineSeatIndividualSuite, MdPin, MdHourglassBottom  } from "react-icons/md";
import { PiFanFill } from "react-icons/pi";
import { FaUserDoctor } from "react-icons/fa6";
import { ImLab } from "react-icons/im";
import { GiSlashedShield } from "react-icons/gi";
import { TiPin } from "react-icons/ti";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CustomPagination from "../HIS/components/CustomPagination";
import toast from "react-hot-toast";
import FilterModal from './components/modals/FilterModal';




const tableHeaders = [
    { name: "" },
    { name: "ID #" },
    { name: "Date / Time" },
    { name: "Patient" },
    { name: "Account" },
    { name: "Doctor" },
    { name: "Appointment" },
    { name: "View" },
    { name: "Ch-In" },
    { name: "Result" },
    { name: "Action" },
];


const tableData = [
    {
        id: 11,
        time: "23:44",
        date: "03/09/2024",
        patient: {
            name: "zain khan",
            mrnNo: "480070",
            age: "22",
            tNo: "200213",
            country: "Pakistan",
            gander: "Male"
        },
        paymentType: "010...",
        doctorName: "Salman Ali",
        dId: "65",
        result: [
          {
            id: 3150,
            service: "Ultrasound",
            category: "",
            group: "XRAY: X-RAY"
          },
          {
            id: 3149,
            service: "Ultrasound",
            category: "",
            group: "XRAY: X-RAY"
          }
        ]
    },
    {
      id: 12,
      time: "23:44",
      date: "03/09/2024",
      patient: {
          name: "zain khan",
          mrnNo: "480070",
          age: "22",
          tNo: "200213",
          country: "Pakistan",
          gander: "Male"
      },
      paymentType: "010...",
      doctorName: "Salman Ali",
      dId: "65",
  },
  {
    id: 13,
    time: "23:44",
    date: "03/09/2024",
    patient: {
        name: "zain khan",
        mrnNo: "480070",
        age: "22",
        tNo: "200213",
        country: "Pakistan",
        gander: "Femail",
    },
    paymentType: "010...",
    doctorName: "Salman Ali",
    dId: "65",
  }
]






function RadiologyList (){
    const navigate = useNavigate();
    const [showFilter, setShowFilter] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null);

    const toggleRow = (id) => {
      setExpandedRow(expandedRow === id ? null : id);
    };
    return (<>
        <div className="superGit_container !p-0">
          <FilterModal 
            showFilter={showFilter}
            setShowFilter={setShowFilter}
          />
            <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3">
                <div className="">
                    <Tooltip
                        placement="right"
                        title="Keep track of your Radiology Requests"
                        className="my-auto !mt-2"
                    >
                        <span className="font-semibold text-black/70 text-lg ">
                        Radiology Requests
                        <span className="italic text-lg ml-1">
                            (0
                            <span className="text-xs font-bold italic">Entries</span>)
                        </span>
                        </span>
                </Tooltip>
                </div>
                 
                <div className="flex gap-3 flex-col md:flex-row">
                    {/* <Button
                        variant="outlined"
                        // onClick={() => downloadExcelFile("Beneficiaries")}
                        className="!text-white bg-gradient-to-r from-[#f14f3e] to-[#fab768] hover:opacity-80"
                        startIcon={<FileDownloadOutlinedIcon />}
                        size="small"
                    >
                        Download
                    </Button> */}
                    <Button
                        variant="contained"
                        onClick={() =>
                        navigate("/radiology/add", { state: { name: "b-form" } })
                        }
                        style={{
                        backgroundImage:
                            "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                        }}
                        startIcon={<AddIcon />}
                        size="small"
                    >
                        Create Radiology Requests
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => setShowFilter(() => !showFilter)}
                        className="!text-white bg-gradient-to-r from-[#f14f3e] to-[#fab768] hover:opacity-80"
                        startIcon={<FilterAltOutlinedIcon />}
                        size="small"
                    >
                        Apply Filter
                    </Button>
                </div>
            </div>

            <div className="super-git-table !p-0 !m-0">
              <table className="table-super-git !p-0">
                    <thead className="!p-0">
                        <tr className="!p-0">
                        {tableHeaders.map((el, index) => {
                            return (
                            <th
                                className="!p-2 !text-sm !bg-[#373d45]"
                                key={"tableHeaders_" + index}
                            >
                                {el.name}
                            </th>
                            );
                        })}
                        </tr>
                    </thead>
                    <tbody>
            {tableData?.map((el, index) => {
              return (<>
                <tr className="!p-0" key={"beneficiariesData_" + index}>
                  <td className='!p-1 !text-sm'>
                    <button onClick={() => toggleRow(el.id)}>
                      {expandedRow === el.id ? "▲" : "▼"}
                    </button>
                  </td>
                  <td className="!p-1 !text-sm">
                    {index + 1}
                  </td>
                  <td className="!p-1 !text-sm capitalize text-center">
                    <div>
                      <p>Time: {el.time}</p>
                      <p>Date: {el.date}</p>
                    </div>
                  </td>
                  <td className="!p-1 !text-sm">
                    <div className='d-flex justify-between align-items-center'>
                      <div className='w-100 me-3'>
                        <p className='d-flex justify-between'>
                          <span>MRN: {el?.patient?.mrnNo}</span>
                          <span>AGE: {el?.patient?.age}</span>
                        </p>
                        <p className='d-flex justify-between'>
                          <span>T#{el?.patient?.tNo}</span>
                          <span>{el?.patient?.name}</span>
                        </p>
                      </div>
                      <div className='text-[25px]'>
                        {el?.patient?.gander === 'Male' ? <FaMale /> : <FaFemale />}
                      </div>
                    </div>
                  </td>
                  <td className="!p-1 !text-sm">
                    <p className='text-center'>{el.paymentType}</p>
                    <p className='text-center'>Al Shorouk</p>
                  </td>

                  <td className="!p-1 !text-sm">
                    <p>{el.dId} <span className='ms-2'>X-RAY</span></p>
                    <p>DR \ {el.doctorName}</p>
                  </td>
                  <td className="!p-1 text-xl ">
                    <div className='d-flex align-items-center justify-center'>
                      <TiPin />
                      <span className='d-flex align-items-center mx-2'>
                        <MdPin /> 1
                      </span>
                      <span className='d-flex align-items-center'>
                        <MdHourglassBottom /> 1
                      </span>
                    </div>
                  </td>

                  {/* yyy */}
                  <td className="!p-1 !text-sm">
                    <Tooltip title="View">
                      <div
                        // onClick={() => handleViewClick(el)}
                        className="text-xl cursor-pointer d-flex justify-center"
                      >
                        <MdOutlineRemoveRedEye />
                      </div>
                    </Tooltip>
                  </td>
                  <td className="!p-1 !text-sm">
                    <div className='text-xl cursor-pointer d-flex justify-center'>
                      <MdCheck />
                    </div>
                  </td>

                  <td className="!p-1 !text-sm">
                    <div className="text-xl flex gap-2 justify-center">
                      <FaUserDoctor />
                      <PiFanFill />
                      <MdAirlineSeatIndividualSuite />
                      <ImLab />
                      <FaUserInjured />
                      <GiSlashedShield />
                    </div>
                  </td>
                  {/* <td>{el.providers_name_en}</td> */}
                  <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1">
                    <Tooltip title="Edit">
                      <div
                        // onClick={() => onClickEdit(el)}
                        className="text-xl cursor-pointer text-orange-400"
                      >
                        <TbEditCircle />
                      </div>
                    </Tooltip>
                  </td>
                </tr>

                {/* Expandable Row for Test Results */}
                {expandedRow === el.id && el?.result?.length > 0 && (
                  <tr>
                    <td colSpan="11" style={{ padding: "10px" }}>
                      <table className='table-super-git table-bordered !p-0' style={{ width: "100%", border: "1px solid #ddd" }}>
                        <thead>
                          <tr>
                            <th className="!p-2 !text-sm !bg-[#373d45]">#</th>
                            <th className="!p-2 !text-sm !bg-[#373d45]">Service</th>
                            <th className="!p-2 !text-sm !bg-[#373d45]">Category</th>
                            <th className="!p-2 !text-sm !bg-[#373d45]">Group</th>
                          </tr>
                        </thead>
                        <tbody>
                          {el?.result?.map((test, i) => (
                            <tr key={i}>
                              <td className="!p-1 !text-sm">{i + 1}</td>
                              <td className="!p-1 !text-sm">{test.id}: {test.service}</td>
                              <td className="!p-1 !text-sm">{test.category}</td>
                              <td className="!p-1 !text-sm">{test.group}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
                </>);
            })}
          </tbody>
        </table>
        
            </div>
            <div>
        <CustomPagination
          currentPage={1}
          rowsPerPage={5}
          totalRecords={0}
          // onPageChange={onPageChange}
        />
        </div>
        </div>
    </>)
}




export default RadiologyList;