// src/components/Nephrology.js
import React from 'react';
import { Button, TextField, IconButton, InputAdornment, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomPagination from '../HIS/components/CustomPagination';
import { MdOutlineRemoveRedEye } from 'react-icons/md';

const Nephrology = () => {
  // Sample data
  const sampleData = [
    {
      requestNumber: 'REQ001',
      hospitalNumber: 'HOSP1234',
      dialysisCenter: 'Center A',
      patientName: 'John Doe',
      phone: '123-456-7890',
      age: 45,
      sex: 'M',
      serviceNumber: 'SRV001',
      performerName: 'Dr. Smith',
      action: 'View'
    },
    {
      requestNumber: 'REQ002',
      hospitalNumber: 'HOSP5678',
      dialysisCenter: 'Center B',
      patientName: 'Jane Smith',
      phone: '987-654-3210',
      age: 52,
      sex: 'F',
      serviceNumber: 'SRV002',
      performerName: 'Dr. Johnson',
      action: 'View'
    },
    {
      requestNumber: 'REQ003',
      hospitalNumber: 'HOSP9101',
      dialysisCenter: 'Center C',
      patientName: 'Mike Brown',
      phone: '555-123-4567',
      age: 60,
      sex: 'M',
      serviceNumber: 'SRV003',
      performerName: 'Dr. Lee',
      action: 'View'
    },
    {
      requestNumber: 'REQ004',
      hospitalNumber: 'HOSP1213',
      dialysisCenter: 'Center D',
      patientName: 'Emily Davis',
      phone: '444-555-6666',
      age: 35,
      sex: 'F',
      serviceNumber: 'SRV004',
      performerName: 'Dr. Adams',
      action: 'View'
    },
    {
      requestNumber: 'REQ005',
      hospitalNumber: 'HOSP1415',
      dialysisCenter: 'Center E',
      patientName: 'Chris Wilson',
      phone: '333-777-8888',
      age: 29,
      sex: 'M',
      serviceNumber: 'SRV005',
      performerName: 'Dr. White',
      action: 'View'
    }
  ];

  return (
    <div className='p-2 h-screen'>
      <h2 className='text-2xl font-bold mb-4'>Nephrology</h2>
      {/* Search and Buttons */}
      {/* <div className='flex justify-between items-center mb-4'>
        <TextField
          variant='outlined'
          size='small'
          placeholder='Search'
          className='mr-4 w-1/5'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <div className='flex space-x-2'>
          <Button size='small' variant='contained' color='primary'>
            Export
          </Button>
          <Button size='small' variant='contained' color='primary'>
            Print
          </Button>
        </div>
      </div> */}

      {/* Table */}
      <div className='super-git-table !p-0 !m-0'>
        <table className='table-super-git !p-0'>
          <thead className='!p-0'>
            <tr className='!p-0'>
              <th className='!p-2 !text-sm !bg-[#373d45]'>Request #</th>
              <th className='!p-2 !text-sm !bg-[#373d45]'>Hospital #</th>
              <th className='!p-2 !text-sm !bg-[#373d45]'>Dialysis Center</th>
              <th className='!p-2 !text-sm !bg-[#373d45]'>Patient Name</th>
              <th className='!p-2 !text-sm !bg-[#373d45]'>Phone</th>
              <th className='!p-2 !text-sm !bg-[#373d45]'>Age</th>
              <th className='!p-2 !text-sm !bg-[#373d45]'>Sex</th>
              <th className='!p-2 !text-sm !bg-[#373d45]'>Service Number</th>
              <th className='!p-2 !text-sm !bg-[#373d45]'>Performer Name</th>
              <th className='!p-2 !text-sm !bg-[#373d45]'>Action</th>
            </tr>
          </thead>
          <tbody>
            {[]?.map((data, index) => (
              <tr key={index} className='!p-0'>
                <td className='!p-1 !text-sm'>{data.requestNumber}</td>
                <td className='!p-1 !text-sm'>{data.hospitalNumber}</td>
                <td className='!p-1 !text-sm'>{data.dialysisCenter}</td>
                <td className='!p-1 !text-sm'>{data.patientName}</td>
                <td className='!p-1 !text-sm'>{data.phone}</td>
                <td className='!p-1 !text-sm'>{data.age}</td>
                <td className='!p-1 !text-sm'>{data.sex}</td>
                <td className='!p-1 !text-sm'>{data.serviceNumber}</td>
                <td className='!p-1 !text-sm'>{data.performerName}</td>
                <td className='flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1'>
                  <Tooltip title='View'>
                    <div className='text-xl cursor-pointer text-green-500'>
                      <MdOutlineRemoveRedEye />
                    </div>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <CustomPagination currentPage={1} rowsPerPage={5} totalRecords={5} onPageChange={() => {}} />
    </div>
  );
};

export default Nephrology;
