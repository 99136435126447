import React, {useState} from 'react';
import { Tooltip } from "@mui/material";
// import { TbEditCircle } from "react-icons/tb";
// import CustomPagination from "../HIS/components/CustomPagination";




const tableHeaders = [
    { name: "ID #" },
    { name: "Ward Name" },
    { name: "Occupied" },
    { name: "Vacant" },
    { name: "Total" },
];


const tableData = [
    {
        id: 1,
        wardName: "Brain Ward",
        Occupied: "4",
        vacant: "2",
        total: "6",
    },
    {
        id: 2,
        wardName: "Female Ward ",
        Occupied: "4",
        vacant: "2",
        total: "6",
    },
    {
        id: 3,
        wardName: "ICU",
        Occupied: "4",
        vacant: "2",
        total: "6",
    },
    {
        id: 4,
        wardName: "Male Ward ",
        Occupied: "4",
        vacant: "2",
        total: "6",
    },
]






function PatientBedInfo (){

   
    return (<>
        <div className="superGit_container !p-0">
            <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3 align-items-center">
                <div className="">
                    <Tooltip
                        placement="right"
                        title="Keep track of your Bed Information"
                        className="my-auto !mt-2"
                    >
                        <span className="font-semibold text-black/70 text-lg ">
                        Bed Information
                        <span className="italic text-lg ml-1">
                            (0
                            <span className="text-xs font-bold italic">Entries</span>)
                        </span>
                        </span>
                </Tooltip>
                </div>
                
                <div className="flex gap-3 flex-col md:flex-row">
                    <label class="text-sm mt-3">Search:</label>
                    <input
                        type='text'
                        placeholder=""
                        name="search"
                        id="search"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
            </div>
            <div className="super-git-table !p-0 !m-0">
              <table className="table-super-git !p-0">
                    <thead className="!p-0">
                        <tr className="!p-0">
                        {tableHeaders.map((el, index) => {
                            return (
                            <th
                                className="!p-2 !text-sm !bg-[#373d45]"
                                key={"tableHeaders_" + index}
                            >
                                {el.name}
                            </th>
                            );
                        })}
                        </tr>
                    </thead>
                    <tbody>
            {tableData?.map((el, index) => {
              return (<>
                <tr className="!p-0" key={"beneficiariesData_" + index}>
                  <td className="!p-1 !text-sm">
                    {index + 1}
                  </td>
                  <td className="!p-1 !text-sm capitalize text-center">
                  {el.wardName}
                  </td>
                  <td className="!p-1 !text-sm">
                  {el.Occupied}
                  </td>
                  <td className="!p-1 !text-sm">
                  {el.vacant}
                  </td>

                  <td className="!p-1 !text-sm">
                    {el.total}
                  </td>
                </tr>
                </>);
            })}
          </tbody>
        </table>
        
            </div>
        </div>
    </>)
}




export default PatientBedInfo;