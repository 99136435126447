import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import AppLoader from '../../components/AppLoader';
import { Button } from '@mui/material';
import { createNewDriver, updateDriver } from '../HIS/components/ApiMethods';

const AddDriverForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const driverData = location?.state?.driver;

  let initialValues = {
    name: driverData?.name ?? '',
    role: driverData?.role ?? 'driver',
    document_id: driverData?.document_id ?? '',
    license: driverData?.license ?? '',
    rcm_ref: driverData?.rcm_ref ?? 0
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    role: Yup.string().required('Role is required'),
    document_id: Yup.string().required('Document ID is required'),
    license: Yup.string().required('License Number is required')
  });

  const handleAddDriver = async values => {
    if (driverData) {
      let payload = {
        ...values,
        driver_id: driverData?.driver_id
      };
      await updateDriver(setLoading, payload, navigate);
    } else {
      await createNewDriver(setLoading, values, navigate);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className='superGit_container'>
      <div class='benefeciary-create'>
        <div class='benefeciary-top'>
          <div class='back' onClick={handleGoBack}>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
              <path d='M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z' fill='#4D4D53' />
            </svg>
          </div>
          <div>
            <div class='benefeciary-title'>
              <span>{driverData?.driver_id ? 'Edit' : 'Create New'} Driver</span>
            </div>
            <div class='benefeciary-content'>
              <span>{driverData?.driver_id ? 'Edit' : 'Create New'} Driver account details</span>
            </div>
          </div>
        </div>
        <div class='personal-data'>
          <div class='personal-title'>
            <div class='inventory-svg'>
              <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect width='34' height='34' rx='8' fill='#F4F6F8' />
                <path
                  d='M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z'
                  fill='#F5793B'
                />
                <path d='M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z' fill='#F5793B' />
              </svg>
            </div>
            <div class='personal-head'>
              <span>Driver Data</span>
            </div>
          </div>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleAddDriver}>
            {({ isSubmitting }) => (
              <Form>
                <div class='personal-fields grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-3'>
                  <div className='flex flex-col gap-1'>
                    <label htmlFor='name'>Name*</label>
                    <Field
                      id='name'
                      name='name'
                      type='text'
                      placeholder='Enter driver name'
                      className='border !text-[14px] rounded-md p-2 focus:ring-2 focus:ring-blue-500'
                    />
                    <ErrorMessage name='name' component='div' className='text-red-500 text-sm' />
                  </div>
                  <div className='flex flex-col gap-1'>
                    <label htmlFor='document_id'>Document ID*</label>
                    <Field
                      id='document_id'
                      name='document_id'
                      type='text'
                      placeholder='Enter document id'
                      className='border !text-[14px] rounded-md p-2 focus:ring-2 focus:ring-blue-500'
                    />
                    <ErrorMessage name='document_id' component='div' className='text-red-500 text-sm' />
                  </div>
                  <div className='flex flex-col gap-1'>
                    <label htmlFor='document_id'>License Number*</label>
                    <Field
                      id='license'
                      name='license'
                      type='text'
                      placeholder='Enter license number'
                      className='border !text-[14px] rounded-md p-2 focus:ring-2 focus:ring-blue-500'
                    />
                    <ErrorMessage name='license' component='div' className='text-red-500 text-sm' />
                  </div>
                  <div className='flex flex-col gap-1'>
                    <label htmlFor='role'>Role*</label>
                    <Field
                      id='role'
                      name='role'
                      as='select'
                      placeholder='Select Role'
                      className='border !text-[14px] rounded-md p-2 focus:ring-2 focus:ring-blue-500'
                    >
                      <option value='driver'>Driver</option>
                    </Field>
                    <ErrorMessage name='role' component='div' className='text-red-500 text-sm' />
                  </div>
                </div>
                <Button
                  variant='contained'
                  type='submit'
                  size='large'
                  style={{
                    backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
                  }}
                  className='px-6 py-1 mt-2 text-white font-semibold rounded-md shadow-sm hover:shadow-md transition-all duration-300'
                >
                  {driverData ? 'Edit Driver' : 'Add Driver'}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <AppLoader showLoader={loading} />
    </div>
  );
};

export default AddDriverForm;
