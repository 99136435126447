import React from "react";
import { FieldArray, ErrorMessage, useFormikContext } from "formik";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button } from "@mui/material";

function ArrayInput({ name, title }) {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext();
  const arrayValues = values[name]; // Dynamically access the value based on the `name` prop.

  return (
    <div className="array-input-wrapper p-3 mb-3 bg-white rounded-md shadow-md">
      <FieldArray name={name}>
        {({ push, remove }) => (
          <>
            <div className="flex justify-between mb-3">
              <h1 className="font-semibold text-[#344054] text-lg">{title}</h1>
              <Button
                variant="contained"
                size="small"
                style={{
                  backgroundImage:
                    "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                }}
                onClick={() => push("")} // Push a new empty string to the array.
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            </div>
            <div>
              {arrayValues?.map((item, index) => (
                <div
                  key={index}
                  className="array-input-item p-3 mb-2 border border-gray-300 rounded-md"
                >
                  <div className="flex gap-8">
                    <div className="w-full">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor={`${name}.${index}`}
                      >
                        Value
                      </label>
                      <input
                        className="w-full rounded-md border border-gray-100 p-2"
                        type="text"
                        id={`${name}.${index}`}
                        name={`${name}.${index}`}
                        value={item}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter value"
                      />
                      <ErrorMessage
                        name={`${name}.${index}`}
                        component="div"
                        className="text-red-500 text-xs mt-1"
                      />
                    </div>
                    <div className="flex items-end justify-end">
                      <button
                        type="button"
                        className="bg-red-500 border border-red-500 text-white w-[40px] h-[40px] rounded-full flex justify-center items-center"
                        onClick={() => remove(index)}
                      >
                        <RemoveIcon />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {errors[name] && touched[name] && (
              <div className="max-w-2xl mt-2 text-sm leading-6">
                <p className="text-red-500">{errors[name]}</p>
              </div>
            )}
          </>
        )}
      </FieldArray>
    </div>
  );
}

export default ArrayInput;
