export const formatDateTime = datetimeString => {
  const date = new Date(datetimeString);

  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const amPm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12;

  return `${day} ${month} ${year} ${hours}:${minutes} ${amPm}`;
};
