import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Chip,
  Box,
  Divider,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Modal
} from '@mui/material';
import { IoMdCloseCircleOutline } from 'react-icons/io';

import {
  FaCalendarAlt,
  FaUser,
  FaStethoscope,
  FaInfoCircle,
  FaAllergies,
  FaDiagnoses,
  FaUserMd,
  FaHospital,
  FaSyringe,
  FaDoorOpen,
  FaArrowLeft,
  FaPlus,
  FaPrint,
  FaDisease
} from 'react-icons/fa';
import { ImCheckboxChecked } from 'react-icons/im';

import { VscFeedback } from 'react-icons/vsc';

import { IconButton } from '@mui/material';
import UcafForm from './UcafForm';
import DcafForm from './DcafForm';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { MdFamilyRestroom, MdOutlineInventory } from 'react-icons/md';

const formatDateTime = dateTimeString => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  return new Date(dateTimeString).toLocaleDateString(undefined, options);
};

const EmrCard = ({ entry, setData }) => {
  const [showUCAF, setShowUCAF] = useState(false);
  const [showDCAF, setShowDCAF] = useState(false);
  const printRef = useRef(null);

  const itemsHeaders = ['No', 'Name', 'Standard Code', 'No Standard Code', 'Type'];
  const supportInfoHeaders = ['No', 'Category', 'Code', 'Value', 'Actions'];
  const vitalSignsHeaders = [
    'vital-sign-systolic (mm[Hg])',
    'vital-sign-diastolic (mm[Hg])',
    'vital-sign-height (cm)',
    'vital-sign-weight (kg)',
    'respiratory-rate (mir)',
    'temperature (cel)',
    'pulse (mir)',
    'SP02 (%)',
    'RBS (mg/dl)',
    'pain-score'
  ];

  const careTeamHeader = ['No', 'Name', 'Role', 'Qualification'];
  const diagnosisHeaders = ['No', 'Description', 'Type', 'On Admission'];
  const allergyIntoleranceHeaders = ['No', 'Category', 'Type', 'Onset', 'Recorded'];

  let checkboxes = [
    'er_case',
    'chronic',
    'congential',
    'rta',
    'work_related',
    'vaccination',
    'checkup',
    'psychiatric',
    'infertility',
    'pregnancy',
    'lactation',
    'acute'
  ];

  const checkboxResults = checkboxes.reduce((acc, key) => {
    if (entry.hasOwnProperty(key)) {
      acc[key] = entry[key];
    }
    return acc;
  }, {});

  const filteredcheckboxResults = checkboxes
    ?.filter(checkbox => entry[checkbox] === true)
    ?.map(checkbox => ({ [checkbox]: entry[checkbox] }));

  const itemsValues = entry?.items?.map(item => [
    item?.name || item?.non_standard_description,
    item?.standard_code,
    item?.non_standard_code,
    item?.type
  ]);

  const supportInfoValues = entry?.supportingInfos
    ?.map(data => {
      const excludedCategories = [
        'vital-sign-systolic',
        'vital-sign-diastolic',
        'vital-sign-height',
        'vital-sign-weight',
        'respiratory-rate',
        'temperature',
        'pulse',
        'SP02',
        'RBS',
        'pain-score'
      ];
      if (excludedCategories.includes(data?.category)) {
        return undefined;
      }

      return [
        data?.category,
        data?.code,
        data?.value,
        data?.category == 'attachment' || data?.category == 'investigation-result' ? data : '--'
      ];
    })
    .filter(item => item !== undefined);

  const vitalSignsValues = entry?.supportingInfos
    ?.map(data => {
      const includedCategories = [
        'vital-sign-systolic',
        'vital-sign-diastolic',
        'vital-sign-height',
        'vital-sign-weight',
        'respiratory-rate',
        'temperature',
        'pulse',
        'SP02',
        'RBS',
        'pain-score'
      ];
      if (includedCategories.includes(data?.category)) {
        return { category: data?.category, value: data?.value };
      } else {
        return undefined;
      }
    })
    .filter(item => item !== undefined);

  const careTeamValues = entry?.careTeamsArr?.map(team => {
    return [team?.practitionerName, team?.care_team_role, team?.qualification];
  });

  const diagnosisValues = entry?.diagnosisArr?.map(diagnosis => {
    return [diagnosis?.codeDescription, diagnosis?.on_admission, diagnosis?.type];
  });

  const allergyIntoleranceValues = entry?.allergyIntolerance?.map(allergy => {
    return [
      allergy?.category,
      allergy?.type,
      formatDateTime(allergy?.onsetDateTime),
      formatDateTime(allergy?.recordedDate)
    ];
  });

  const handlePrint = useReactToPrint({
    contentRef: printRef,
    documentTitle: 'Emr'
  });

  return (
    <Card sx={{ borderRadius: 2, boxShadow: '0 2px 4px rgba(0,0,0,0.1)', mb: 2 }}>
      <CardHeader
        title={
          <Typography variant='h6' fontWeight='bold'>
            <IconButton
              onClick={() => setData(null)}
              aria-label='go back'
              className='!mr-2 !w-[30px] !h-[30px] !text-white !bg-red-400 !rounded-full'
            >
              <FaArrowLeft />
            </IconButton>
            EMR Entry
          </Typography>
        }
        subheader={<Typography variant='subtitle2'>{formatDateTime(entry?.date || new Date())}</Typography>}
        action={
          <div className='pt-[15px] pr-[15px]'>
            <Button
              variant='contained'
              onClick={() => setShowUCAF(true)}
              style={{
                backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
              }}
              startIcon={<FaPlus />}
              size='small'
            >
              UCAF
            </Button>
            <Button
              variant='contained'
              onClick={handlePrint}
              style={{
                backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                marginLeft: '5px'
              }}
              startIcon={<FaPrint />}
              size='small'
            >
              Print
            </Button>
            <UcafForm showUCAF={showUCAF} setShowUCAF={setShowUCAF} item={entry} />
            <DcafForm showDCAF={showDCAF} setShowDCAF={setShowDCAF} item={entry} />
          </div>
        }
        sx={{ borderBottom: '1px solid #e0e0e0', padding: 1 }}
      />
      <CardContent sx={{ padding: 1.5 }} ref={printRef}>
        <DataSection
          title='Patient Information'
          icon={<FaUser />}
          data={[
            { label: 'Patient Name', value: entry?.patient.full_name },
            { label: 'Beneficiary Type', value: entry?.patient.beneficiary_type },
            { label: 'Date Of Birth', value: entry?.patient?.dob },
            { label: 'Document ID ', value: entry?.patient.document_id },
            { label: 'File No ', value: entry?.patient.file_no },
            { label: 'Address', value: entry?.patient.address }
          ]}
        />

        <VitalSignsTable
          title='Vital Signs'
          icon={<MdOutlineInventory />}
          headers={vitalSignsHeaders}
          rows={vitalSignsValues}
        />

        <Box className='flex items-center flex-wrap gap-4 mt-1'>
          {filteredcheckboxResults?.map(data => (
            <Box className='flex items-center gap-2'>
              <ImCheckboxChecked className='text-[15px]' />
              <p className='capitalize text-[#333]'>{Object.keys(data)[0]}</p>
            </Box>
          ))}
        </Box>

        <DataSectionTable title='Diagnosis' icon={<FaDiagnoses />} headers={diagnosisHeaders} rows={diagnosisValues} />

        <DataSectionTable
          title='Supporting Information'
          icon={<FaInfoCircle />}
          headers={supportInfoHeaders}
          rows={supportInfoValues}
        />

        <DataSectionTable title='Items' icon={<FaInfoCircle />} headers={itemsHeaders} rows={itemsValues} />

        <DataSectionTable title='Care Team' icon={<FaUserMd />} headers={careTeamHeader} rows={careTeamValues} />
        <DataSectionTable
          title='Allergy Intolerance'
          icon={<FaAllergies />}
          headers={allergyIntoleranceHeaders}
          rows={allergyIntoleranceValues}
        />

        <DataSection
          title='Physical Assesment'
          icon={<FaInfoCircle />}
          data={[{ label: 'Physical Assesment', value: entry?.physical_assesment }]}
        />

        <Box className='w-full grid grid-cols-2 gap-4'>
          <DataSection
            title='Family History'
            icon={<MdFamilyRestroom />}
            data={[{ label: 'Family History', value: entry?.family_history }]}
          />
          <DataSection
            title='Past History'
            icon={<FaInfoCircle />}
            data={[{ label: 'Past History', value: entry?.past_history }]}
          />
        </Box>

        <Box className='w-full grid grid-cols-2 gap-4 mt-1'>
          <Box>
            <Typography
              variant='subtitle2'
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
                mb: 1,
                color: '#333'
              }}
            >
              <FaInfoCircle className='text-[20px] me-[8px]' />
              Significant Signs
            </Typography>
            {entry?.significantSign?.length ? (
              <ol className='list-decimal flex items-center flex-wrap ms-10 gap-4'>
                {entry?.significantSign?.map(data => (
                  <li className='text-[#666] text-sm pb-1  whitespace-nowrap'>{data}</li>
                ))}
              </ol>
            ) : (
              <p className='text-[#666] text-sm pb-1  whitespace-nowrap ms-8'>N/A</p>
            )}
          </Box>

          <Box>
            <Typography
              variant='subtitle2'
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 'bold',
                mb: 1,
                color: '#333'
              }}
            >
              <FaInfoCircle className='text-[20px] me-[8px]' />
              Other Conditions
            </Typography>
            {entry?.otherConditions?.length ? (
              <ol className='list-decimal flex items-center flex-wrap ms-10 gap-4'>
                {entry?.otherConditions?.map(data => (
                  <li className='text-[#666] text-sm pb-1  whitespace-nowrap'>{data}</li>
                ))}
              </ol>
            ) : (
              <p className='text-[#666] text-sm pb-1  whitespace-nowrap ms-8'>N/A</p>
            )}
          </Box>
        </Box>
        <Divider sx={{ mt: 1 }} className='w-full' />

        <DataSection
          title='Encounter'
          icon={<FaHospital />}
          data={[
            { label: 'Class', value: entry?.encounterClass },
            { label: 'Priority', value: entry?.encounterPriority }
          ]}
        />
        <DataSection
          title='Immunization'
          icon={<FaSyringe />}
          data={[
            {
              label: 'Occurrence',
              value: formatDateTime(entry?.immunization?.occurrenceDateTime)
            }
          ]}
        />
        <DataSection
          title='Accident Information'
          icon={<FaInfoCircle />}
          data={[
            { label: 'Type', value: entry?.accidentType },
            { label: 'Admin Source', value: entry?.adminSource }
          ]}
        />
        <DataSection
          title='Discharge'
          icon={<FaDoorOpen />}
          data={[{ label: 'Disposition', value: entry?.dischargeDisposition }]}
        />
        <Box className='mt-1'>
          <Typography
            variant='subtitle2'
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'bold',
              mb: 1,
              color: '#333'
            }}
          >
            <VscFeedback className='text-[20px] me-[8px]' />
            {'Doctor Remarks'}
          </Typography>
          <Box>
            <Typography variant='body2' sx={{ fontWeight: 'bold', color: '#555' }}>
              Remarks:
            </Typography>
            <Typography
              variant='body2'
              sx={{ color: '#666' }}
              className='border border-gray-300 min-h-[60px] mt-1 px-3'
              dangerouslySetInnerHTML={{ __html: entry?.doctor_remarks || '' }}
            ></Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const DataSection = ({ title, icon, data }) => (
  <Box sx={{ mb: 1 }} className='mt-1'>
    <Typography
      variant='subtitle2'
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        mb: 1,
        color: '#333'
      }}
    >
      {React.cloneElement(icon, { style: { marginRight: 8, fontSize: 20 } })}
      {title}
    </Typography>
    <Grid container spacing={1}>
      {data.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Box className='flex items-center gap-2'>
            <Typography variant='body2' sx={{ fontWeight: 'bold', color: '#555' }} className='text-nowrap'>
              {item.label}:
            </Typography>
            <Typography variant='body2' sx={{ color: '#666' }}>
              {item.value || 'N/A'}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
    <Divider sx={{ mt: 1 }} />
  </Box>
);

const DataSectionTable = ({ title, icon, headers, rows }) => {
  const [isViewFile, setIsViewFile] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState(null);

  if (!rows?.length) {
    return null;
  }

  const handleDownloadFile = fileData => {
    if (!fileData || !fileData.image_value) {
      console.error('Invalid file data provided.');
      return;
    }
    // Decode the base64 data and prepare a Blob
    const byteCharacters = atob(fileData?.image_value);
    const byteNumbers = new Uint8Array(byteCharacters.length).map((_, i) => byteCharacters.charCodeAt(i));
    const blob = new Blob([byteNumbers], { type: fileData?.image_content_type });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    const fileName = fileData?.image_title || 'attachment';
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleViewFile = fileData => {
    console.log(fileData);
    setIsViewFile(true);
    setSelectedFileData(fileData);
  };

  return (
    <Box sx={{ mb: 1 }} className='mt-1'>
      <Typography
        variant='subtitle2'
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold',
          mb: 1,
          color: '#333'
        }}
      >
        {React.cloneElement(icon, { style: { marginRight: 8, fontSize: 20 } })}
        {title}
      </Typography>
      <table className='table-auto border-collapse border border-gray-200 w-full text-left text-sm'>
        <thead className='bg-gray-200'>
          <tr>
            {headers.map((header, index) => (
              <th className='!p-2 !text-sm border border-gray-300' key={index}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td className='border border-gray-300 p-1 text-sm'>{rowIndex + 1}</td>
              {row.map((cell, cellIndex) => (
                <td className='border border-gray-300 p-1 text-sm' key={cellIndex} sx={{ color: '#666' }}>
                  {typeof cell === 'object' ? (
                    <button className='text-blue-500 underline' onClick={() => handleViewFile(cell)}>
                      View
                    </button>
                  ) : (
                    cell || '--'
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Divider sx={{ mt: 1 }} />
      <div className={`${isViewFile ? 'open' : ''} add-item-modal modal relative`} id='myModal'>
        <div
          className='modal-content absolute !top-[5%] bg-white left-0 mx-auto !h-[500px] !w-[600px] overflow-y-auto !z-[9999999]'
          style={{ overflowY: 'unset' }}
        >
          <div className='flex items-center justify-between'>
            <h5 className='capitalize text-[16px] font-semibold'>{selectedFileData?.category}</h5>
            <IoMdCloseCircleOutline
              size={25}
              className='text-gray-800 cursor-pointer'
              onClick={() => {
                setSelectedFileData(null);
                setIsViewFile(false);
              }}
            />
          </div>
          <img
            src={`data:image/png;base64,${selectedFileData?.image_value}`}
            alt='img'
            className='w-full h-[400px] rounded mt-3'
          />
        </div>
      </div>
    </Box>
  );
};
const VitalSignsTable = ({ title, icon, headers, rows }) => {
  if (!rows?.length) {
    return null;
  }
  return (
    <Box sx={{ mb: 1 }} className='mt-1'>
      <Typography
        variant='subtitle2'
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold',
          mb: 1,
          color: '#333'
        }}
      >
        {React.cloneElement(icon, { style: { marginRight: 8, fontSize: 20 } })}
        {title}
      </Typography>
      <table className='table-auto border-collapse border border-gray-200 w-full text-left text-sm'>
        <thead className='bg-gray-200'>
          <tr>
            {headers.map((header, index) => (
              <th className='!p-2 !text-sm border border-gray-300' key={index}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className='border-b border-[#000]'>
            <td className='border border-gray-300 p-1 text-sm w-[150px]'>
              {rows?.find(data => data?.category === 'vital-sign-systolic')?.value || '--'}
            </td>
            <td className='border border-gray-300 p-1 text-sm w-[150px]'>
              {rows?.find(data => data?.category === 'vital-sign-diastolic')?.value || '--'}
            </td>
            <td className='border border-gray-300 p-1 text-sm w-[150px]'>
              {rows?.find(data => data?.category === 'vital-sign-height')?.value || '--'}
            </td>
            <td className='border border-gray-300 p-1 text-sm w-[150px]'>
              {rows?.find(data => data?.category === 'vital-sign-weight')?.value || '--'}
            </td>
            <td className='border border-gray-300 p-1 text-sm w-[150px]'>
              {rows?.find(data => data?.category === 'respiratory-rate')?.value || '--'}
            </td>
            <td className='border border-gray-300 p-1 text-sm w-[150px]'>
              {rows?.find(data => data?.category === 'temperature')?.value || '--'}
            </td>
            <td className='border border-gray-300 p-1 text-sm w-[150px]'>
              {rows?.find(data => data?.category === 'pulse')?.value || '--'}
            </td>
            <td className='border border-gray-300 p-1 text-sm w-[150px]'>
              {rows?.find(data => data?.category === 'SP02')?.value || '--'}
            </td>
            <td className='border border-gray-300 p-1 text-sm w-[150px]'>
              {rows?.find(data => data?.category === 'pain-score')?.value || '--'}
            </td>
            <td className='border border-gray-300 p-1 text-sm w-[150px]'>
              {rows?.find(data => data?.category === 'RBS')?.value || '--'}
            </td>
          </tr>
        </tbody>
      </table>
      <Divider sx={{ mt: 1 }} />
    </Box>
  );
};

export default EmrCard;
