import React, { useContext, useEffect, useState } from 'react';
import { appContext } from '../../context/ContextProvider';
import toast from 'react-hot-toast';
import { useQuery, useMutation } from '@apollo/client';
import { Form, Field, Formik } from 'formik';

import usersGQL from '../../gql/users';
import Spinner from '../../pages/HIS/components/Spinner';
import { Button } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { getPayerList } from '../HIS/components/ApiMethods';
import ptpa from '../../gql/ptpa';

function Login(props) {
  const { user, setUser, setToken, setPermissions } = useContext(appContext);

  const [showLoader, setShowLoader] = useState(false);

  const [loginUser, { data, loading, error }] = useMutation(usersGQL.USER_LOGIN_MUTATION, {
    update(proxy, result) {
      setShowLoader(false);
      onSuccessfulRequestResponse(result.data);
    },
    onError(error) {
      setShowLoader(false);
      onResponseError(error.graphQLErrors[0]);
    }
  });

  //parse Permissions
  const parsePermissions = data => {
    let tempData = [];
    data.map((item, index) => {
      tempData.push(item.PermissionUrl);
    });

    return tempData;
  };

  const onSuccessfulRequestResponse = async data => {
    if (data?.userSignIn?.data?.jwt) {
      let user = {
        FullName: data.userSignIn.data.full_name,
        Email: data.userSignIn.data.email,
        RoleId: data.userSignIn.data.role_id,
        RoleName: data.userSignIn.data.role_name,
        providers_id: data.userSignIn.data.providers_id,
        providers_branch_id: data.userSignIn.data.providers_branch_id,
        jwt: data.userSignIn.data.jwt,
        practitioner_id: data.userSignIn.data.his_refrence,
        business_id: data.userSignIn.data.business_id,
        business_name_en: data.userSignIn.data.business_name_en,
        business_name_ar: data.userSignIn.data.business_name_ar,
        business_logo: data.userSignIn.data.business_logo
      };
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      setToken(data.userSignIn.data.jwt);
      localStorage.setItem('token', JSON.stringify(data.userSignIn.data.jwt));
      const hisPayers = await getPayerList();
      if (hisPayers) {
        localStorage.removeItem('hisPayers');
        localStorage.setItem('hisPayers', JSON.stringify(hisPayers));
      } else {
        localStorage.setItem('hisPayers', JSON.stringify(hisPayers));
      }
      setPermissions(parsePermissions(data.userSignIn.data.permission));
      localStorage.setItem('permissions', JSON.stringify(parsePermissions(data.userSignIn.data.permission)));
    }
  };

  const onResponseError = error => {
    toast.error(`Invalid Creds - ${error.message}`);
  };
  ///

  const addData = values => {
    setShowLoader(true);
    let data = { input: { email: values.email, password: values.password } };
    loginUser({ variables: data });
  };
  const {
    data: payersData,
    loading: loadingPayers,
    error: errorPayer
  } = useQuery(ptpa.GET_ALL_PAYERS, {
    fetchPolicy: 'network-only'
  });

  // Save payers data to localStorage on app load
  useEffect(() => {
    if (payersData) {
      const payers = payersData.listPayersWithTpa.data;
      localStorage.setItem('payersData', JSON.stringify(payers));
    }
  }, [payersData]);

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validate={values => {
          const errors = {};
        }}
        onSubmit={(values, { setSubmitting }) => {
          addData(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
          /* and other goodies */
        }) => (
          <div className='w-full'>
            <div className='w-full flex flex-row'>
              <div className='login-img w-1/2 h-screen hidden lg:block  '>
                <img
                  className=' w-full h-screen'
                  // src="/images/bannerSupergit.png"
                  src='https://i.pinimg.com/originals/88/b0/bc/88b0bca05972efa8f57d793f756c61b4.jpg'
                  alt='Super Git'
                />
              </div>

              <div className='login-form h-screen w-full lg:w-1/2 flex justify-center items-center relative'>
                <div className='w-full lg:w-1/2 mx-4 lg:mx-0'>
                  <div className='mx-auto flex justify-center mb-4'>
                    <img src='/images/supergit-01.png' alt='logo' className='w-32' />
                  </div>
                  <h2 className='my-2 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900'>
                    Login To Your Account
                  </h2>

                  {/* Form */}
                  <div className='w-full'>
                    <div className='bg-white px-1 py-1 sm:rounded-lg sm:px-1'>
                      <Form className='space-y-6'>
                        <div>
                          <div className='mt-2 relative'>
                            <PersonOutlineIcon className='absolute left-3 top-1/2 transform -translate-y-1/2 !text-orange-400' />
                            <Field
                              id='email'
                              name='email'
                              type='email'
                              autoComplete='email'
                              onChange={handleChange}
                              required
                              placeHolder='Email'
                              className='pl-12 block w-full rounded-xl border-slate-300 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
                              style={{
                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
                              }}
                            />
                          </div>
                        </div>

                        <div>
                          <div className='relative'>
                            <HttpsOutlinedIcon className='absolute left-3 top-1/2 transform -translate-y-1/2 !text-orange-400' />
                            <Field
                              style={{
                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
                              }}
                              id='password'
                              name='password'
                              type='password'
                              autoComplete='current-password'
                              onChange={handleChange}
                              required
                              placeHolder='Password'
                              className='pl-12 block w-full rounded-xl border-slate-300 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6'
                            />
                          </div>
                        </div>

                        <div>
                          <Button
                            variant='contained'
                            type='submit'
                            size='small'
                            style={{
                              backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px'
                            }}
                            className='flex w-full justify-center !rounded-xl px-3 py-1.5 text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 !normal-case'
                          >
                            {showLoader ? <Spinner size='22px' customClass={'!text-white'} /> : 'Login'}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className='absolute bottom-0 right-0 m-3'>
                  <p className='bg-gray-100 py-1.5 px-3 text-gray-600 text-xs font-semibold rounded-full '>
                    Super Git v2.1.4
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}

export default Login;
