import React from 'react';
import {
    FaUser ,
  } from "react-icons/fa";
  import { MdWatchLater, MdSentimentSatisfiedAlt  } from "react-icons/md";
  import { FaUserGroup } from "react-icons/fa6";
  import { Bar, Doughnut, PolarArea, Radar, Line, Bubble } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadialLinearScale,
  PointElement
} from "chart.js";
import {
  FaUserCheck,
} from "react-icons/fa";

// Register required Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale,
    PointElement
);

const PatientSatisfaction = () => {
    const data = {
        labels: ["Label", "Label", "Label"],
        datasets: [
            {
                data: [15, 10, 27],
                backgroundColor: [
                    "rgba(96, 165, 250, 0.6)",   // Light blue with 60% opacity
                    "rgba(167, 139, 250, 0.6)",  // Light purple with 60% opacity
                    "rgba(244, 114, 182, 0.6)",  // Light pink with 60% opacity
                    "rgba(52, 211, 153, 0.6)"    // Light green with 60% opacity
                ],
                hoverBackgroundColor: [
                    "rgba(96, 165, 250, 0.8)",   // Light blue with 80% opacity on hover
                    "rgba(167, 139, 250, 0.8)",  // Light purple with 80% opacity on hover
                    "rgba(244, 114, 182, 0.8)",  // Light pink with 80% opacity on hover
                    "rgba(52, 211, 153, 0.8)"    // Light green with 80% opacity on hover
                ],
                borderWidth: 1,
            },
        ],
    };
  
    const options = {
        scales: {
            r: {
                beginAtZero: true,
            },
        },
    };


    return (
        <div className="bg-white p-2 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-1">Daily Scans Consulted</h3>
            <div className="flex flex-row justify-between items-center">
                <PolarArea data={data} options={options} />
            </div>
        </div>
    );
};



const BarChart = () => {
    const data = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
    ],
    datasets: [
        {
          label: "Outpatients",
          backgroundColor: "rgba(96, 165, 250, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(75, 192, 192, 0.8)",
          hoverBorderColor: "rgba(75, 192, 192, 1)",
          data: [650, 590, 800, 810, 560, 550, 400, 600, 650, 700, 800, 900],
        },
        {
          label: "Inpatients",
          backgroundColor: "rgba(240, 82, 108, 0.2)",
          borderColor: "rgba(153, 102, 255, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(153, 102, 255, 0.8)",
          hoverBorderColor: "rgba(153, 102, 255, 1)",
          data: [280, 480, 400, 190, 860, 270, 900, 500, 650, 700, 750, 800],
        },
      ],
    };
  
    return (
        <div className="bg-white p-2 w-full rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-1">AVG. Satisfaction by Month</h3>
            <Bar data={data} />
        </div>
    );
};



const BarChartTwo = () => {
    const data = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
    ],
    datasets: [
        {
          label: "Outpatients",
          backgroundColor: "rgba(96, 165, 250, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(75, 192, 192, 0.8)",
          hoverBorderColor: "rgba(75, 192, 192, 1)",
          data: [650, 590, 800, 810, 560, 550, 400, 600, 650, 700, 800, 900],
        },
        {
          label: "Inpatients",
          backgroundColor: "rgba(240, 82, 108, 0.2)",
          borderColor: "rgba(153, 102, 255, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(153, 102, 255, 0.8)",
          hoverBorderColor: "rgba(153, 102, 255, 1)",
          data: [280, 480, 400, 190, 860, 270, 900, 500, 650, 700, 750, 800],
        },
      ],
    };
  
    return (
        <div className="bg-white p-2 w-full rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-1">AVG. wait time by Month</h3>
            <Bar data={data} />
        </div>
    );
};



const LineChart = () => {
    const data = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
    ],
    datasets: [
        {
          label: "Outpatients",
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(75, 192, 192, 0.8)",
          hoverBorderColor: "rgba(75, 192, 192, 1)",
          data: [650, 590, 800, 810, 560, 550, 400, 600, 650, 700, 800, 900],
        },
        {
          label: "Inpatients",
          backgroundColor: "rgba(153, 102, 255, 0.6)",
          borderColor: "rgba(153, 102, 255, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(153, 102, 255, 0.8)",
          hoverBorderColor: "rgba(153, 102, 255, 1)",
          data: [280, 480, 400, 190, 860, 270, 900, 500, 650, 700, 750, 800],
        },
      ],
    };
  
    return (
        <div className="bg-white p-2 w-full rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-1">Total Patient By Time</h3>
            <Line data={data} />
        </div>
    );
};



const LineChartTwo = () => {
    const data = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
    ],
    datasets: [
        {
          label: "Outpatients",
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(75, 192, 192, 0.8)",
          hoverBorderColor: "rgba(75, 192, 192, 1)",
          data: [650, 590, 800, 810, 560, 550, 400, 600, 650, 700, 800, 900],
        },
        {
          label: "Inpatients",
          backgroundColor: "rgba(153, 102, 255, 0.6)",
          borderColor: "rgba(153, 102, 255, 1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(153, 102, 255, 0.8)",
          hoverBorderColor: "rgba(153, 102, 255, 1)",
          data: [280, 480, 400, 190, 860, 270, 900, 500, 650, 700, 750, 800],
        },
      ],
    };
  
    return (
        <div className="bg-white p-2 w-full rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-1">Total Patient By Department Referral</h3>
            <Line data={data} />
        </div>
    );
};



const ScansChart = () => {
    const data = {
        labels: ["Skill A", "Skill B", "Skill C", "Skill D", "Skill E"],
        datasets: [
            {
                label: "Team A",
                data: [65, 59, 90, 81, 56],
                fill: true,
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgba(255, 99, 132, 1)",
                pointBackgroundColor: "rgba(255, 99, 132, 1)",
            },
            {
                label: "Team B",
                data: [28, 48, 40, 19, 96],
                fill: true,
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235, 1)",
                pointBackgroundColor: "rgba(54, 162, 235, 1)",
            },
        ],
    };
  
    const options = {
        scales: {
            r: {
                angleLines: {
                    display: true,
                },
                suggestedMin: 0,
                suggestedMax: 100,
            },
        },
        plugins: {
            legend: {
                display: true,
                position: "top",
            },
        },
    };

  
  
    return (
        <div className="bg-white p-2 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-1">Daily Scans Consulted</h3>
            <div className="flex flex-row justify-between items-center">
                <Radar data={data} options={options} />
            </div>
        </div>
    );
};



const PatientsByDivision = () => (
    <div className="bg-white  p-2 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-3">Scan Types</h3>

        <div class="w-full bg-gray-200 rounded-full h-4">
            <div class="bg-blue-500 h-full rounded-full" style={{width: '75%'}}></div>
        </div>
        
        <div className='p-4'>
            <div className='d-flex justify-between border-b-[1px] border-[#ececec] pb-2 mb-2'>
                <p className='text-[25px] d-flex font-bold align-items-center'><FaUserCheck className='me-2' /> 22%</p>
                <span className='text-[25px] font-bold'>20%</span>
            </div>
            <div className='d-flex justify-between border-b-[1px] border-[#ececec] pb-2 mb-2'>
                <p className='text-[25px] d-flex font-bold align-items-center'><FaUserCheck className='me-2' /> 22%</p>
                <span className='text-[25px] font-bold'>20%</span>
            </div>
            <div className='d-flex justify-between border-b-[1px] border-[#ececec] pb-2 mb-2'>
                <p className='text-[25px] d-flex font-bold align-items-center'><FaUserCheck className='me-2' /> 22%</p>
                <span className='text-[25px] font-bold'>20%</span>
            </div>
            <div className='d-flex justify-between'>
                <p className='text-[25px] d-flex font-bold align-items-center'><FaUserCheck className='me-2' /> 22%</p>
                <span className='text-[25px] font-bold'>20%</span>
            </div>
        </div>
    </div>
);




const DaryScans = () => (
    <div className="bg-white  p-2 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-3">Scan Types</h3>

        <div class="w-full bg-gray-200 rounded-full h-4">
            <div class="bg-[#fb4b2a] h-full rounded-full" style={{width: '75%'}}></div>
        </div>
        
        <div className='p-4'>
            <div className='border-b-[1px] border-[#ececec] pb-2'>
                <p className='text-[25px] d-flex font-bold align-items-center'><FaUserCheck className='me-2' /> 22%</p>
                <p className='text-[11px]'>Lorem Ipsum is simply dummy text of the printing. </p>
            </div>
            <div className='border-b-[1px] border-[#ececec] pb-2'>
                <p className='text-[25px] d-flex font-bold align-items-center'><FaUserCheck className='me-2' /> 22%</p>
                <p className='text-[11px]'>Lorem Ipsum is simply dummy text of the printing. </p>
            </div>
            <div className='border-b-[1px] border-[#ececec] pb-2'>
                <p className='text-[25px] d-flex font-bold align-items-center'><FaUserCheck className='me-2' /> 22%</p>
                <p className='text-[11px]'>Lorem Ipsum is simply dummy text of the printing. </p>
            </div>
            <div className='border-b-[1px] border-[#ececec] pb-2'>
                <p className='text-[25px] d-flex font-bold align-items-center'><FaUserCheck className='me-2' /> 22%</p>
                <p className='text-[11px]'>Lorem Ipsum is simply dummy text of the printing. </p>
            </div>
        </div>
    </div>
);



const PositiveChart = () => {
    const data = {
        labels: ["Label", "Label", "Label"],
        datasets: [
            {
                data: [10, 9, 33],
                backgroundColor: [
                    "rgba(54, 162, 235, 0.6)",   // Soft blue with 60% opacity
                    "rgba(255, 99, 132, 0.6)",   // Soft pink with 60% opacity
                    "rgba(255, 206, 86, 0.6)"    // Soft yellow with 60% opacity
                ],
                hoverBackgroundColor: [
                    "rgba(54, 162, 235, 0.8)",   // More opaque blue on hover (80% opacity)
                    "rgba(255, 99, 132, 0.8)",   // More opaque pink on hover (80% opacity)
                    "rgba(255, 206, 86, 0.8)"    // More opaque yellow on hover (80% opacity)
                ],
                borderWidth: 1,
            },
        ],
    };
  
    const options = {
        cutout: "70%",
        plugins: {
            tooltip: {
                enabled: true,
            },
            legend: {
                display: true,
            },
            centerText: {
                display: true,
                text: `${Math.round((data.datasets[0].data.reduce((a, b) => a + b) / 100) * 100)}%`, // Show percentage based on total sum
            },
        },
    };

    // Center text plugin
    const centerTextPlugin = {
        id: "centerText",
        beforeDraw(chart) {
            const { width } = chart;
            const { height } = chart;
            const ctx = chart.ctx;
            ctx.restore();
            const fontSize = (height / 160).toFixed(2);
            ctx.font = `${fontSize}em sans-serif`;
            ctx.textBaseline = "middle";

            const text = `${Math.round(
                (data.datasets[0].data.reduce((a, b) => a + b) / 100) * 100
            )}%`;
            const textX = Math.round((width - ctx.measureText(text).width) / 2);
            const textY = height / 2;

            ctx.fillText(text, textX, textY);
            ctx.save();
        },
    };
  
    return (
        <div><Doughnut data={data} options={options} plugins={[centerTextPlugin]} /></div>
    );
};


const LineChartWithBaseline = () => {
    const data = {
        labels: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        datasets: [
            {
                label: "Dataset",
                data: [10, 20, 30, 40, 50, 5, 10], // Example data points
                borderColor: "rgba(54, 162, 235, 1)",  // Blue line
                backgroundColor: "rgba(54, 162, 235, 0.5)",  // Light blue fill for points
                pointBackgroundColor: "rgba(54, 162, 235, 1)",  // Blue color for points
                pointBorderColor: "rgba(54, 162, 235, 1)",
                tension: 0.3,  // Smooth curve
            },
            {
                label: "Baseline",
                data: [5, 5, 5, 5, 5, 5, 5], // Baseline data (constant y=5)
                borderColor: "rgba(255, 0, 0, 1)", // Red line
                borderWidth: 1,
                borderDash: [5, 5], // Dashed line style for baseline
                fill: false,
                pointRadius: 0, // No points for baseline
            },
        ],
    };

    const options = {
        // scales: {
        //     x: {
        //         type: "category",
        //         title: {
        //             display: true,
        //             text: "Months",
        //         },
        //     },
        //     y: {
        //         beginAtZero: true,
        //         title: {
        //             display: true,
        //             text: "Values",
        //         },
        //     },
        // },
        plugins: {
            legend: {
                display: true,
                position: "top",
            },
        },
    };

    return (
        <div className="bg-white p-2 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-1">Total Patients by Week Day</h3>
            <Line data={data} options={options} />
        </div>
    );
};



const BubbleChart = () => {
    const data = {
        datasets: [
            {
                label: "Male",
                data: [
                    { x: 10, y: 20, r: 15 },
                    { x: 15, y: 10, r: 10 },
                    { x: 25, y: 15, r: 20 },
                    { x: 30, y: 25, r: 25 },
                    { x: 40, y: 10, r: 10 },
                ],
                backgroundColor: "rgba(75, 192, 192, 0.6)", // Aqua color with 60% opacity
                borderColor: "rgba(75, 192, 192, 1)",      // Aqua with full opacity for border
                borderWidth: 1,
            },
            {
                label: "Female",
                data: [
                    { x: 5, y: 30, r: 15 },
                    { x: 10, y: 15, r: 10 },
                    { x: 15, y: 25, r: 20 },
                    { x: 20, y: 35, r: 25 },
                    { x: 35, y: 15, r: 10 },
                ],
                backgroundColor: "rgba(255, 159, 64, 0.6)", // Orange color with 60% opacity
                borderColor: "rgba(255, 159, 64, 1)",       // Orange with full opacity for border
                borderWidth: 1,
            },
            {
                label: "Gender",
                data: [
                    { x: 20, y: 40, r: 15 },
                    { x: 25, y: 35, r: 10 },
                    { x: 30, y: 45, r: 20 },
                    { x: 35, y: 25, r: 25 },
                    { x: 45, y: 20, r: 10 },
                ],
                backgroundColor: "rgba(153, 102, 255, 0.6)", // Purple color with 60% opacity
                borderColor: "rgba(153, 102, 255, 1)",       // Purple with full opacity for border
                borderWidth: 1,
            },
        ],
    };

    const options = {
    //     scales: {
    //         x: {
    //             beginAtZero: true,
    //             title: {
    //                 display: true,
    //                 text: "X-Axis",
    //             },
    //         },
    //         y: {
    //             beginAtZero: true,
    //             title: {
    //                 display: true,
    //                 text: "Y-Axis",
    //             },
    //         },
    //     },
        plugins: {
            legend: {
                display: true,
                position: "top",
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const { x, y, r } = context.raw;
                        return `x: ${x}, y: ${y}, size: ${r}`;
                    },
                },
            },
        },
    };

    return (
        <div className="bg-white p-2 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-1">Total Patients by Patient Gender</h3>
            <Bubble data={data} options={options} />
        </div>
    );
};





const carsdata = [
    {
      title: "Total Patients",
      count: "9k",
      icon: <FaUserGroup />,
      color: "bg-blue-300",
    },
    {
      title: "AVG. Wait Time",
      count: 35.36,
      icon: <MdWatchLater />,
      color: "bg-green-300",
    },
    {
      title: "AVG. Patient Satisfaction",
      count: 4.99,
      icon: <MdSentimentSatisfiedAlt  />,
      color: "bg-yellow-300",
    },
    {
      title: "AVG. Patient Age",
      count: 39.86,
      icon: <FaUser  />,
      color: "bg-red-300",
    },
];







function EmergencyDashboard(){
    
    return (<>
        <div className="grid gap-8">
            {/* First Row */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                {carsdata.map((item, index) => (
                <div
                    key={index}
                    className={`${item.color} p-4 rounded-sm shadow-lg text-center`}
                >
                    <div className="text-2xl font-semibold text-gray-900 mb-1 flex items-center justify-center">
                    <span className="mr-2">{item.icon}</span>
                    {item.title}
                    </div>
                    <div className="text-3xl font-bold text-gray-600">{item.count}</div>
                </div>
                ))}
            </div>

            <div className='grid grid-cols-12 gap-4'>
                <div class="col-span-5 gap-4">
                    <div className='grid gap-4'>
                        <div className='grid grid-cols-1 md:grid-cols-1 gap-4'>
                            <BarChart />
                        </div>
                    </div>
                </div>
                <div class="col-span-7">
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4 h-full'>
                        <LineChart />
                        <BarChartTwo />
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-12 gap-4'>
                <div class="col-span-5 gap-4">
                    <div className='grid gap-4 h-full'>
                        <div className='grid grid-cols-1 md:grid-cols-1 gap-4'>
                        <LineChartTwo />
                        </div>
                    </div>
                </div>
                <div class="col-span-7 gap-4 h-full">
                    <div className='grid gap-4 h-full'>
                        <div className='grid grid-cols-1 gap-4 h-full'>
                            <div className="flex flex-row justify-between items-center">
                                <div className='grid gap-4 w-full h-full'>
                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                        <BubbleChart />
                                        <LineChartWithBaseline />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}



export default EmergencyDashboard;