import React from 'react';

const ConsentForm = () => {
  return (
    <div className='bg-white rounded mx-auto max-w-[60%] p-3 border-3 border-[#774f27]'>
      <h3 className='text-center text-[#774f27] font-[600] text-[30px]'>Jeddah Obstetric Clinics</h3>
      <h5 className='text-center text-black font-[600] text-[25px] mt-3'> General Consent Form</h5>

      <form>
        <div className='border-3 !border-[#774f27] rounded-[25px] p-2 w-[500px] mt-4'>
          <div className='flex items-end gap-2'>
            <label className='consent-form-label'>Patient Name:</label>
            <input type='text' className='consent-form-input' />
          </div>
          <div className='flex items-center gap-2'>
            <div className='flex items-end gap-2'>
              <label className='consent-form-label'>Mobile No:</label>
              <input type='text' className='consent-form-input' />
            </div>
            <div className='flex items-end gap-2'>
              <label className='consent-form-label'>ID:</label>
              <input type='text' className='consent-form-input' />
            </div>
          </div>
          <div className='flex items-end gap-2'>
            <label className='consent-form-label'>Email (Optional):</label>
            <input type='email' className='consent-form-input' />
          </div>
          <div className='flex items-end gap-2'>
            <label className='consent-form-label'>Date:</label>
            <input type='date' className='consent-form-input' />
          </div>
        </div>
        <div className='border-2 border-[#774f27] py-2 flex items-center justify-center my-4'>
          <h5 className=' text-black font-[600] text-[18px]'>AUTHORIZATION AND CONSENT FOR THE PATIENT </h5>
        </div>

        <div>
          <p>
            <strong>I acknowledge that:</strong>
          </p>
          <ol className='list-decimal consent-form-list'>
            <li>
              {' '}
              It is necessary for me to give some of my personal details in the clinic for medical purposes only.
            </li>
            <li>
              JOBS clinic may release some of my personal medical information and records (if needed) to some other
              institution for further medical assisstance and treatment.
            </li>
            <li>JOBS clinic complies with data privacy policy and align to MOH rules and regulations.</li>
            <li>
              I have had the opportunities to ask any questions about the procedure/ treatment, any alternative options,
              possible outcomes, and the risk and hazards involved with the proceeding.
            </li>
            <li> I have the right to have a chaperone present during the consultation/procedure.</li>
            <li>
              I have the right to refuse to any treatment that the doctor have given me and I do understand that I need.
            </li>
            <li>
              I have been informed that my Physcian is required to have an extra- service fee of (300 sar) for saudi
              share and deductibles. Therefore, I have agreed to pay it voluntarily.
            </li>
            <li>
              I understand that JOBS clinic will inform me ahead of time for the procedure or treatrment that has not
              been.
            </li>
            <li>
              I have read, understood , and agreed to all above mentioned statements. And I have signed this consent.
            </li>
          </ol>
        </div>

        <div className='border-2 border-[#774f27] w-full grid grid-cols-3 mt-3'>
          <div className='border-r-2 border-[#774f27]'>
            <label className='text-[16px] text-black font-[600] border-b-2 border-[#774f27] p-2 w-full'>
              Patient Name/ Legal Guardian
            </label>
            <input type='text' className='consent-form-input-transparent' />
          </div>
          <div className='border-r-2 border-[#774f27]'>
            <label className='text-[16px] text-black font-[600] border-b-2 border-[#774f27] p-2 w-full'>
              Patient Signature
            </label>
            <input type='text' className='consent-form-input-transparent' />
          </div>
          <div className=''>
            <label className='text-[16px] text-black font-[600] border-b-2 border-[#774f27] p-2 w-full'>
              Date Signed
            </label>
            <input type='date' className='consent-form-input-transparent' />
          </div>
        </div>
        <div className='flex items-end gap-2 w-[50%] mt-2'>
          <label className='consent-form-label'>Interprteter Name (if needed):</label>
          <input type='text' className='consent-form-input' />
        </div>
      </form>
      <p className='text-center text-[14px] mt-4'>
        <span className='text-[#774f27] font-bold'>Address:</span> King Abdulaziz Road, South Obhur, Jeddah Saudi Arabia{' '}
        <span className='text-[#774f27] font-bold'>Email:</span>
        info@jobsclinics.com <span className='text-[#774f27] font-bold'>Mobile No:</span> 0539839091
      </p>
    </div>
  );
};

export default ConsentForm;
