import React from 'react';
import { Outlet } from 'react-router-dom';













function Radiology(){
    return (
        <Outlet />
    )
}



export default Radiology;