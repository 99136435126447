import { Tab, Tabs, Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from "@mui/material";
import { TbEditCircle } from "react-icons/tb";
import CustomPagination from "../HIS/components/CustomPagination";


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
}
  
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}
  


const tableHeaders = [
    { name: "ID #" },
    { name: "Hospital No" },
    { name: "Name" },
    { name: "Age" },
    { name: "Gender" },
    { name: "Finalized DateTime" },
    { name: "Action" },
];


const tableData = [
    {
        id: 1,
        hpNo: "HP-01959",
        name: "zain khan",
        age: "28",
        gender: "Male",
        dateTIme: "03/09/2024 - 12:00 PM ",
    },
    {
        id: 2,
        hpNo: "HP-959626",
        name: "Ali khan",
        age: "45",
        gender: "Male",
        dateTIme: "03/09/2024 - 12:00 PM ",
    },
    {
        id: 3,
        hpNo: "HP-4848",
        name: "zain khan",
        age: "28",
        gender: "Male",
        dateTIme: "03/09/2024 - 12:00 PM ",
    },
    {
        id: 4,
        hpNo: "HP-595659",
        name: "Ali khan",
        age: "45",
        gender: "Male",
        dateTIme: "03/09/2024 - 12:00 PM ",
    },
]




function FinalizedPatients(){
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return(<>
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="LAMA" {...a11yProps(0)} style={{fontWeight: '600'}} />
                    <Tab label="Transferred" {...a11yProps(1)} style={{fontWeight: '600'}} />
                    <Tab label="Discharged" {...a11yProps(2)} style={{fontWeight: '600'}} />
                    <Tab label="Admitted" {...a11yProps(3)} style={{fontWeight: '600'}} />
                    <Tab label="Death" {...a11yProps(4)} style={{fontWeight: '600'}} />
                    <Tab label="DOR" {...a11yProps(5)} style={{fontWeight: '600'}} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <div className="superGit_container !p-0">
                    <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3 align-items-center">
                        <div className="">
                            <Tooltip
                                placement="right"
                                title="Keep track of yourLAMA"
                                className="my-auto !mt-2"
                            >
                                <span className="font-semibold text-black/70 text-lg ">
                                LAMA
                                <span className="italic text-lg ml-1">
                                    (0
                                    <span className="text-xs font-bold italic">Entries</span>)
                                </span>
                                </span>
                        </Tooltip>
                        </div>
                        
                        <div className="flex gap-3 flex-col md:flex-row">
                            <label class="text-sm mt-3">Search:</label>
                            <input
                                type='text'
                                placeholder=""
                                name="search"
                                id="search"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    <div className="super-git-table !p-0 !m-0">
                    <table className="table-super-git !p-0">
                            <thead className="!p-0">
                                <tr className="!p-0">
                                {tableHeaders.map((el, index) => {
                                    return (
                                    <th
                                        className="!p-2 !text-sm !bg-[#373d45]"
                                        key={"tableHeaders_" + index}
                                    >
                                        {el.name}
                                    </th>
                                    );
                                })}
                                </tr>
                            </thead>
                            <tbody>
                    {tableData?.map((el, index) => {
                    return (<>
                        <tr className="!p-0" key={"beneficiariesData_" + index}>
                        <td className="!p-1 !text-sm">
                            {index + 1}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.hpNo}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.name}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.age}
                        </td>
                        <td className="!p-1 !text-sm">
                        {el.gender}
                        </td>
                        <td className="!p-1 !text-sm">
                        {el.dateTIme}
                        </td>

                        
                        {/* <td>{el.providers_name_en}</td> */}
                        <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1">
                            <Tooltip title="Edit">
                            <div
                                // onClick={() => onClickEdit(el)}
                                className="text-xl cursor-pointer text-orange-400"
                            >
                                <TbEditCircle />
                            </div>
                            </Tooltip>
                        </td>
                        </tr>
                        </>);
                    })}
                    </tbody>
                    </table>
                    
                        </div>
                        <div>
                    <CustomPagination
                    currentPage={1}
                    rowsPerPage={5}
                    totalRecords={0}
                    // onPageChange={onPageChange}
                    />
                    </div>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <div className="superGit_container !p-0">
                    <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3 align-items-center">
                        <div className="">
                            <Tooltip
                                placement="right"
                                title="Keep track of your Transferred"
                                className="my-auto !mt-2"
                            >
                                <span className="font-semibold text-black/70 text-lg ">
                                Transferred
                                <span className="italic text-lg ml-1">
                                    (0
                                    <span className="text-xs font-bold italic">Entries</span>)
                                </span>
                                </span>
                        </Tooltip>
                        </div>
                        
                        <div className="flex gap-3 flex-col md:flex-row">
                        <label class="text-sm mt-3">Search:</label>
                  <input
                      type='text'
                      placeholder=""
                      name="search"
                      id="search"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                        </div>
                    </div>

                    <div className="super-git-table !p-0 !m-0">
                    <table className="table-super-git !p-0">
                            <thead className="!p-0">
                                <tr className="!p-0">
                                {tableHeaders.map((el, index) => {
                                    return (
                                    <th
                                        className="!p-2 !text-sm !bg-[#373d45]"
                                        key={"tableHeaders_" + index}
                                    >
                                        {el.name}
                                    </th>
                                    );
                                })}
                                </tr>
                            </thead>
                            <tbody>
                    {tableData?.map((el, index) => {
                    return (<>
                        <tr className="!p-0" key={"beneficiariesData_" + index}>
                        <td className="!p-1 !text-sm">
                            {index + 1}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.hpNo}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.name}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.age}
                        </td>
                        <td className="!p-1 !text-sm">
                        {el.gender}
                        </td>
                        <td className="!p-1 !text-sm">
                        {el.dateTIme}
                        </td>

                        
                        {/* <td>{el.providers_name_en}</td> */}
                        <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1">
                            <Tooltip title="Edit">
                            <div
                                // onClick={() => onClickEdit(el)}
                                className="text-xl cursor-pointer text-orange-400"
                            >
                                <TbEditCircle />
                            </div>
                            </Tooltip>
                        </td>
                        </tr>
                        </>);
                    })}
                    </tbody>
                    </table>
                    
                        </div>
                        <div>
                    <CustomPagination
                    currentPage={1}
                    rowsPerPage={5}
                    totalRecords={0}
                    // onPageChange={onPageChange}
                    />
                    </div>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <div className="superGit_container !p-0">
                    <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3 align-items-center">
                        <div className="">
                            <Tooltip
                                placement="right"
                                title="Keep track of your Discharged"
                                className="my-auto !mt-2"
                            >
                                <span className="font-semibold text-black/70 text-lg ">
                                Discharged
                                <span className="italic text-lg ml-1">
                                    (0
                                    <span className="text-xs font-bold italic">Entries</span>)
                                </span>
                                </span>
                        </Tooltip>
                        </div>
                        
                        <div className="flex gap-3 flex-col md:flex-row">
                            <label class="text-sm mt-3">Search:</label>
                            <input
                                type='text'
                                placeholder=""
                                name="search"
                                id="search"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    <div className="super-git-table !p-0 !m-0">
                    <table className="table-super-git !p-0">
                            <thead className="!p-0">
                                <tr className="!p-0">
                                {tableHeaders.map((el, index) => {
                                    return (
                                    <th
                                        className="!p-2 !text-sm !bg-[#373d45]"
                                        key={"tableHeaders_" + index}
                                    >
                                        {el.name}
                                    </th>
                                    );
                                })}
                                </tr>
                            </thead>
                            <tbody>
                    {tableData?.map((el, index) => {
                    return (<>
                        <tr className="!p-0" key={"beneficiariesData_" + index}>
                        <td className="!p-1 !text-sm">
                            {index + 1}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.hpNo}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.name}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.age}
                        </td>
                        <td className="!p-1 !text-sm">
                        {el.gender}
                        </td>
                        <td className="!p-1 !text-sm">
                        {el.dateTIme}
                        </td>

                        
                        {/* <td>{el.providers_name_en}</td> */}
                        <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1">
                            <Tooltip title="Edit">
                            <div
                                // onClick={() => onClickEdit(el)}
                                className="text-xl cursor-pointer text-orange-400"
                            >
                                <TbEditCircle />
                            </div>
                            </Tooltip>
                        </td>
                        </tr>
                        </>);
                    })}
                    </tbody>
                    </table>
                    
                        </div>
                        <div>
                    <CustomPagination
                    currentPage={1}
                    rowsPerPage={5}
                    totalRecords={0}
                    // onPageChange={onPageChange}
                    />
                    </div>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
               <div className="superGit_container !p-0">
                    <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3 align-items-center">
                        <div className="">
                            <Tooltip
                                placement="right"
                                title="Keep track of your Admitted"
                                className="my-auto !mt-2"
                            >
                                <span className="font-semibold text-black/70 text-lg ">
                                Admitted
                                <span className="italic text-lg ml-1">
                                    (0
                                    <span className="text-xs font-bold italic">Entries</span>)
                                </span>
                                </span>
                        </Tooltip>
                        </div>
                        
                        <div className="flex gap-3 flex-col md:flex-row">
                            <label class="text-sm mt-3">Search:</label>
                            <input
                                type='text'
                                placeholder=""
                                name="search"
                                id="search"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    <div className="super-git-table !p-0 !m-0">
                    <table className="table-super-git !p-0">
                            <thead className="!p-0">
                                <tr className="!p-0">
                                {tableHeaders.map((el, index) => {
                                    return (
                                    <th
                                        className="!p-2 !text-sm !bg-[#373d45]"
                                        key={"tableHeaders_" + index}
                                    >
                                        {el.name}
                                    </th>
                                    );
                                })}
                                </tr>
                            </thead>
                            <tbody>
                    {tableData?.map((el, index) => {
                    return (<>
                        <tr className="!p-0" key={"beneficiariesData_" + index}>
                        <td className="!p-1 !text-sm">
                            {index + 1}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.hpNo}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.name}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.age}
                        </td>
                        <td className="!p-1 !text-sm">
                        {el.gender}
                        </td>
                        <td className="!p-1 !text-sm">
                        {el.dateTIme}
                        </td>

                        
                        {/* <td>{el.providers_name_en}</td> */}
                        <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1">
                            <Tooltip title="Edit">
                            <div
                                // onClick={() => onClickEdit(el)}
                                className="text-xl cursor-pointer text-orange-400"
                            >
                                <TbEditCircle />
                            </div>
                            </Tooltip>
                        </td>
                        </tr>
                        </>);
                    })}
                    </tbody>
                    </table>
                    
                        </div>
                        <div>
                    <CustomPagination
                    currentPage={1}
                    rowsPerPage={5}
                    totalRecords={0}
                    // onPageChange={onPageChange}
                    />
                    </div>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
                <div className="superGit_container !p-0">
                    <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3 align-items-center">
                        <div className="">
                            <Tooltip
                                placement="right"
                                title="Keep track of your Death"
                                className="my-auto !mt-2"
                            >
                                <span className="font-semibold text-black/70 text-lg ">
                                Death
                                <span className="italic text-lg ml-1">
                                    (0
                                    <span className="text-xs font-bold italic">Entries</span>)
                                </span>
                                </span>
                        </Tooltip>
                        </div>
                        
                        <div className="flex gap-3 flex-col md:flex-row">
                            <label class="text-sm mt-3">Search:</label>
                            <input
                                type='text'
                                placeholder=""
                                name="search"
                                id="search"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    <div className="super-git-table !p-0 !m-0">
                    <table className="table-super-git !p-0">
                            <thead className="!p-0">
                                <tr className="!p-0">
                                {tableHeaders.map((el, index) => {
                                    return (
                                    <th
                                        className="!p-2 !text-sm !bg-[#373d45]"
                                        key={"tableHeaders_" + index}
                                    >
                                        {el.name}
                                    </th>
                                    );
                                })}
                                </tr>
                            </thead>
                            <tbody>
                    {tableData?.map((el, index) => {
                    return (<>
                        <tr className="!p-0" key={"beneficiariesData_" + index}>
                        <td className="!p-1 !text-sm">
                            {index + 1}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.hpNo}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.name}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.age}
                        </td>
                        <td className="!p-1 !text-sm">
                        {el.gender}
                        </td>
                        <td className="!p-1 !text-sm">
                        {el.dateTIme}
                        </td>

                        
                        {/* <td>{el.providers_name_en}</td> */}
                        <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1">
                            <Tooltip title="Edit">
                            <div
                                // onClick={() => onClickEdit(el)}
                                className="text-xl cursor-pointer text-orange-400"
                            >
                                <TbEditCircle />
                            </div>
                            </Tooltip>
                        </td>
                        </tr>
                        </>);
                    })}
                    </tbody>
                    </table>
                    
                        </div>
                        <div>
                    <CustomPagination
                    currentPage={1}
                    rowsPerPage={5}
                    totalRecords={0}
                    // onPageChange={onPageChange}
                    />
                    </div>
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
                <div className="superGit_container !p-0">
                    <div className="flex justify-between flex-col lg:flex-row gap-4 mb-3 px-3 align-items-center">
                        <div className="">
                            <Tooltip
                                placement="right"
                                title="Keep track of your DOR"
                                className="my-auto !mt-2"
                            >
                                <span className="font-semibold text-black/70 text-lg ">
                                DOR
                                <span className="italic text-lg ml-1">
                                    (0
                                    <span className="text-xs font-bold italic">Entries</span>)
                                </span>
                                </span>
                        </Tooltip>
                        </div>
                        
                        <div className="flex gap-3 flex-col md:flex-row">
                            <label class="text-sm mt-3">Search:</label>
                            <input
                                type='text'
                                placeholder=""
                                name="search"
                                id="search"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    <div className="super-git-table !p-0 !m-0">
                    <table className="table-super-git !p-0">
                            <thead className="!p-0">
                                <tr className="!p-0">
                                {tableHeaders.map((el, index) => {
                                    return (
                                    <th
                                        className="!p-2 !text-sm !bg-[#373d45]"
                                        key={"tableHeaders_" + index}
                                    >
                                        {el.name}
                                    </th>
                                    );
                                })}
                                </tr>
                            </thead>
                            <tbody>
                    {tableData?.map((el, index) => {
                    return (<>
                        <tr className="!p-0" key={"beneficiariesData_" + index}>
                        <td className="!p-1 !text-sm">
                            {index + 1}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.hpNo}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.name}
                        </td>
                        <td className="!p-1 !text-sm capitalize text-center">
                        {el.age}
                        </td>
                        <td className="!p-1 !text-sm">
                        {el.gender}
                        </td>
                        <td className="!p-1 !text-sm">
                        {el.dateTIme}
                        </td>

                        
                        {/* <td>{el.providers_name_en}</td> */}
                        <td className="flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1">
                            <Tooltip title="Edit">
                            <div
                                // onClick={() => onClickEdit(el)}
                                className="text-xl cursor-pointer text-orange-400"
                            >
                                <TbEditCircle />
                            </div>
                            </Tooltip>
                        </td>
                        </tr>
                        </>);
                    })}
                    </tbody>
                    </table>
                    
                        </div>
                        <div>
                    <CustomPagination
                    currentPage={1}
                    rowsPerPage={5}
                    totalRecords={0}
                    // onPageChange={onPageChange}
                    />
                    </div>
                </div>
            </CustomTabPanel>
        </Box>
    </>)
}




export default FinalizedPatients;