import { Button, Tooltip } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo
} from 'ckeditor5';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'ckeditor5/ckeditor5.css'
import './Style/style.css'
import { CheckIcon } from '@heroicons/react/20/solid';


const validation = yup.object({
    detail: yup
      .string('Enter Details')
      .required('Details is required'),
});





function EmergencyAdd (){
    const navigate = useNavigate()

    const handleGoBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    const formik = useFormik({
        initialValues: {
            detail: '',
            summary: '',
            remarks: ''
        },
        validationSchema: validation,
        onSubmit: (values) => {
            console.log('Submitting', JSON.stringify(values));
        }
    });
    return (<>
        <div className='benefeciary-create'>
            <div class="patient-details !mb-3">
                <div class="benefeciary-top !p-0 !bg-transparent !shadow-none !border-none !mb-2">
                    <div class="back" onClick={handleGoBack}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                            d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
                            fill="#4D4D53"
                            />
                        </svg>
                    </div>
                    <div className="">
                        <Tooltip
                        placement="right"
                        title="Keep track of your Beneficiary account"
                        className="my-auto !mt-2"
                        >
                        <span className="font-semibold text-black/70 text-lg ">
                        Create Patient
                        </span>
                        </Tooltip>
                    </div>
                </div>
            </div>

            <div className='emergency-form-main'>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                    <div className='form-group'>
                        <label class="text-sm mt-3">First Name*</label>
                        <input
                            type='text'
                            placeholder="First Name"
                            name="firstName"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="firstName"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                            <p className="max-w-2xl text-sm leading-6 text-red-500">
                            {formik.errors.firstName}
                        </p>
                        ) : null}
                    </div>
                    <div className='form-group'>
                        <label class="text-sm mt-3">Middle Name</label>
                        <input
                            type='text'
                            placeholder="Middle Name"
                            name="middleName"
                            value={formik.values.middleName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="middleName"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className='form-group'>
                        <label class="text-sm mt-3">Last Name*</label>
                        <input
                            type='text'
                            placeholder="Last Name"
                            name="lastName"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="lastName"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                            <p className="max-w-2xl text-sm leading-6 text-red-500">
                            {formik.errors.lastName}
                        </p>
                        ) : null}
                    </div>
                    <div className='form-group'>
                        <label class="text-sm">Religion*</label>
                        <select 
                            name='religion'
                            value={formik.values.religion}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="religion"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                            <option value="">Select Religion</option>
                            <option value="Islam">Islam</option>
                            <option value="Hinduism">Hinduism</option>
                            <option value="Christianity">Christianity</option>
                            <option value="Other">Other</option>
                        </select>
                        {formik.touched.religion && formik.errors.religion ? (
                            <p className="max-w-2xl text-sm leading-6 text-red-500">
                            {formik.errors.religion}
                        </p>
                        ) : null}
                    </div>
                    <div className='form-group'>
                        <label class="text-sm">Gender*</label>
                        <select 
                            name='gender '
                            value={formik.values.gender}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="gender"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                        {formik.touched.gender && formik.errors.gender ? (
                            <p className="max-w-2xl text-sm leading-6 text-red-500">
                            {formik.errors.gender}
                        </p>
                        ) : null}
                    </div>
                    <div className='form-group'>
                        <label class="text-sm">DOB</label>
                        <input
                            type='date'
                            placeholder="Middle Name"
                            name="dob"
                            value={formik.values.dob}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="dob"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className='form-group'>
                        <label class="text-sm">Country</label>
                        <select 
                            name='country'
                            value={formik.values.country}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="country"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                            <option value="">Select Country</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Oman">Oman</option>
                        </select>
                    </div>
                    <div className='form-group'>
                        <label class="text-sm">County</label>
                        <select 
                            name='county'
                            value={formik.values.county}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="county"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                            <option value="">Select County</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Oman">Oman</option>
                        </select>
                    </div>
                    <div className='form-group'>
                        <label class="text-sm">Address</label>
                        <input
                            type='text'
                            placeholder="Address"
                            name="address"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="address"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className='form-group'>
                        <label class="text-sm">Case</label>
                        <select 
                            name='case'
                            value={formik.values.case}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="case"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        >
                            <option value="">Select Case</option>
                            <option value="1">General</option>
                            <option value="2">Dog Bite</option>
                            <option value="3">Snake Bite</option>
                            <option value="4">Animal Bite</option>
                            <option value="5">Emergency Labor</option>
                            <option value="6">Medico-legal</option>
                        </select>
                    </div>
                    <div className='form-group'>
                        <label class="text-sm">Contact number</label>
                        <input
                            type='text'
                            placeholder="Contact number"
                            name="contactNumber"
                            value={formik.values.contactNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="contactNumber"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className='form-group'>
                        <label class="text-sm">Referred By</label>
                        <input
                            type='text'
                            placeholder="Referred By"
                            name="referredBy"
                            value={formik.values.referredBy}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="referredBy"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className='form-group'>
                        <label class="text-sm">Condition during arrival</label>
                        <input
                            type='text'
                            placeholder="Condition during arrival"
                            name="conditionArrival"
                            value={formik.values.conditionArrival}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="conditionArrival"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className='form-group'>
                        <label class="text-sm">Mode of arrival</label>
                        <input
                            type='text'
                            placeholder="Mode of arrival"
                            name="modeArrival"
                            value={formik.values.modeArrival}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="modeArrival"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className='form-group'>
                        <label class="text-sm">Care Of Person</label>
                        <input
                            type='text'
                            placeholder="Care Of Person"
                            name="carePerson"
                            value={formik.values.carePerson}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="carePerson"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className='form-group'>
                        <label class="text-sm">Care Of Person Number</label>
                        <input
                            type='text'
                            placeholder="Care Of Person Number"
                            name="carePersonNumber"
                            value={formik.values.carePersonNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="carePersonNumber"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className='form-group'>
                        <label class="text-sm">Brought By</label>
                        <input
                            type='text'
                            placeholder="Brought By"
                            name="broughtBy"
                            value={formik.values.broughtBy}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="broughtBy"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className='form-group'>
                        <label class="text-sm">Relation With Patient</label>
                        <input
                            type='text'
                            placeholder="Relation With Patient"
                            name="relationWithPatient"
                            value={formik.values.relationWithPatient}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="relationWithPatient"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                </div>
            
                <div className='text-center mt-3'>
                    <Button
                        variant="contained"
                        onClick={formik.handleSubmit}
                        style={{
                        backgroundImage:
                            "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                        }}
                        startIcon={<CheckIcon />}
                        size="small"
                    >
                        Save Patients
                    </Button>
                </div>
            </div>
        </div>
    </>)
}




export default EmergencyAdd;