// src/components/DrugsRequisitionList.js
import React, { useState } from 'react';
import { Button, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import CustomPagination from '../HIS/components/CustomPagination';

const DrugsRequisitionList = () => {
  // Sample data
  const sampleData = [
    {
      patientName: 'Hero Khan',
      contactNo: '9955',
      date: '2024-11-10',
      status: 'pending'
    },
    {
      patientName: 'Mark Ochieng',
      contactNo: '0707194917',
      date: '2024-10-30',
      status: 'pending'
    },
    {
      patientName: 'Joy Prudence',
      contactNo: '0782563421',
      date: '2024-10-10',
      status: 'pending'
    },
    {
      patientName: 'Rodrigue Iragi Maheshe',
      contactNo: '0978334983',
      date: '2024-10-09',
      status: 'pending'
    },
    {
      patientName: 'John Makwata Wekesa',
      contactNo: '0723900205',
      date: '2024-10-04',
      status: 'pending'
    }
    // More data...
  ];

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate total pages
  const totalPages = Math.ceil(sampleData.length / itemsPerPage);

  // Get current items
  const currentItems = sampleData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // Handle pagination
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  return (
    <div className='p-2 min-h-screen'>
      <h2 className='text-2xl font-bold mb-4'>Drugs Requisition List</h2>

      {/* Search and Print Button */}
      {/* <div className='flex justify-between items-center mb-4'>
        <TextField
          variant='outlined'
          size='small'
          placeholder='Search'
          className='mr-4 w-1/5'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button size='small' variant='contained' color='primary'>
          Print
        </Button>
      </div> */}

      {/* Table */}
      <div className='super-git-table !p-0 !m-0'>
        <table className='table-super-git !p-0'>
          <thead className='!p-0'>
            <tr className='!p-0'>
              <th className='!p-2 !text-sm !bg-[#373d45]'>Patient Name</th>
              <th className='!p-2 !text-sm !bg-[#373d45]'>Contact No</th>
              <th className='!p-2 !text-sm !bg-[#373d45]'>Date</th>
              <th className='!p-2 !text-sm !bg-[#373d45]'>Status</th>
              <th className='!p-2 !text-sm !bg-[#373d45]'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {[]?.map((data, index) => (
              <tr key={index} className='!p-0'>
                <td className='!p-1 !text-sm'>{data.patientName}</td>
                <td className='!p-1 !text-sm'>{data.contactNo}</td>
                <td className='!p-1 !text-sm'>{data.date}</td>
                <td className='!p-1 !text-sm'>{data.status}</td>
                <td className='flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1'>
                  <Tooltip title='View'>
                    <div className='text-xl cursor-pointer text-green-500'>
                      <MdOutlineRemoveRedEye />
                    </div>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <CustomPagination currentPage={1} rowsPerPage={5} totalRecords={5} onPageChange={() => {}} />
    </div>
  );
};

export default DrugsRequisitionList;
