import React, { useState } from 'react';
import { Card, CardHeader, CardContent, CardActions } from '@mui/material';
import { Chip, Tooltip, IconButton, Typography } from '@mui/material';
import {
  MdOutlineRemoveRedEye,
  MdOutlineMedicalServices,
  MdOutlineLocalHospital,
  MdOutlineLocalPharmacy
} from 'react-icons/md';
import { GrPrint } from 'react-icons/gr';
import { TbEditCircle } from 'react-icons/tb';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import RadiologyReport from '../../reports/Radiology';
import Pharmacy from '../../reports/Pahrmacy';
import Treatement from '../../reports/Treatement';
import Laboratory1 from '../../reports/Laboratory1';
import './smallCard.css';
import SickLeaveReport from './SickLeaveReport';

export const formatDateTime = isoDateString => {
  const dateObj = new Date(isoDateString);
  const formattedDate = dateObj.toLocaleDateString();
  const formattedTime = dateObj.toLocaleTimeString();

  return `${formattedDate} ${formattedTime}`;
};

const SmallEmrCard = ({ emr, setData, patientID }) => {
  const getStatusSeverity = status => {
    switch (status.toLowerCase()) {
      case 'fulfilled':
        return 'success';
      case 'pending':
        return 'info';
      case 'cancelled':
        return 'error';
      case 'booked':
        return 'default';
      case 'waitlisted':
        return 'warning';
      default:
        return 'default';
    }
  };
  const renderReport = () => {
    switch (reportType) {
      case 'radiology':
        return <RadiologyReport />;
      case 'pharmacy':
        return <Pharmacy />;
      case 'treatment':
        return <Treatement />;
      case 'laboratory':
        return <Laboratory1 />;
      case 'Sick Leave':
        return <SickLeaveReport />;
      default:
        return null;
    }
  };
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [reportType, setReportType] = useState('');

  const handleOpen = type => {
    setReportType(type); // Set the report type based on which icon is clicked
    setOpen(true); // Open the modal
  };

  const handleClose = () => {
    setOpen(false); // Close the modal
    setReportType(''); // Reset the report type
  };

  // const handleEdit = (emr) => {
  //   navigate("/his/emr-edit", { state: { emr } });

  // };
  const handleEdit = emr => {
    navigate('/his/emr-edit', {
      state: { emr: emr, editPatientID: patientID }
    });
  };

  return (
    <>
      <Card className='shadow-md hover:shadow-lg transition-shadow duration-300 p-1'>
        <CardHeader
          title={
            <Typography variant='h6' component='div' sx={{ fontSize: '.8rem' }}>
              {formatDateTime(emr?.created_at)}
            </Typography>
          }
          className='bg-gray-100 p-2'
        />
        <CardContent className='text-sm'>
          <div className='flex'>
            <span className='font-semibold'>Doctor Name:</span>
            <span className='ms-8'>{emr?.careTeamsArr && emr?.careTeamsArr[0]?.practitionerName}</span>
          </div>
          <div className='flex'>
            <span className='font-semibold '>Care Team Role: </span>
            <span className='ms-3'>{emr?.careTeamsArr[0]?.care_team_role}</span>
          </div>
          <div className='flex'>
            <span className='font-semibold'>Readmission:</span>
            <span className='ms-9'>{emr?.readmission}</span>
          </div>
          <div className='flex'>
            <span className='font-semibold'>serviceType:</span>
            <span className='ms-10'>{emr?.serviceType}</span>
          </div>
        </CardContent>

        <CardActions className='flex justify-end space-x-4'>
          <div className='flex flex-col items-center'>
            <Tooltip title='View'>
              <IconButton className='!p-0'>
                <MdOutlineRemoveRedEye size={25} className='text-black' onClick={() => setData(emr)} />
              </IconButton>
            </Tooltip>
            <span className='text-xs mt-1'>View</span>
          </div>

          {/* Edit */}
          <div className='flex flex-col items-center'>
            <Tooltip title='Edit'>
              <IconButton className='!p-0'>
                <TbEditCircle size={25} className='text-black' onClick={() => handleEdit(emr)} />
              </IconButton>
            </Tooltip>
            <span className='text-xs mt-1'>Edit</span>
          </div>

          {/* Radiology Report Icon */}
          <div className='flex flex-col items-center'>
            <Tooltip title='Radiology Report'>
              <IconButton className='!p-0' onClick={() => handleOpen('radiology')}>
                <MdOutlineLocalHospital size={25} className='text-black' />
              </IconButton>
            </Tooltip>
            <span className='text-xs mt-1'>Radiology rep</span>
          </div>

          {/* Pharmacy Report Icon */}
          <div className='flex flex-col items-center'>
            <Tooltip title='Pharmacy Report'>
              <IconButton className='!p-0' onClick={() => handleOpen('pharmacy')}>
                <MdOutlineLocalPharmacy size={25} className='text-black' />
              </IconButton>
            </Tooltip>
            <span className='text-xs mt-1'>Pharmacy rep</span>
          </div>

          {/* treatement Report Icon */}
          <div className='flex flex-col items-center'>
            <Tooltip title='Treatment Report'>
              <IconButton className='!p-0' onClick={() => handleOpen('treatment')}>
                <MdOutlineMedicalServices size={25} className='text-black' />
              </IconButton>
            </Tooltip>
            <span className='text-xs mt-1'>Treatment rep</span>
          </div>

          {/* Laboratory Report Icon */}
          <div className='flex flex-col items-center'>
            <Tooltip title='Laboratory Report'>
              <IconButton className='!p-0' onClick={() => handleOpen('laboratory')}>
                <MdOutlineMedicalServices size={25} className='text-black' />
              </IconButton>
            </Tooltip>
            <span className='text-xs mt-1'>Laboratory1 rep</span>
          </div>

          {/* Sick Leave Report */}
          <div className='flex flex-col items-center'>
            <Tooltip title='Sick Leave Report'>
              <IconButton className='!p-0' onClick={() => handleOpen('Sick Leave')}>
                <GrPrint size={25} className='text-black' />
              </IconButton>
            </Tooltip>
            <span className='text-xs mt-1'>Sick Leave Report</span>
          </div>
        </CardActions>
      </Card>
      <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
        <DialogTitle className='no-print'>
          {reportType.charAt(0).toUpperCase() + reportType.slice(1)} Report
        </DialogTitle>
        <DialogContent className='printable-modal-content'>
          {reportType === 'radiology' && <RadiologyReport data={emr} />}
          {reportType === 'pharmacy' && <Pharmacy data={emr} />}
          {reportType === 'treatment' && <Treatement data={emr} />}
          {reportType === 'laboratory' && <Laboratory1 data={emr} />}
          {reportType === 'Sick Leave' && <SickLeaveReport data={emr} />}
        </DialogContent>
        <DialogActions className='no-print'>
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SmallEmrCard;
