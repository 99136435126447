import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';

import apiClient from '../../../components/formCreation/labAPiConfig';
import HomeCareFormResultsTable from './HomeCareFormResultsTable';

const ViewHomeCareFormEntries = ({ visitId, patient }) => {
  const [formsData, setFormsData] = useState(null);

  const fetchAllFormsData = useCallback(async () => {
    try {
      await apiClient
        .get(`/internal-result-upload/visit/all?item_id=${visitId}`)
        .then(response => {
          setFormsData(response.data.data);
        })
        .catch(error => {
          console.error('Error occurred:', error);
        });
    } catch (error) {
      console.error('Error', error);
      toast.error('Failed to fetch forms');
    }
  }, []);

  useEffect(() => {
    fetchAllFormsData();
  }, [fetchAllFormsData]);

  return (
    <div>
      {!formsData?.length || !formsData ? (
        <div className='flex items-center justify-center flex-col h-full'>
          <h5 className='text-lg mt-6'>No Data Found Please Create New Entries</h5>
        </div>
      ) : (
        <HomeCareFormResultsTable tableData={formsData} patient={patient} />
      )}
    </div>
  );
};

export default ViewHomeCareFormEntries;
