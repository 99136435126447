import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppLoader from '../../../components/AppLoader';
import SmallEmrCard from './SmallEmrCard';
import EmrCard from './EmrCard';
import { getMedicalByID } from './ApiMethods';
import Spinner from './Spinner';

export default function PatientMedicalHistory({ patientID }) {
  const [data, setData] = useState(null);
  const [patientMedicalHistory, setPatientMedicalHistory] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const fetchEMRS = useCallback(
    async (reset = false) => {
      if (loading || (!hasMore && !reset)) return;

      setLoading(true);
      try {
        const currentPage = reset ? 1 : page;
        if (patientID) {
          const result = await getMedicalByID(
            setLoading,
            {
              patientID: patientID,
              page: currentPage,
              perPage: 3
            },
            undefined
          );

          if (result?.data?.length) {
            setPatientMedicalHistory(prev => (reset ? result.data : [...prev, ...result.data]));
            setPage(prev => (reset ? 2 : prev + 1));
            setHasMore(result.data.length === 3);
          } else {
            setHasMore(false);
          }
        }
      } catch (error) {
        console.error('Error fetching appointments:', error);
      } finally {
        setLoading(false);
      }
    },
    [page, hasMore, patientID]
  );

  useEffect(() => {
    if (patientID) {
      fetchEMRS(true);
    }
  }, [patientID]);

  const handleScroll = () => {
    if (!containerRef.current || loading || !hasMore) return;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollHeight - scrollTop <= clientHeight + 100) {
      fetchEMRS();
    }
  };

  return (
    <div className='max-h-[650px] overflow-y-auto' ref={containerRef} onScroll={handleScroll}>
      {data ? (
        <EmrCard entry={data} setData={setData} />
      ) : (
        <div>
          <div className='grid grid-cols-1 gap-3'>
            {patientMedicalHistory &&
              patientMedicalHistory?.length > 0 &&
              patientMedicalHistory?.map((emr, index) => (
                <React.Fragment key={index}>
                  <SmallEmrCard emr={emr} setData={setData} patientID={patientID} />
                </React.Fragment>
              ))}
          </div>
          {patientMedicalHistory?.data === null && (
            <div className='flex w-full h-[40vh] items-center justify-center'>
              <h1 className='text-2xl font-bold text-center'>No Record Found</h1>
            </div>
          )}
        </div>
      )}
      {page === 1 ? (
        <AppLoader showLoader={loading} />
      ) : (
        loading &&
        !data && (
          <div className='flex items-center justify-center mt-3'>
            <Spinner />
          </div>
        )
      )}
    </div>
  );
}
