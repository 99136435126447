import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import OutPatient from "./OutPatient";
import Nephrology from "./Nephrology";
import DrugsRequisitionList from "./DrugRequistionList";
import InPatientPage from "./InPatientPaga";
import DischargeSummary from "./DischargeSummary";

export default function NursingMain() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <div className="p-2">
      {/* Tabs Header */}
      {/* <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        className="mb-4"
      >
        <Tab label="Out Patient" />
        <Tab label="In Patient" />
        <Tab label="Nephrology" />
        <Tab label="Requisition List" />
        <Tab label="Discharge Summary" />
      </Tabs> */}

      {/* Tabs Content */}
      {/* <Box>
        {selectedTab === 0 && <OutPatient />}
        {selectedTab === 1 && <InPatientPage />}
        {selectedTab === 2 && <Nephrology />}
        {selectedTab === 3 && <DrugsRequisitionList />}
        {selectedTab === 4 && <DischargeSummary />}
      </Box> */}
    </div>
  );
}
