import { FaFilter } from 'react-icons/fa';
import PatientsChart from './Charts/PatientChart';
import { useContext, useEffect, useState } from 'react';
import { getDashboardStats } from '../HIS/components/ApiMethods';
import { appContext } from '../../context/ContextProvider';
import moment from 'moment';
import AppLoader from '../../components/AppLoader';
import { Button, Drawer } from '@mui/material';
import { IoMdCloseCircle } from 'react-icons/io';

const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const { user } = useContext(appContext);
  const [Loading, setLoading] = useState(null);
  const currentDate = moment().format('YYYY-MM-DD');
  const [dashboardData, setDashboardData] = useState(null);
  const [filters, setFilters] = useState({
    start_date: currentDate,
    end_date: currentDate,
    practitioner_id: user?.practitioner_id || ''
  });

  useEffect(() => {
    getDashboardStats(setLoading, setDashboardData, filters);
  }, []);

  const handleAddFilters = () => {
    getDashboardStats(setLoading, setDashboardData, filters);
    setOpen(false);
  };

  const hanldeResetFilters = () => {
    let payload = {
      start_date: currentDate,
      end_date: currentDate,
      practitioner_id: ''
    };
    setFilters(payload);
    getDashboardStats(setLoading, setDashboardData, payload);
    setOpen(false);
  };

  return (
    <div className='grid gap-8'>
      <div className='grid-cols-1 flex items-center mt-3' onClick={() => setOpen(true)}>
        <h5 className='text-[25px] !font-semibold !text-black'>Apply Filters</h5>
        <FaFilter size={20} className='!text-primary ms-2 cursor-pointer' />
      </div>
      {/* First Row */}
      <div className='grid grid-cols-1 md:grid-cols-4 gap-4'>
        <div className={`bg-indigo-600 p-4 rounded-md shadow-lg flex items-center justify-start flex-col`}>
          <div className=' text-2xl font-semibold text-gray-900 mb-1 flex items-center justify-center'>
            Number Of In Patients
          </div>
          <div className='text-3xl font-bold text-gray-600'>{dashboardData?.data?.number_of_inpatients || 0}</div>
        </div>
        <div className={`bg-teal-500 p-4 rounded-md shadow-lg flex items-center justify-start flex-col`}>
          <div className=' text-2xl font-semibold text-gray-900 mb-1 flex items-center justify-center'>
            Number Of Out Patients
          </div>
          <div className='text-3xl font-bold text-gray-600'>{dashboardData?.data?.number_of_outpatients || 0}</div>
        </div>
        <div className={`bg-gray-500 p-4 rounded-md shadow-lg flex items-center justify-start flex-col`}>
          <div className=' text-2xl font-semibold text-gray-900 mb-1 flex items-center justify-center'>
            Total Visits
          </div>
          <div className='text-3xl font-bold text-gray-800'>{dashboardData?.data?.total_visits || 0}</div>
        </div>
        <div className={`bg-yellow-500 p-4 rounded-md shadow-lg flex items-center justify-start flex-col`}>
          <div className=' text-2xl font-semibold text-gray-900 mb-1 flex items-center justify-center'>
            Number Of Orders
          </div>
          <div className='text-3xl font-bold text-gray-600'>{dashboardData?.data?.number_of_orders || 0}</div>
        </div>
      </div>

      {/* Second Row */}
      <div className='grid grid-cols-1 gap-6'>
        <div className='h-full'>
          <PatientsChart chartData={dashboardData?.data?.visits_per_doctor || []} />
        </div>
      </div>
      <AppLoader showLoader={Loading} />
      <Drawer anchor='right' open={open} onClose={() => setOpen(false)} PaperProps={{ className: 'p-4 w-[350px]' }}>
        <div className='flex items-center justify-between'>
          <h5 className='text-[20px] font-medium'>Apply Filters</h5>
          <IoMdCloseCircle size={25} className='text-gray-500 cursor-pointer' onClick={() => setOpen(false)} />
        </div>
        <div className='grid grid-cols-1 gap-2'>
          <div className='flex flex-col mt-6 gap-3'>
            <div>
              <label className='text-base font-medium'>Start Date</label>
              <input
                id='start'
                type='date'
                className='w-full border rounded-md !p-1.5'
                value={filters?.start_date}
                onChange={e => {
                  setFilters(prevValues => ({
                    ...prevValues,
                    start_date: e.target.value
                  }));
                }}
              />
            </div>
            <div>
              <label className='text-base font-medium'>End Date</label>
              <input
                id='end'
                type='date'
                className='w-full border rounded-md !p-1.5'
                value={filters?.end_date}
                onChange={e => {
                  setFilters(prevValues => ({
                    ...prevValues,
                    end_date: e.target.value
                  }));
                }}
              />
            </div>
            <div className='mt-[20px] flex items-center gap-2'>
              <Button
                onClick={handleAddFilters}
                variant='contained'
                type='button'
                size='small'
                className='flex w-full justify-center rounded-md bg-gradient-to-r from-[#f14f3e] to-[#fab768] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 normal-case'
              >
                Submit
              </Button>
              <Button
                onClick={hanldeResetFilters}
                variant='contained'
                type='button'
                size='small'
                className='flex w-full justify-center rounded-md bg-gradient-to-r !bg-gray-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 normal-case'
              >
                Reset
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Dashboard;
