import { Button, Tooltip } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo
} from 'ckeditor5';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'ckeditor5/ckeditor5.css'
import './Style/style.css'
import { CheckIcon } from '@heroicons/react/20/solid';


const validation = yup.object({
    detail: yup
      .string('Enter Details')
      .required('Details is required'),
});





function RadiologyAdd (){
    const navigate = useNavigate()

    const handleGoBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    const formik = useFormik({
        initialValues: {
            detail: '',
            summary: '',
            remarks: ''
        },
        validationSchema: validation,
        onSubmit: (values) => {
            console.log('Submitting', JSON.stringify(values));
        }
    });
    return (<>
        <div className='benefeciary-create'>
            <div class="patient-details !mb-3">
                <div class="benefeciary-top !p-0 !bg-transparent !shadow-none !border-none !mb-2">
                    <div class="back" onClick={handleGoBack}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                            d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
                            fill="#4D4D53"
                            />
                        </svg>
                    </div>
                    <div className="">
                        <Tooltip
                        placement="right"
                        title="Keep track of your Beneficiary account"
                        className="my-auto !mt-2"
                        >
                        <span className="font-semibold text-black/70 text-lg ">
                        Create Radiology Requests
                        </span>
                        </Tooltip>
                    </div>
                </div>
            </div>

            <div style={{margin: '0 auto',maxWidth: '1000px', width: '100%'}}>
                <div class="personal-data !p-2 !mb-3" >
                    <CKEditor
                        editor={ ClassicEditor }
                        config={ {
                            toolbar: [
                            'undo', 'redo', '|',
                            'heading', '|', 'bold', 'italic', '|',
                            'link', 'insertTable', 'mediaEmbed', '|',
                            'bulletedList', 'numberedList', 'indent', 'outdent'
                            ],
                            height: '300px',
                            plugins: [
                            Bold,
                                Essentials,
                                Heading,
                                Indent,
                                IndentBlock,
                                Italic,
                                Link,
                                List,
                                MediaEmbed,
                                Paragraph,
                                Table,
                                Undo
                            ],
                            initialData: '',
                        }}
                        className="custom-editor"
                        data={formik.values.detail}
                        onChange={(event, editor) => {
                            formik.setFieldValue('detail', editor.getData());
                        }}
                    />
                    {formik.touched.detail && formik.errors.detail ? (
                        <p className="max-w-2xl text-sm leading-6 text-red-500">
                        {formik.errors.detail}
                      </p>
                    ) : null}
                    <label class="text-sm mt-3">Summary</label>
                    <textarea
                        placeholder=""
                        name="summary"
                        value={formik.values.summary}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="summary"
                        row="5"
                        className="mt-1 h-[130px] block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <label class="text-sm mt-3">Remarks</label>
                    <textarea
                        placeholder=""
                        name="remarks"
                        value={formik.values.remarks}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="remarks"
                        row="5"
                        className="mt-1 h-[130px] block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
            </div>
        </div>
        <div className='text-center'>
            <Button
                variant="contained"
                onClick={formik.handleSubmit}
                style={{
                backgroundImage:
                    "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                }}
                startIcon={<CheckIcon />}
                size="small"
            >
                Save Radiology Requests
            </Button>
        </div>
    </>)
}




export default RadiologyAdd;