import { useField, useFormikContext } from 'formik';
import React from 'react';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function SharedSearchDropdown({
  name,
  label,
  data,
  className,
  optionLabel,
  onItemAdd,
  disabled = false,
  readOnly = false,
  ...props
}) {
  const [field, meta, helpers] = useField(name);
  const { setFieldValue } = useFormikContext();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    if (readOnly || disabled) return;
    helpers.setValue(newValue ? newValue.value : '');

    if (onItemAdd && newValue) {
      onItemAdd(newValue.value);
    }
  };

  const selectedOption = data ? data.find(item => item.value === field.value) : null;

  return (
    <div className='flex gap-1 flex-col w-full'>
      <label className='text-sm flex justify-between' htmlFor={name}>
        <span> {label}</span>
        {label === 'Patient' ? (
          <IconButton
            className='!p-0.5 !text-white !bg-primary hover:text-white hover:bg-primary'
            onClick={() => navigate('/beneficiary/form')}
          >
            <Add className='!size-4' />
          </IconButton>
        ) : null}
      </label>
      <Autocomplete
        id={name}
        options={data || []}
        // className="!p-[2px] "
        getOptionLabel={option => option.label || ''}
        value={selectedOption}
        disabled={disabled}
        onChange={handleChange}
        renderInput={params => (
          <TextField
            {...params}
            variant='outlined'
            placeholder={optionLabel}
            className={`${className} !border-[#6b7280]`}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            InputProps={{
              ...params.InputProps,
              readOnly: readOnly
            }}
            {...props}
          />
        )}
        sx={{
          '& .MuiInputBase-root': {
            borderRadius: '0.375rem'
          }
        }}
      />
    </div>
  );
}
