import React, { useEffect, useState, useRef } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function FilterModal({ showFilter, setShowFilter, refetch, setPageNo, setLimit, pageNo}) {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const modalRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowFilter(false);
            }
        };
        if (showFilter) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showFilter]);

    const formik = useFormik({
        initialValues: {
            search: '',
            startDate: startDate,
            endDate: endDate
        },
        validationSchema: '',
        onSubmit: (values) => {
            console.log('Submitting', JSON.stringify(values));
        }
    });

  return (
    <div className={`${showFilter ? "open" : ""} modal`} id="myModal">
          <div ref={modalRef} className="modal-content" style={{overflowY: 'unset'}}>
            <div
              className="close"
              onClick={() => setShowFilter(() => !showFilter)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="#DE5042"
                />
              </svg>
              <div className="bene-text">Radiology Requests</div>
            </div>
            <div className="search-fields">
              <input
                placeholder="Search"
                type="text"
                name="search"
                value={formik.values.search}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="search"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    style={{width: '100%'}}
                />
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    style={{width: '100%'}}
                />
              {/* <select
                id="Type"
                name="providerId"
                onChange={handleChange}
                value={values.providerId}
                autoComplete="type-name"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option value="">Select Provider</option>
                {providers.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select> */}
            </div>

            <div
              className="search-btn"
              onClick={() => {
                formik.handleSubmit();
                setShowFilter(false);
              }}
            >
              {/* <div className="search-btn" onClick={handleSubmit}> */}
              <span>Search</span>
            </div>

            <div className="reset-btn" onClick={formik.resetForm}>
              <span>Reset</span>
            </div>
          </div>
    </div>
  );
}

export default FilterModal;
