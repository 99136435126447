import React, { useEffect, useState } from 'react';
import { getInsuranceInvoiceList, SearchCompanyInvoice, ShowInsuranceInvocieApi } from './ApiMethods';
import { formatCustomDate, formatDate } from './CashTable';
import CustomPagination from './CustomPagination';
import { useNavigate } from 'react-router-dom';
import AppLoader from '../../../components/AppLoader';
import { FaSearch } from 'react-icons/fa';
import { Tooltip } from '@mui/material';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import toast from 'react-hot-toast';

function InsuranceTable() {
  const navigate = useNavigate();
  const [invoiceList, setInvoiceList] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInsuranceInvoiceList(setLoading, setInvoiceList, {
      page: 1,
      per_page: 8
    });
  }, []);

  const tableHeaders = [
    { name: 'Invoice Number' },
    { name: 'Date' },
    { name: 'Patient Name' },
    // { name: 'Patient Paid Amount' },
    { name: 'Company Amount' },
    { name: 'Company Vat' },
    { name: 'Company Paid Amount' },
    { name: 'File Number' },
    { name: 'Nationality' },
    { name: 'Description' },
    { name: 'Actions' }
  ];

  const onPageChange = (newPage, newRowsPerPage) => {
    getInsuranceInvoiceList(setLoading, setInvoiceList, {
      page: newPage,
      per_page: newRowsPerPage
    });
  };

  const handleSearch = async () => {
    if (searchValue?.trim()) {
      const data = await SearchCompanyInvoice({
        setLoading: setLoading,
        query: searchValue.trim()
      });
      if (data?.data && data?.data.length > 0) {
        setInvoiceList(data);
      } else {
        setInvoiceList([]);
        toast.error('No invoices found for the given search results.');
      }
    } else {
      getInsuranceInvoiceList(setLoading, setInvoiceList, {
        page: 1,
        per_page: 8
      });
    }
  };

  const handelShowClick = data => {
    ShowInsuranceInvocieApi(setLoading, data?.id).then(response => {
      if (response?.status === 200) {
        navigate('/receptionist/show-invoice', {
          state: { data: response?.data }
        });
      }
    });
  };

  return (
    <>
      <div className='flex justify-end mb-2'>
        <input
          type='text'
          placeholder='Search Invoice by Invoice Number'
          className='block mr-2 w-[25%] rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
          onChange={e => {
            setSearchValue(e.target.value);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
        <span className='!bg-primary !text-white rounded-md p-2.5' onClick={() => handleSearch()}>
          <FaSearch />
        </span>
      </div>
      <div className='overflow-x-auto'>
        <div className='super-git-table !p-0 !m-0'>
          <table className='table-super-git !p-0'>
            <thead className='!p-0'>
              <tr className='!p-0'>
                {tableHeaders.map((el, index) => (
                  <th className='!p-2 !text-sm !bg-[#373d45]' key={'tableHeaders_' + index}>
                    {el.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {invoiceList?.data?.length > 0 ? (
                invoiceList.data.map((el, index) => (
                  <tr key={'invoiceData_' + index} className='!p-0'>
                    <td className='!p-1 !text-sm'>{el?.invoice_number}</td>
                    <td className='!p-1 !text-sm'>{formatDate(el?.date)}</td>
                    <td className='!p-1 !text-sm capitalize text-start'>{el?.customer_name}</td>
                    {/* <td className='!p-1 !text-sm'>{el?.patient_share}</td> */}
                    <td className='!p-1 !text-sm'>
                      {el?.sub_total_without_tax % 1 !== 0
                        ? el.sub_total_without_tax.toFixed(2)
                        : el?.sub_total_without_tax}
                    </td>
                    <td className='!p-1 !text-sm'>
                      {el?.total_vat % 1 !== 0 ? el.total_vat.toFixed(2) : el?.total_vat}
                    </td>
                    <td className='!p-1 !text-sm'>
                      {el?.sub_total_with_tax % 1 !== 0 ? el.sub_total_with_tax.toFixed(2) : el?.sub_total_with_tax}
                    </td>
                    <td className='!p-1 !text-sm'>{el?.file_number}</td>
                    <td className='!p-1 !text-sm'>{el?.nationality}</td>
                    <td className='!p-1 !text-sm'>{el?.description}</td>

                    <td className='flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1'>
                      <Tooltip title='View'>
                        <div onClick={() => handelShowClick(el)} className='text-xl cursor-pointer text-green-500'>
                          <MdOutlineRemoveRedEye />
                        </div>
                      </Tooltip>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={tableHeaders.length} className='px-4 py-2 text-center text-sm text-gray-700'>
                    No invoices found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <CustomPagination
          currentPage={invoiceList?.page}
          rowsPerPage={invoiceList?.per_page}
          totalRecords={invoiceList?.total_record}
          onPageChange={onPageChange}
        />
        <AppLoader setShowLoader={setLoading} showLoader={loading} />
      </div>
    </>
  );
}

export default InsuranceTable;
