import React, { useEffect, useState } from 'react';
import { getCashInvoiceList, SearchInvoice, ShowInvocieApi } from './ApiMethods';
import '../../Beneficiary/styles/BeneficiaryList.css';
import CustomPagination from './CustomPagination';
import { useNavigate } from 'react-router-dom';
import AppLoader from '../../../components/AppLoader';
import { FaSearch } from 'react-icons/fa';
import { Tooltip } from '@mui/material';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import toast from 'react-hot-toast';

export function formatCustomDate(isoDate) {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
export const formatDate = dateString => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = String(hours % 12 || 12).padStart(2, '0');

  return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
};

export default function CashTable() {
  const navigate = useNavigate();
  const [invoiceList, setInvoiceList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getCashInvoiceList(setLoading, setInvoiceList, { page: 1, per_page: 8 });
  }, []);
  const tableHeaders = [
    { name: 'Invoice Number' },
    { name: 'Date' },
    { name: 'Patient Name' },
    { name: 'Patient Amount' },
    { name: 'Patient VAT' },
    { name: 'Patient Paid Amount' },
    { name: 'File Number' },
    { name: 'Nationality' },
    { name: 'Description' },
    { name: 'Actions' }
  ];
  const onPageChange = (newPage, newRowsPerPage) => {
    getCashInvoiceList(setLoading, setInvoiceList, {
      page: newPage,
      per_page: newRowsPerPage
    });
  };

  const handleSearch = async () => {
    if (!searchValue?.trim()) {
      getCashInvoiceList(setLoading, setInvoiceList, { page: 1, per_page: 8 });
    } else {
      const data = await SearchInvoice({
        setLoading: setLoading,
        query: searchValue.trim()
      });
      if (data?.data && data?.data.length > 0) {
        setInvoiceList(data);
      } else {
        setInvoiceList([]);
        toast.error('No invoices found for the given search results.');
      }
    }
  };

  const handelShowClick = data => {
    ShowInvocieApi(setLoading, data?.id).then(response => {
      if (response?.status === 200) {
        navigate('/receptionist/show-invoice', {
          state: { data: response?.data }
        });
      }
    });
  };

  return (
    <>
      <div className='flex justify-end mb-2'>
        <input
          type='text'
          placeholder='Search Invoice by Invoice Number'
          className='block mr-2 w-[25%] rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6'
          onChange={e => {
            setSearchValue(e.target.value);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
        <span className='!bg-primary !text-white rounded-md p-2.5' onClick={() => handleSearch()}>
          <FaSearch />
        </span>
      </div>
      <div className='overflow-x-auto'>
        <div className='super-git-table !p-0 !m-0'>
          <table className='table-super-git !p-0'>
            <thead className='!p-0'>
              <tr className='!p-0'>
                {tableHeaders.map((el, index) => {
                  return (
                    <th className='!p-2 !text-sm !bg-[#373d45]' key={'tableHeaders_' + index}>
                      {el.name}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {invoiceList?.data?.length > 0 ? (
                invoiceList.data.map((el, index) => (
                  <tr key={'invoiceData_' + index} className='!p-0'>
                    <td className='!p-1 !text-sm'>{el?.invoice_number}</td>
                    <td className='!p-1 !text-sm'>{formatDate(el?.date)}</td>
                    <td className='!p-1 !text-sm capitalize text-start'>{el?.customer_name}</td>
                    <td className='!p-1 !text-sm'>
                      {el?.patient_share % 1 !== 0 ? el.patient_share.toFixed(2) : el?.patient_share}
                    </td>
                    <td className='!p-1 !text-sm'>
                      {el?.patient_tax % 1 !== 0 ? el.patient_tax.toFixed(2) : el?.patient_tax}
                    </td>
                    <td className='!p-1 !text-sm'>{el?.amount % 1 !== 0 ? el.amount.toFixed(2) : el?.amount}</td>
                    <td className='!p-1 !text-sm'>{el?.file_number}</td>
                    <td className='!p-1 !text-sm'>{el?.nationality}</td>
                    <td className='!p-1 !text-sm'>{el?.description}</td>
                    <td className='flex gap-2 justify-center items-center mx-2 my-auto !mt-2 !p-1'>
                      <Tooltip title='View'>
                        <div onClick={() => handelShowClick(el)} className='text-xl cursor-pointer text-green-500'>
                          <MdOutlineRemoveRedEye />
                        </div>
                      </Tooltip>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={tableHeaders.length} className='px-4 py-2 text-center text-sm text-gray-700'>
                    No invoices found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <CustomPagination
          currentPage={invoiceList?.page || 1}
          rowsPerPage={invoiceList?.per_page || 5}
          totalRecords={invoiceList?.total_record || 0}
          onPageChange={onPageChange}
        />
        <AppLoader showLoader={loading} setShowLoader={setLoading} />
      </div>
    </>
  );
}

//  {/* <table className="min-w-full bg-white border-collapse">
//     <thead className="bg-gray-200">
//       <tr>
//         {tableHeaders.map((el, index) => (
//           <th
//             className="px-4 py-2 text-left text-sm font-medium text-gray-900"
//             key={"tableHeaders_" + index}
//           >
//             {el.name}
//           </th>
//         ))}
//       </tr>
//     </thead>
//     <tbody>
//       {invoiceList?.data?.length > 0 ? (
//         invoiceList.data.map((el, index) => (
//           <tr key={"invoiceData_" + index} className="bg-white border-b">
//             <td className="px-4 py-2 text-sm text-gray-700">
//               {el?.invoice_number}
//             </td>
//             <td className="px-4 py-2 text-sm text-gray-700">
//               {el?.patient?.name}
//             </td>
//             <td className="px-4 py-2 text-sm text-gray-700">
//               {el?.patient_share}
//             </td>
//             <td className="px-4 py-2 text-sm text-gray-700">
//               {el?.patient_tax}
//             </td>
//             <td className="px-4 py-2 text-sm text-gray-700">
//               {el?.amount}
//             </td>
//             <td className="px-4 py-2 text-sm text-gray-700">
//               {el?.patient?.file_number}
//             </td>
//             <td className="px-4 py-2 text-sm text-gray-700">
//               {el?.patient?.nationality}
//             </td>
//             <td className="px-4 py-2 text-sm text-gray-700">
//               {el?.description}
//             </td>
//             <td className="px-4 py-2 text-sm text-gray-700">
//               {formatDate(el?.date)}
//             </td>
//             <td className="px-4 py-2 text-sm text-gray-700">
//               <span
//                 className="cursor-pointer text-blue-600"
//                 onClick={() => handelShowClick(el)}
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="24"
//                   height="24"
//                   viewBox="0 0 24 24"
//                   fill="none"
//                 >
//                   <path
//                     d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.79 17.5 12 17.5C8.21 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
//                     fill="#4D4D53"
//                   />
//                 </svg>
//               </span>
//             </td>
//           </tr>
//         ))
//       ) : (
//         <tr>
//           <td
//             colSpan={tableHeaders.length}
//             className="px-4 py-2 text-center text-sm text-gray-700"
//           >
//             No invoices found.
//           </td>
//         </tr>
//       )}
//     </tbody>
//   </table> */}
