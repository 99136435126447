import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import _, { set } from 'lodash';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import physicians from '../../gql/physicians';
import AppModal from '../../components/AppModal';
import { useNavigate } from 'react-router-dom';
import { createPractitionerHIS, updateDoctor } from '../HIS/components/ApiMethods';

function AddPhysicianDetailForm({ item }) {
  const navigate = useNavigate();
  const [loadings, setLoading] = useState(false);
  const roles = [
    { id: 'Doctor', name: 'Doctor' },
    { id: 'Nurse', name: 'Nurse' },
    { id: 'Pharmist', name: 'Pharmist' },
    { id: 'Researcher', name: 'Researcher' },
    { id: 'Teacher/Educatoe', name: 'Teacher/Educatoe' }
  ];

  const { loading: loadingSpeciality, data: dataSpeciality } = useQuery(physicians.GET_PHYSICIAN_SPECIALITY, {
    fetchPolicy: 'network-only'
  });

  const onSuccessfulRequestResponse = data => {
    if (data.registerPhysician) {
      toast.success(data.registerPhysician.message);
      formik.resetForm();
    } else {
    }
  };

  const onResponseError = error => {
    toast.error(`${error?.message}`);
  };

  const [createPhysician, { data, loading, error }] = useMutation(physicians.CREATE_PHYSICIAN, {
    update(proxy, result) {
      onSuccessfulRequestResponse(result.data);
    },
    onError(error) {
      onResponseError(error.graphQLErrors[0]);
    }
  });

  const [updatePhysician, { updatedData, updatedLoading, updatedError }] = useMutation(physicians.UPDATE_PHYSICIAN, {
    update(proxy, result) {
      onSuccessfulEditResponse(result.data);
    },
    onError(error) {
      onResponseError(error.graphQLErrors[0]);
    }
  });

  const onSuccessfulEditResponse = data => {
    if (data?.updatePhysician) {
      toast.success(data?.updatePhysician?.message);
      navigate('/physicians');
    } else {
    }
  };

  useEffect(() => {
    if (item) {
      formik.setValues({
        name: item?.name || '',
        document_id: item?.document_id || '',
        license: item?.license || '',
        role: item?.role || '',
        speciality: item?.speciality || '',
        speciality_item_index: item?.speciality_item_index || 0,
        code: item?.code || '',
        id: item?.id || ''
      });
    }
  }, [item]);

  const formik = useFormik({
    initialValues: {
      name: '',
      document_id: '',
      license: '',
      role: '',
      speciality: '',
      speciality_item_index: 0,
      code: ''
    },

    validationSchema: () => {
      return Yup.object().shape({
        name: Yup.string().required('Name is required'),
        license: Yup.string().required('License Number is required'),
        // document_id: Yup.string().required("Document ID is required"),
        role: Yup.string().required('Role is required'),
        speciality: Yup.string().required('Speciality is required')
      });
    },

    onSubmit: values => {
      if (item) {
        updateDoctor(setLoading, {
          practitioner_id: item?.practitioner_id,
          ...values
        }).then(res => {
          updatePhysician({ variables: { input: values } });
        });
      } else {
        createPhysician({ variables: { input: values } }).then(res => {
          let payload = {
            rcm_ref: res?.data?.registerPhysician?.data?.id ?? '',
            ...values
          };
          createPractitionerHIS(payload);
        });
      }
    }
  });

  return (
    <>
      <div class='personal-data'>
        <div class='personal-title'>
          <div class='inventory-svg'>
            <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <rect width='34' height='34' rx='8' fill='#F4F6F8' />
              <path
                d='M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z'
                fill='#F5793B'
              />
              <path d='M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z' fill='#F5793B' />
            </svg>
          </div>
          <div class='personal-head'>
            <span>Physician Data</span>
          </div>
        </div>
        <div class='personal-fields grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-3'>
          <div className='flex flex-col gap-1'>
            <label htmlFor='name'>Name*</label>
            <input
              placeholder='Enter Name'
              type='text'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='name'
              autoComplete='name'
              className='w-full'
            />
            {formik.errors.name && formik.touched.name ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.name}</p>
            ) : null}
          </div>

          <div className='flex flex-col gap-1'>
            <label htmlFor='license'>License Number*</label>
            <input
              placeholder='Enter License Number*'
              type='text'
              name='license'
              value={formik.values.license}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='license'
              autoComplete='license'
              className='w-full'
            />
            {formik.errors.license && formik.touched.license ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.license}</p>
            ) : null}
          </div>

          <div className='flex flex-col gap-1'>
            <label htmlFor='document_id'>Document ID</label>
            <input
              placeholder='Enter Document ID'
              name='document_id'
              value={formik.values.document_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id='document_id'
              autoComplete='document_id'
              className='w-full'
            />
            {formik.errors.document_id && formik.touched.document_id ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.document_id}</p>
            ) : null}
          </div>

          <div className='flex flex-col gap-1'>
            <label htmlFor='speciality'>Speciality*</label>
            <select
              id='speciality'
              name='speciality'
              onChange={async e => {
                if (e.target.value) {
                  const val = await dataSpeciality?.getPhysicianSpeciality?.data.find(sp => sp.id == e.target.value);

                  if (val) {
                    formik.setFieldValue('speciality', val?.display || '');
                    formik.setFieldValue('speciality_item_index', val?.id || '');
                    formik.setFieldValue('code', val?.code || '');
                  }
                }
              }}
              value={formik.values.speciality_item_index}
              autoComplete='speciality'
            >
              <option value={''}>Select Speciality</option>
              {dataSpeciality?.getPhysicianSpeciality?.data.map(item => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.display}
                  </option>
                );
              })}
            </select>
            {formik.errors.speciality && formik.touched.speciality ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.speciality}</p>
            ) : null}
          </div>

          <div className='flex flex-col gap-1'>
            <label htmlFor='role'>Role*</label>
            <select id='role' name='role' onChange={formik.handleChange} value={formik.values.role} autoComplete='role'>
              <option value={''}>Select Role</option>
              {roles.map(item => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {formik.errors.role && formik.touched.role ? (
              <p className='max-w-2xl text-sm leading-6 text-red-500'>{formik.errors.role}</p>
            ) : null}
          </div>
        </div>
      </div>

      <div class='save-benefeciary-btn' onClick={formik.handleSubmit}>
        <span>{item ? 'Update' : 'Save'} Physician</span>
      </div>

      <AppModal isOpen={loading || loadingSpeciality} />
    </>
  );
}

export default AddPhysicianDetailForm;
