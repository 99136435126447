import React, { useEffect, useRef, useState } from "react";
import { Tooltip, Paper, Typography, Modal, Box, Button } from "@mui/material";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaMale, FaFemale } from "react-icons/fa";
import JsBarcode from "jsbarcode";
import {
  AccessTime,
  CheckCircle,
  LocalShipping,
  DoneAll,
  NoteAdd,
  Diversity1,
} from "@mui/icons-material";

const LabMain = () => {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const reportDate = new Date().toLocaleString();

  // Define all possible statuses with their corresponding icons
  const statuses = [
    { label: "Create", icon: <NoteAdd /> },
    { label: "Collect Sample", icon: <AccessTime /> },
    { label: "Receive Sample", icon: <CheckCircle /> },
    { label: "Result Entry", icon: <DoneAll /> },
  ];

  const rows = [
    {
      id: 1,
      date: reportDate,
      patient: {
        name: "John Doe",
        age: 45,
        gender: "male",
        mrn: "12345",
        transId: "1234",
      },
      doctor: { name: "Dr. Smith", specialty: "Cardiology" },
      account: "Cash",
      status: "Receive Sample",
    },
    {
      id: 2,
      date: reportDate,
      patient: {
        name: "Jane Smith",
        age: 34,
        gender: "female",
        mrn: "67890",
        transId: "5678",
      },
      doctor: { name: "Dr. Williams", specialty: "Neurology" },
      account: "Insurance",
      status: "Result Entry",
    },
    {
      id: 3,
      date: reportDate,
      patient: {
        name: "Emily Clark",
        age: 29,
        gender: "female",
        mrn: "54321",
        transId: "9876",
      },
      doctor: { name: "Dr. Brown", specialty: "Oncology" },
      account: "Cash",
      status: "Create",
    },
  ];

  const handleViewClick = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const trackingTimeline = [
    {
      label: "Transaction Initiated",
      icon: <NoteAdd />,
      date: "2024-09-13 8:49 AM",
      by: "KHADIJAH ALABADI",
      id: "208920",
    },
    {
      label: "Collect Sample",
      icon: <AccessTime />,
      date: "2024-09-13 9:03 AM",
      by: "User",
    },
    {
      label: "Receive Sample",
      icon: <CheckCircle />,
      date: "2024-09-13 9:03 AM",
      by: "User",
    },
    {
      label: "Result Entry",
      icon: <DoneAll />,
      date: "2024-09-13 9:05 AM",
      by: "User",
      from: "Receive",
    },
    {
      label: "Complete",
      icon: <LocalShipping />,
      date: "2024-09-13 9:09 AM",
      by: "User",
      from: "Result Entry",
    },
  ];

  const barcodeRef = useRef(null);
  const barcodeValue = "173295234"; // Replace this with the actual value

  useEffect(() => {
    if (barcodeRef.current) {
      JsBarcode(barcodeRef.current, barcodeValue, {
        format: "CODE128", // The barcode format
        displayValue: true, // Show the value below the barcode
        fontSize: 16,
        height: 50, // Adjust height based on the design
      });
    }
  }, [barcodeValue]);

  const handlePrint = () => {
    const canvas = barcodeRef.current;
    if (canvas) {
      // Convert the canvas to an image
      const imgData = canvas.toDataURL("image/png");

      // Open a new window for printing
      const printWindow = window.open("", "", "width=500,height=600");

      // Write the HTML content for the print window
      printWindow.document.write(
        `<html>
          <head>
            <title>Print Barcode</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                font-size: 18px
              }
              .info {
                margin-top: 10px;
              }
              .barcode {
                margin-top: 10px;
              }
              .barcode img{
                width: 100%;
              }
              .d1 {
                display: flex;
                justify-content: space-between
              }
              .name{
                align-text: center
              }
            </style>
          </head>
          <body>
            <div>
              <p class="name"><strong>Jane Smith</strong></p>
             <div class="d1">
                <p><strong>147836</strong></p>
                <p><strong>Age:</strong> 34</p>
                <p><strong>Male</strong></p>
             </div>
            </div>
            <div class="barcode">
              <img src="${imgData}" alt="Barcode" style="max-width: 100%;" />
            </div>
            <h3>Complete Blood Count</h3>
            <p><strong>SMP ID:</strong> 13-08-2024 08:49:00</p>
            <p><strong>Labikumb:</strong> 541</p> 
          </body>
        </html>`
      );

      // Close the document and trigger the print dialog
      printWindow.document.close();
      printWindow.onload = () => {
        printWindow.print();
      };
    }
  };

  return (
    <div className="p-2">
      {/* Lab Report Section */}
      <Paper className="p-2 mb-2">
        <div className="text-base font-bold">Lab Report</div>
        <div className="text-sm font-semibold">Date and Time: {reportDate}</div>
      </Paper>

      {/* Simple Table for Lab Data */}
      <Paper className="mb-6 overflow-auto">
        <table className="w-full border border-gray-300 text-left">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-1 border-b">Date and Time</th>
              <th className="p-1 border-b">Patient</th>
              <th className="p-1 border-b">Doctor</th>
              <th className="p-1 border-b">Account</th>
              <th className="p-1 border-b">Action</th>
              <th className="p-1 border-b">Status</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id} className="hover:bg-gray-50">
                <td className="p-1 border-b">{row.date}</td>
                <td className="p-1 border-b">
                  <div className="flex justify-between pr-2">
                    <div className="font-bold">{row.patient.name}</div>
                    <div className="flex gap-2">
                      <span className="mt-1">
                        {row.patient.gender === "male" ? (
                          <FaMale />
                        ) : (
                          <FaFemale />
                        )}
                      </span>
                      Age: {row.patient.age}
                    </div>
                  </div>
                  <div className="text-xs text-gray-600">
                    MRN: {row.patient.mrn} | Trans ID: {row.patient.transId}
                  </div>
                </td>
                <td className="p-1 border-b">
                  <div className="font-bold">{row.doctor.name}</div>
                  <div className="text-xs text-gray-600">
                    {row.doctor.specialty}
                  </div>
                </td>
                <td className="p-1 border-b">{row.account}</td>
                <td className="p-1 border-b">
                  <Tooltip title="View">
                    <div
                      className="text-xl cursor-pointer"
                      onClick={() => handleViewClick(row)}
                    >
                      <MdOutlineRemoveRedEye />
                    </div>
                  </Tooltip>
                </td>
                <td className="p-1 border-b flex gap-2">
                  {statuses.map((status, index) => (
                    <span
                      key={status.label}
                      className={`text-2xl ${
                        index <=
                        statuses.findIndex((s) => s.label === row.status)
                          ? "text-blue-500"
                          : "text-gray-300"
                      }`}
                    >
                      {status.icon}
                    </span>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Paper>

      {/* Modal for Tracking Timeline */}
      <Modal open={open} onClose={handleClose}>
        <Box className="p-6 bg-white rounded-md shadow-lg max-w-lg mx-auto mt-24 space-y-4">
          <Typography variant="h6">Tracking Timeline</Typography>
          {trackingTimeline.map((step, index) => (
            <div key={index} className="flex items-start mb-2">
              <div className="mr-4 text-blue-500">{step.icon}</div>
              <div>
                <Typography variant="body1" className="font-semibold">
                  {step.label} at {step.date}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  By: {step.by} {step.id && ` [ID: ${step.id}]`}
                  {step.from && ` [From: ${step.from}]`}
                </Typography>
              </div>
            </div>
          ))}

          <div id="barcode-section" className="text-center">
            <canvas ref={barcodeRef}></canvas>
          </div>

          {/* Print Button */}
          <Button variant="contained" color="primary" onClick={handlePrint}>
            Print Barcode
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default LabMain;
