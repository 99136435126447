import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import WarningIcon from '@mui/icons-material/Warning';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoIcon from '@mui/icons-material/Info';
import ScienceIcon from '@mui/icons-material/Science';
import EventNoteIcon from '@mui/icons-material/EventNote';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AirIcon from '@mui/icons-material/Air';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import HeightIcon from '@mui/icons-material/Height';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'; // For vital-sign-systolic
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; // For vital-sign-diastolic
import EventIcon from '@mui/icons-material/Event'; // For last-menstrual-period

const supportingInfoElements = [
  {
    label: 'Chief Complaint',
    id: 1,
    name: 'Chief Complaint',
    icon: <WarningIcon />,
    object: {
      category: 'chief-complaint',
      codeText: '',
      code: '',
      valueUnit: '',
      value: ''
    }
  },
  {
    label: 'Attachment',
    id: 2,
    name: 'Attachment',
    icon: <AttachFileIcon />,
    object: {
      category: 'attachment',
      image_value: '',
      image_title: '',
      image_content_type: ''
    }
  },
  {
    label: 'Birth Weight',
    id: 3,
    name: 'Birth Weight',
    icon: <ChildFriendlyIcon />,
    object: {
      category: 'birth-weight',
      value: '',
      valueUnit: 'kg'
    }
  },
  {
    label: 'Days Supply',
    id: 4,
    name: 'Days Supply',
    icon: <CalendarTodayIcon />,
    object: {
      category: 'days-supply',
      valueUnit: 'd',
      value: ''
    }
  },
  {
    label: 'Employment Impacted',
    id: 5,
    name: 'Employment Impacted',
    icon: <WorkOffIcon />,
    object: {
      category: 'employment-impacted',
      startDate: '',
      endDate: ''
    }
  },
  {
    label: 'Hospitalized',
    id: 6,
    name: 'Hospitalized',
    icon: <LocalHospitalIcon />,
    object: {
      category: 'hospitalized',
      startDate: '',
      endDate: ''
    }
  },
  {
    label: 'ICU Hours',
    id: 7,
    name: 'ICU Hours',
    icon: <AccessTimeIcon />,
    object: {
      category: 'icu-hours',
      startDate: '',
      endDate: '',
      value: '',
      valueUnit: 'h'
    }
  },
  {
    label: 'Info',
    id: 8,
    name: 'Info',
    icon: <InfoIcon />,
    object: {
      category: 'info',
      value: '',
      valueUnit: ''
    }
  },
  {
    label: 'Lab Test',
    id: 9,
    name: 'Lab Test',
    icon: <ScienceIcon />,
    object: {
      category: 'lab-test',
      startDate: '',
      endDate: '',
      value: '',
      valueUnit: '',
      codeText: '',
      code: ''
    }
  },
  {
    label: 'Last Menstrual Period',
    id: 10,
    name: 'Last Menstrual Period',
    icon: <EventIcon />,
    object: {
      category: 'last-menstrual-period',
      startDate: ''
    }
  },
  {
    label: 'Missing Tooth',
    id: 11,
    name: 'Missing Tooth',
    icon: <SentimentVeryDissatisfiedIcon />,
    object: {
      category: 'missingtooth',
      startDate: '',
      codeText: '',
      code: '',
      reason: ''
    }
  },
  {
    label: 'Onset',
    id: 12,
    name: 'Onset',
    icon: <PlayArrowIcon />,
    object: {
      category: 'onset',
      startDate: '',
      codeText: '',
      code: ''
    }
  },
  {
    label: 'Oxygen Saturation',
    id: 13,
    name: 'Oxygen Saturation',
    icon: <FavoriteIcon />,
    object: {
      category: 'oxygen-saturation',
      startDate: '',
      endDate: '',
      value: '',
      valueUnit: '%'
    }
  },
  {
    label: 'Reason For Visit',
    id: 15,
    name: 'Reason For Visit',
    icon: <EventNoteIcon />,
    object: {
      category: 'reason-for-visit',
      codeText: '',
      code: ''
    }
  },
  {
    label: 'Ventilation Hours',
    id: 18,
    name: 'Ventilation Hours',
    icon: <AirIcon />,
    object: {
      category: 'ventilation-hours',
      startDate: '',
      endDate: '',
      value: '',
      valueUnit: 'h'
    }
  },

  {
    id: 23,
    name: 'History of Present Illness',
    label: 'History of Present Illness',
    icon: <InfoIcon />,
    object: {
      category: 'history-of-present-illness',
      value: null,
      valueUnit: ''
    }
  },
  {
    id: 24,
    name: 'Past Medical & Surgical Historys',
    label: 'Past Medical & Surgical Historys',
    icon: <InfoIcon />,
    object: {
      category: 'patient-history',
      value: null,
      valueUnit: ''
    }
  },
  {
    id: 25,
    name: 'Physical Examination',
    label: 'Physical Examination',
    icon: <InfoIcon />,
    object: {
      category: 'physical-examination',
      value: null,
      valueUnit: ''
    }
  },
  {
    id: 26,
    name: 'Treatment Plan',
    label: 'Treatment Plan',
    icon: <InfoIcon />,
    object: {
      category: 'treatment-plan',
      value: null,
      valueUnit: ''
    }
  },
  {
    id: 27,
    name: 'Estimated Length of Stay',
    label: 'Estimated Length of Stay',
    icon: <InfoIcon />,
    object: {
      category: 'estimated-Length-of-Stay',
      value: null,
      valueUnit: 'd'
    }
  },
  {
    id: 28,
    name: 'Morphology',
    label: 'Morphology',
    icon: <WarningIcon />,
    object: {
      category: 'morphology',
      codeText: '',
      code: ''
    }
  },
  {
    id: 29,
    name: 'Investigation Result',
    label: 'Investigation Result',
    icon: <SentimentVeryDissatisfiedIcon />,
    object: {
      category: 'investigation-result',
      codeText: '',
      code: '',
      image_value: '',
      image_title: '',
      image_content_type: ''
    }
  },
  {
    id: 30,
    name: 'Admission Weight',
    label: 'Admission Weight',
    icon: <ChildFriendlyIcon />,
    object: {
      category: 'admission-weight',
      value: null,
      valueUnit: 'kg'
    }
  }

  // {
  //   id: 14,
  //   name: "Pulse",
  //   icon: <FavoriteBorderIcon />,
  //   object: {
  //     category: "pulse",
  //     startDate: "",
  //     endDate: "",
  //     value: "",
  //     valueUnit: "/min",
  //   },
  // },

  // {
  //   id: 16,
  //   name: "Respiratory Rate",
  //   icon: <AirIcon />,
  //   object: {
  //     category: "respiratory-rate",
  //     startDate: "",
  //     endDate: "",
  //     value: "",
  //     valueUnit: "/min",
  //   },
  // },

  // {
  //   id: 17,
  //   name: "Temperature",
  //   icon: <ThermostatIcon />,
  //   object: {
  //     category: "temperature",
  //     startDate: "",
  //     endDate: "",
  //     value: "",
  //     valueUnit: "Cel",
  //   },
  // },

  // {
  //   id: 19,
  //   name: "Vital Sign Diastolic",
  //   icon: <ArrowDownwardIcon />,
  //   object: {
  //     category: "vital-sign-diastolic",
  //     startDate: "",
  //     endDate: "",
  //     value: "",
  //     valueUnit: "mm[Hg]",
  //   },
  // },

  // {
  //   id: 20,
  //   name: "Vital Sign Height",
  //   icon: <HeightIcon />,
  //   object: {
  //     category: "vital-sign-height",
  //     startDate: "",
  //     endDate: "",
  //     value: "",
  //     valueUnit: "cm",
  //   },
  // },

  // {
  //   id: 21,
  //   name: "Vital Sign Systolic",
  //   icon: <ArrowUpwardIcon />,
  //   object: {
  //     category: "vital-sign-systolic",
  //     startDate: "",
  //     endDate: "",
  //     value: "",
  //     valueUnit: "mm[Hg]",
  //   },
  // },

  // {
  //   id: 22,
  //   name: "Vital Sign Weight",
  //   icon: <MonitorWeightIcon />,
  //   object: {
  //     category: "vital-sign-weight",
  //     startDate: "",
  //     endDate: "",
  //     value: "",
  //     valueUnit: "kg",
  //   },
  // },
];

export default supportingInfoElements;

export const supportingInfoElementsWithoutChiefComplaint = [
  {
    label: 'Attachment',
    id: 1,
    name: 'Attachment',
    icon: <AttachFileIcon />,
    object: {
      category: 'attachment',
      image_value: '',
      image_title: '',
      image_content_type: ''
    }
  },
  {
    label: 'Birth Weight',
    id: 2,
    name: 'Birth Weight',
    icon: <ChildFriendlyIcon />,
    object: {
      category: 'birth-weight',
      value: '',
      valueUnit: 'kg'
    }
  },

  {
    label: 'Days Supply',
    id: 4,
    name: 'Days Supply',
    icon: <CalendarTodayIcon />,
    object: {
      category: 'days-supply',
      valueUnit: 'd',
      value: ''
    }
  },
  {
    label: 'Employment Impacted',
    id: 5,
    name: 'Employment Impacted',
    icon: <WorkOffIcon />,
    object: {
      category: 'employment-impacted',
      startDate: '',
      endDate: ''
    }
  },
  {
    label: 'Hospitalized',
    id: 6,
    name: 'Hospitalized',
    icon: <LocalHospitalIcon />,
    object: {
      category: 'hospitalized',
      startDate: '',
      endDate: ''
    }
  },
  {
    label: 'ICU Hours',
    id: 7,
    name: 'ICU Hours',
    icon: <AccessTimeIcon />,
    object: {
      category: 'icu-hours',
      startDate: '',
      endDate: '',
      value: '',
      valueUnit: 'h'
    }
  },
  {
    label: 'Info',
    id: 8,
    name: 'Info',
    icon: <InfoIcon />,
    object: {
      category: 'info',
      value: '',
      valueUnit: ''
    }
  },
  {
    label: 'Lab Test',
    id: 9,
    name: 'Lab Test',
    icon: <ScienceIcon />,
    object: {
      category: 'lab-test',
      startDate: '',
      endDate: '',
      value: '',
      valueUnit: '',
      codeText: '',
      code: ''
    }
  },
  {
    label: 'Last Menstrual Period',
    id: 10,
    name: 'Last Menstrual Period',
    icon: <EventIcon />,
    object: {
      category: 'last-menstrual-period',
      startDate: ''
    }
  },
  {
    label: 'Missing Tooth',
    id: 11,
    name: 'Missing Tooth',
    icon: <SentimentVeryDissatisfiedIcon />,
    object: {
      category: 'missingtooth',
      startDate: '',
      codeText: '',
      code: '',
      reason: ''
    }
  },
  {
    label: 'Onset',
    id: 12,
    name: 'Onset',
    icon: <PlayArrowIcon />,
    object: {
      category: 'onset',
      startDate: '',
      codeText: '',
      code: ''
    }
  },
  {
    label: 'Oxygen Saturation',
    id: 13,
    name: 'Oxygen Saturation',
    icon: <FavoriteIcon />,
    object: {
      category: 'oxygen-saturation',
      startDate: '',
      endDate: '',
      value: '',
      valueUnit: '%'
    }
  },
  {
    label: 'Reason For Visit',
    id: 15,
    name: 'Reason For Visit',
    icon: <EventNoteIcon />,
    object: {
      category: 'reason-for-visit',
      codeText: '',
      code: ''
    }
  },
  {
    label: 'Ventilation Hours',
    id: 18,
    name: 'Ventilation Hours',
    icon: <AirIcon />,
    object: {
      category: 'ventilation-hours',
      startDate: '',
      endDate: '',
      value: '',
      valueUnit: 'h'
    }
  }

  // {
  //   id: 14,
  //   name: "Pulse",
  //   icon: <FavoriteBorderIcon />,
  //   object: {
  //     category: "pulse",
  //     startDate: "",
  //     endDate: "",
  //     value: "",
  //     valueUnit: "/min",
  //   },
  // },

  // {
  //   id: 16,
  //   name: "Respiratory Rate",
  //   icon: <AirIcon />,
  //   object: {
  //     category: "respiratory-rate",
  //     startDate: "",
  //     endDate: "",
  //     value: "",
  //     valueUnit: "/min",
  //   },
  // },

  // {
  //   id: 17,
  //   name: "Temperature",
  //   icon: <ThermostatIcon />,
  //   object: {
  //     category: "temperature",
  //     startDate: "",
  //     endDate: "",
  //     value: "",
  //     valueUnit: "Cel",
  //   },
  // },

  // {
  //   id: 19,
  //   name: "Vital Sign Diastolic",
  //   icon: <ArrowDownwardIcon />,
  //   object: {
  //     category: "vital-sign-diastolic",
  //     startDate: "",
  //     endDate: "",
  //     value: "",
  //     valueUnit: "mm[Hg]",
  //   },
  // },

  // {
  //   id: 20,
  //   name: "Vital Sign Height",
  //   icon: <HeightIcon />,
  //   object: {
  //     category: "vital-sign-height",
  //     startDate: "",
  //     endDate: "",
  //     value: "",
  //     valueUnit: "cm",
  //   },
  // },

  // {
  //   id: 21,
  //   name: "Vital Sign Systolic",
  //   icon: <ArrowUpwardIcon />,
  //   object: {
  //     category: "vital-sign-systolic",
  //     startDate: "",
  //     endDate: "",
  //     value: "",
  //     valueUnit: "mm[Hg]",
  //   },
  // },

  // {
  //   id: 22,
  //   name: "Vital Sign Weight",
  //   icon: <MonitorWeightIcon />,
  //   object: {
  //     category: "vital-sign-weight",
  //     startDate: "",
  //     endDate: "",
  //     value: "",
  //     valueUnit: "kg",
  //   },
  // },
];

export const supportingInfoElementsOnlyChief = [
  {
    label: 'Chief Complaint',
    id: 3,
    name: 'Chief Complaint',
    icon: <WarningIcon />,
    object: {
      category: 'chief-complaint',
      codeText: '',
      code: '',
      valueUnit: '',
      value: ''
    }
  }
];

// const supportingInfoElements = [
//   {
//     id: 1,
//     name: "Attachment",
//     object: {
//       category: "attachment",
//       image_value: "",
//      image_title: "",
//       image_content_type: "",
//     },
//   },
//   {
//     id: 2,
//     name: "Birth Weight",
//     object: {
//       category: "birth-weight",
//       value: "",
//       valueUnit: "kg",
//     },
//   },
//   {
//     id: 3,
//     name: "Chief Complaint",
//     object: {
//       category: "chief-complaint",
//       codeText: "",
//       code: "",
//       valueUnit: "",
//       value: "",
//     },
//   },
//   {
//     id: 4,
//     name: "Days Supply",
//     object: {
//       category: "days-supply",
//       valueUnit: "d",
//       value: "",
//     },
//   },
//   {
//     id: 5,
//     name: "Employment Impacted",
//     object: {
//       category: "employment-impacted",
//       startDate: "",
//       endDate: "",
//     },
//   },
//   {
//     id: 6,
//     name: "Hospitalized",
//     object: {
//       category: "hospitalized",
//       startDate: "",
//       endDate: "",
//     },
//   },
//   {
//     id: 7,
//     name: "ICU Hours",
//     object: {
//       category: "icu-hours",
//       startDate: "",
//       endDate: "",
//       value: "",
//       valueUnit: "h",
//     },
//   },
//   {
//     id: 8,
//     name: "Info",
//     object: {
//       category: "info",
//       value: "",
//       valueUnit: "",
//     },
//   },
//   {
//     id: 9,
//     name: "Lab Test",
//     object: {
//       category: "lab-test",
//       startDate: "",
//       endDate: "",
//       value: "",
//       valueUnit: "",
//       codeText: "",
//       code: "",
//     },
//   },
//   {
//     id: 10,
//     name: "Last Menstrual Period",
//     object: {
//       category: "last-menstrual-period",
//       startDate: "",
//     },
//   },
//   {
//     id: 11,
//     name: "Missing Tooth",
//     object: {
//       category: "missingtooth",
//       startDate: "",
//       codeText: "",
//       code: "",
//       reason: "",
//     },
//   },
//   {
//     id: 12,
//     name: "Onset",
//     object: {
//       category: "onset",
//       startDate: "",
//       codeText: "",
//       code: "",
//     },
//   },
//   {
//     id: 13,
//     name: "Oxygen Saturation",
//     object: {
//       category: "oxygen-saturation",
//       startDate: "",
//       endDate: "",
//       value: "",
//       valueUnit: "%",
//     },
//   },
//   {
//     id: 14,
//     name: "Pulse",
//     object: {
//       category: "pulse",
//       startDate: "",
//       endDate: "",
//       value: "",
//       valueUnit: "/min",
//     },
//   },
//   {
//     id: 15,
//     name: "Reason For Visit",
//     object: {
//       category: "reason-for-visit",
//       codeText: "",
//       code: "",
//     },
//   },
//   {
//     id: 16,
//     name: "Respiratory Rate",
//     object: {
//       category: "respiratory-rate",
//       startDate: "",
//       endDate: "",
//       value: "",
//       valueUnit: "/min",
//     },
//   },
//   {
//     id: 17,
//     name: "Temperature",
//     object: {
//       category: "temperature",
//       startDate: "",
//       endDate: "",
//       value: "",
//       valueUnit: "Cel",
//     },
//   },
//   {
//     id: 18,
//     name: "Ventilation Hours",
//     object: {
//       category: "ventilation-hours",
//       startDate: "",
//       endDate: "",
//       value: "",
//       valueUnit: "h",
//     },
//   },
//   {
//     id: 19,
//     name: "Vital Sign Diastolic",
//     object: {
//       category: "vital-sign-diastolic",
//       startDate: "",
//       endDate: "",
//       value: "",
//       valueUnit: "mm[Hg]",
//     },
//   },
//   {
//     id: 20,
//     name: "Vital Sign Height",
//     object: {
//       category: "vital-sign-height",
//       startDate: "",
//       endDate: "",
//       value: "",
//       valueUnit: "cm",
//     },
//   },
//   {
//     id: 21,
//     name: "Vital Sign Systolic",
//     object: {
//       category: "vital-sign-systolic",
//       startDate: "",
//       endDate: "",
//       value: "",
//       valueUnit: "mm[Hg]",
//     },
//   },
//   {
//     id: 22,
//     name: "Vital Sign Weight",
//     object: {
//       category: "vital-sign-weight",
//       startDate: "",
//       endDate: "",
//       value: "",
//       valueUnit: "kg",
//     },
//   },
// ];

// export default supportingInfoElements;
