import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Chip } from '@mui/material';

function ViewHomeCareFormDetailsModal({
  selectedItem,
  setSelectedItem,
  openFormDetailsModal,
  setOpenFormDetailsModal
}) {
  const modalRef = useRef(null);
  const printRef = useRef(null);

  const handelModelClose = () => {
    setOpenFormDetailsModal(false);
    setSelectedItem(null);
  };

  const printFn = useReactToPrint({
    contentRef: printRef,
    documentTitle: 'Internal Results'
  });

  return (
    <>
      <div className={`${openFormDetailsModal ? 'open' : ''} add-item-modal modal relative`} id='myModal'>
        <div
          ref={modalRef}
          className='modal-content absolute !top-[20%] bg-white left-0 mx-auto !h-[600px] max-h-[800px] !w-[100%] !max-w-[1100px]'
          style={{ overflowY: 'unset' }}
        >
          <div className='flex items-center justify-between'>
            <div className='close gap-3 flex items-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                onClick={handelModelClose}
              >
                <path
                  d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z'
                  fill='#DE5042'
                />
              </svg>
              <div className='bene-text'>View Internal Results</div>
            </div>
            <Chip
              label={'Print Data'}
              color={'success'}
              onClick={printFn}
              size='small'
              className='!mr-[2px] rounded !cursor-pointer w-[130px] font-[600] !h-[32px]'
              style={{ fontSize: '1rem', padding: '2px 4px', marginBottom: '37px' }}
              variant='contained'
              sx={{
                backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)',
                borderColor: '#f14f3e',
                color: 'white',
                '&:hover': {
                  borderColor: '#f14f3e'
                },
                '&:focus': {
                  borderColor: '#f14f3e'
                }
              }}
            />
          </div>
          <div
            ref={printRef}
            className='grid grid-cols-1 print:grid-cols-1 gap-2 print-container'
            style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '800px' }}
          >
            <div className='result-main mb-2'>
              <h1 style={{ fontSize: '25px', fontWeight: '700' }}>Results</h1>
              <div className='result-header'>
                <div className='grid grid-cols-12 print:grid-cols-12 gap-1 px-3 mt-2'>
                  {selectedItem?.data?.header?.map((headerItem, index) => {
                    const match = headerItem?.name_en?.match(/Col(\d+)/);
                    const columnCount = match ? parseInt(match[1], 10) : 1;
                    const colSpan = 12 / columnCount;
                    if (headerItem?.elements) {
                      return headerItem?.elements?.map((element, index) => (
                        <>
                          {element.type === 'heading' ? (
                            <div className={`col-span-${colSpan} print:col-span-${colSpan} p-0`}>
                              <div className='heading-group p-2' key={index}>
                                <h1>{element.name_en}</h1>
                              </div>
                            </div>
                          ) : (
                            <div className={`col-span-${colSpan} print:col-span-${colSpan} p-0`}>
                              <div className='text-group p-2' key={index}>
                                <label className='text-sm'>{element.name_en}</label>
                                <span>
                                  {element?.value?.value?.includes('data:image/') ? (
                                    <img
                                      src={element.value.value}
                                      alt='Signature or Uploaded Image'
                                      className='w-[100px] h-[100px] rounded'
                                    />
                                  ) : (
                                    element?.value?.value
                                  )}
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      ));
                    }
                  })}
                </div>

                <div className='grid grid-cols-12 print:grid-cols-12 gap-1 px-3 mt-2'>
                  {selectedItem?.data?.body?.map((bodyItem, index) => {
                    const match = bodyItem?.name_en?.match(/Col(\d+)/);
                    const columnCount = match ? parseInt(match[1], 10) : 1;
                    const colSpan = 12 / columnCount;
                    if (bodyItem?.elements) {
                      return bodyItem?.elements?.map((element, index) => (
                        <>
                          {element.type === 'heading' ? (
                            <div className={`col-span-${colSpan} print:col-span-${colSpan} p-0`}>
                              <div className='heading-group p-2' key={index}>
                                <h1>{element.name_en}</h1>
                              </div>
                            </div>
                          ) : (
                            <div className={`col-span-${colSpan} print:col-span-${colSpan} p-0`}>
                              <div className='text-group p-2' key={index}>
                                <label className='text-sm'>{element.name_en}</label>
                                <span>
                                  {element?.value?.value?.includes('data:image/') ? (
                                    <img
                                      src={element.value.value}
                                      alt='Signature or Uploaded Image'
                                      className='w-[100px] h-[100px] rounded'
                                    />
                                  ) : (
                                    element?.value?.value
                                  )}
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      ));
                    }
                  })}
                </div>

                <div className='px-3 grid grid-cols-12 print:grid-cols-12 gap-1 mt-2'>
                  {selectedItem?.data?.footer?.map((footerItem, index) => {
                    const match = footerItem?.name_en?.match(/Col(\d+)/);
                    const columnCount = match ? parseInt(match[1], 10) : 1;
                    const colSpan = 12 / columnCount;
                    if (footerItem?.elements) {
                      return footerItem?.elements?.map((element, index) => (
                        <>
                          {element.type === 'heading' ? (
                            <div className={`col-span-${colSpan} print:col-span-${colSpan} p-0`}>
                              <div className='heading-group p-2' key={index}>
                                <h1>{element.name_en}</h1>
                              </div>
                            </div>
                          ) : (
                            <div className={`col-span-${colSpan} print:col-span-${colSpan} p-0`}>
                              <div className='text-group p-2' key={index}>
                                <label className='text-sm'>{element.name_en}</label>
                                <span>
                                  {element?.value?.value?.includes('data:image/') ? (
                                    <img
                                      src={element.value.value}
                                      alt='Signature or Uploaded Image'
                                      className='w-[100px] h-[100px] rounded'
                                    />
                                  ) : (
                                    element?.value?.value
                                  )}
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      ));
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewHomeCareFormDetailsModal;
