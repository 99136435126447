import React from 'react';
import { Outlet } from 'react-router-dom';

function index(props) {
  return (
    <>
      <Outlet />
    </>
  );
}

export default index;
