import React, { useState } from 'react';
import { Formik, FieldArray, Field, useFormikContext } from 'formik';
import { PlusIcon as PlusIcon } from '@heroicons/react/24/solid';
import { TrashIcon as TrashIconMini, PencilIcon } from '@heroicons/react/20/solid';
import _ from 'lodash';
import AddItemModal from './AddItemModal';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';

function ItemsList({ formData, setFormData, emr, patient, rcmDetail }) {
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue } =
    useFormikContext();

  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);

  const tableHeaders = [
    { name: 'No.' },
    { name: 'Type' },
    { name: 'Standard Code - Description' },
    { name: 'Net / Tax' },
    { name: 'Benefit / Tax' },
    // {name: "Status"},
    // {name: "Adjudication Reason Code"},
    // {name: "Note No."},
    { name: 'Days Supply' },
    { name: '' }
  ];

  const itemsData = [];

  const deleteItem = index => {
    let tempFormData = [...values.items];
    tempFormData.splice(index, 1);
    setFieldValue('items', tempFormData);
    // checkEligibility({ variables: { input: data } });
  };

  return (
    <div class='personal-data !p-3 !mb-3'>
      <div class='personal-title !mb-3 flex justify-between'>
        <div className='flex gap-2'>
          <div class='inventory-svg'>
            <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <rect width='34' height='34' rx='8' fill='#F4F6F8'></rect>
              <path
                d='M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z'
                fill='#F5793B'
              ></path>
              <path d='M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z' fill='#F5793B'></path>
            </svg>
          </div>
          <div class='personal-head my-auto'>
            <span>Order Items*</span>
          </div>
        </div>
        <div class=''>
          <Button
            variant='contained'
            size='small'
            style={{
              backgroundImage: 'linear-gradient(83deg, #f14f3e 0%, #fab768 100%)'
            }}
            onClick={() => {
              if (
                emr ||
                (values?.careTeamsArr?.length > 0 &&
                  values?.diagnosisArr?.length > 0 &&
                  values?.payerId &&
                  values?.patientId)
              ) {
                setShowAddItemModal(true);
              } else {
                toast.error('First fill above required fields');
              }
            }}
            startIcon={<AddIcon />}
          >
            Add Items
          </Button>
        </div>
        <AddItemModal
          showAddItemModal={showAddItemModal}
          setShowAddItemModal={setShowAddItemModal}
          formData={formData}
          setFormData={setFormData}
          values={values}
          setMainFormFieldValue={setFieldValue}
          item={currentItemIndex !== null ? values.items[currentItemIndex] : null}
          currentItemIndex={currentItemIndex}
          setCurrentItemIndex={setCurrentItemIndex}
          patient={patient}
          rcmDetail={rcmDetail}
        />
      </div>
      <div class='super-git-table'>
        <table class='table-super-git'>
          <thead>
            <tr>
              {tableHeaders.map((el, index) => {
                return (
                  <th className='!py-2.5 !pr-0 !pl-8 !bg-[#ffab2c]' key={'tableHeaders_' + index}>
                    {el.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {values.items.map((item, index) => {
              return (
                <tr key={'addressform'}>
                  <td>{index + 1}</td>
                  <td>{item.type}</td>
                  <td>
                    {item.standardCodeDescription} - {item.standardCode}
                  </td>
                  <td>{item.net}</td>
                  <td>{item.tax_amount}</td>
                  <td>{item?.days_supply || ''}</td>
                  <td class='action flex'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      onClick={() => {
                        setCurrentItemIndex(index);
                        setShowAddItemModal(true);
                      }}
                    >
                      <path
                        d='M2.99902 17.25V21H6.74902L17.809 9.94L14.059 6.19L2.99902 17.25ZM5.91902 19H4.99902V18.08L14.059 9.02L14.979 9.94L5.91902 19ZM20.709 5.63L18.369 3.29C18.169 3.09 17.919 3 17.659 3C17.399 3 17.149 3.1 16.959 3.29L15.129 5.12L18.879 8.87L20.709 7.04C21.099 6.65 21.099 6.02 20.709 5.63Z'
                        fill='black'
                      />
                    </svg>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      onClick={() => deleteItem(index)}
                    >
                      <path
                        d='M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z'
                        fill='black'
                      />
                    </svg>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {errors.items && touched.items ? (
        <p className='max-w-2xl mt-2 text-sm leading-6 text-red-500'>{errors.items}</p>
      ) : null}
    </div>
  );
}

export default ItemsList;
