import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
  Divider,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";

// API endpoint
const API_URL = "https://labapi.supergitsa.com/api";

// Validation schema using Yup
const ReportSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  name_en: Yup.string().required("Report Name (English) is required"),
  name_ar: Yup.string().required("Report Name (Arabic) is required"),
});

export default function FormList({ setSelectedReportId }) {
  const [reports, setReports] = useState([]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [reportToDelete, setReportToDelete] = useState(null);

  const fetchReports = async () => {
    try {
      const response = await axios.get(`${API_URL}/form/all`);
      if (response.status === 200) {
        setReports(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
      toast.error("Failed to fetch reports");
    }
  };

  // Fetch data from the API
  useEffect(() => {
    fetchReports();
  }, []);

  const handleAddReport = () => {
    setSelectedReport(null); // Reset selected report
    setOpenReportDialog(true);
  };

  const handleEditReport = (report) => {
    setSelectedReport(report); // Set the report to be edited
    setOpenReportDialog(true);
  };

  const handleDeleteReport = (report) => {
    setReportToDelete(report);
    setConfirmDeleteDialog(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`${API_URL}/form/${reportToDelete.id}`);

      setConfirmDeleteDialog(false);
      fetchReports();
      toast.success("Report deleted successfully");
    } catch (error) {
      console.error("Error deleting report:", error);
      toast.error("Failed to delete report");
    }
  };

  const handleSaveReport = async (values, { resetForm }) => {
    try {
      if (selectedReport) {
        // Update report
        await axios.put(`${API_URL}/form/${selectedReport.id}`, values);

        fetchReports();
        toast.success("Report updated successfully");
      } else {
        // Add new report
        const response = await axios.post(`${API_URL}/form`, values);
        if (response.status === 200) {
          fetchReports();
          toast.success("Report added successfully");
        }
      }
      setOpenReportDialog(false);
      resetForm();
    } catch (error) {
      console.error("Error saving report:", error);
      toast.error("Failed to save report");
    }
  };

  const handleCancelAdd = () => {
    setOpenReportDialog(false);
  };

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 2, backgroundColor: "#fff" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Form</Typography>
          <IconButton onClick={handleAddReport} sx={{ color: "#ff5722" }}>
            <AddCircleOutlineIcon fontSize="large" />
          </IconButton>
        </Box>
        <Divider sx={{ backgroundColor: "#ff5722" }} />
        <List>
          {reports.map((report) => (
            <ListItem
              key={report.id}
              onClick={() => setSelectedReportId(report.id)}
              className="cursor-pointer hover:bg-gray-100"
            >
              <ListItemText
                primary={report.name_en}
                secondary={report.name_ar}
              />
              <IconButton
                onClick={() => handleEditReport(report)}
                sx={{ color: "#000" }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => handleDeleteReport(report)}
                sx={{ color: "#000" }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* Dialog for adding/updating report */}
      <Dialog open={openReportDialog} onClose={handleCancelAdd}>
        <DialogTitle>
          {selectedReport ? "Edit Report" : "Add New Report"}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              type: selectedReport?.type || "",
              name_en: selectedReport?.name_en || "",
              name_ar: selectedReport?.name_ar || "",
            }}
            validationSchema={ReportSchema}
            onSubmit={handleSaveReport}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <FormControl fullWidth margin="dense" variant="outlined">
                  <InputLabel>Type</InputLabel>
                  <Select
                    name="type"
                    label="Type"
                    value={values.type}
                    onChange={handleChange}
                    error={touched.type && Boolean(errors.type)}
                  >
                    <MenuItem value="labs">Labs</MenuItem>
                    <MenuItem value="radiology">Radiology</MenuItem>
                    <MenuItem value="homecare">Homecare</MenuItem>
                  </Select>
                  {touched.type && errors.type && (
                    <Typography color="error" variant="body2">
                      {errors.type}
                    </Typography>
                  )}
                </FormControl>
                <Field
                  as={TextField}
                  name="name_en"
                  label="Report Name (English)"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  onChange={handleChange}
                  error={touched.name_en && Boolean(errors.name_en)}
                  helperText={touched.name_en && errors.name_en}
                />
                <Field
                  as={TextField}
                  name="name_ar"
                  label="Report Name (Arabic)"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  onChange={handleChange}
                  error={touched.name_ar && Boolean(errors.name_ar)}
                  helperText={touched.name_ar && errors.name_ar}
                />
                <DialogActions>
                  <Button onClick={handleCancelAdd}>Cancel</Button>
                  <Button type="submit">Save</Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      {/* Confirmation Dialog for Delete */}
      <Dialog
        open={confirmDeleteDialog}
        onClose={() => setConfirmDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this report?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteDialog(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
